import React from "react";
import {
  TextField as MuiTextField,
  Box,
  Stack,
  Typography,
  Paper,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import ChatFlowEditBtn from "./ChatFlowEditBtn";
import ChatFlowDelBtn from "./ChatFlowDelBtn";
import ChatFlowEditTree from "./ChatFlowEditTree";

// ----------------------------------------------------------------------
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));
// ----------------------------------------------------------------------
export default function ChatFlowPanel({
  editData,
  preDefinedType,
  field,
  addLabel,
  handleComplete,
  emptyMsg,
}) {
  if (!editData) return <></>;
  return (
    <>
      <Box sx={{ flexGrow: 1, overflow: "hidden", px: 3 }}>
        <Item
          sx={{
            my: 1,
            mx: "auto",
            p: 2,
          }}
        >
          {editData[field] ? (
            <>
              <Stack
                spacing={2}
                direction="row"
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                sx={{ width: "100%" }}
              >
                <Box display="flex" alignItems="center">
                  {/* <ChatFlowEditBtn
                    preDefinedType={preDefinedType}
                    editData={editData}
                    field={field}
                    handleComplete={handleComplete}
                    addLabel={addLabel}
                  /> */}
                  <Box sx={{ mx: 2 }} />
                  <ChatFlowEditTree
                    editData={editData[field]}
                    addLabel="Modifier la conversation"
                  />
                  <Box sx={{ mx: 2 }} />
                </Box>
                <Typography noWrap>{editData[field].title}</Typography>
                <ChatFlowDelBtn
                  field={field}
                  editData={editData}
                  handleComplete={handleComplete}
                />
              </Stack>
            </>
          ) : (
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              sx={{ width: "100%" }}
            >
              <Typography noWrap>{emptyMsg}</Typography>
              <ChatFlowEditBtn
                preDefinedType={preDefinedType}
                editData={editData}
                field={field}
                addLabel="Conversations Tchat"
                handleComplete={handleComplete}
              />
            </Stack>
          )}
        </Item>
      </Box>
    </>
  );
}
// ----------------------------------------------------------------------
