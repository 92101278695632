import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Bar } from "react-chartjs-2";
import DeleteIcon from "@mui/icons-material/Delete";

import { MoreVertical } from "react-feather";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  CardContent,
  CardActions,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Link,
  Typography as MuiTypography,
  IconButton,
  Menu,
  MenuItem,
  Popover,
} from "@mui/material";
import axios from "axios";
import { API_URL } from "config";
import { Add as AddIcon } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { spacing } from "@mui/system";
import useChallenge from "hooks/useChallenge";
import { getMediaUrl } from "utils/objectUtil";
import AudioBoxFromUrl from "components/media/AudioBoxFromUrl";
import ChallengeDelBtn from "pages/challenge/ChallengeDelBtn";
import StereoChalAddBtn from "./StereoChalAddBtn";
// ----------------------------------------------------------------------
const CardMedia = styled(MuiCardMedia)`
  height: 220px;
`;
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const LinearProgress = styled(MuiLinearProgress)(spacing);

const Spacer = styled.div(spacing);

const Typography = styled(MuiTypography)(spacing);

const Centered = styled.div`
  text-align: center;
`;

const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 128px;
  width: 128px;
`;

const LetterAvatar = styled(MuiAvatar)(spacing);
const AboutIcon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(2)};

  svg {
    width: 14px;
    height: 14px;
  }
`;

const ChartWrapper = styled.div`
  height: 280px;
  position: relative;
`;

const StatsIcon = styled.div`
  position: absolute;
  right: 16px;
  top: 32px;

  svg {
    width: 32px;
    height: 32px;
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const ProductsChip = styled(Chip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;
const REF_MODEL = "stereotypechallenge";
export default function ProgStereoChallengePage() {
  const { stereotypechallenges, getStereotypeChallenges } = useChallenge();
  useEffect(() => {
    getStereotypeChallenges();
  }, []);
  return (
    <>
      <Helmet title="Stereotype programme" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Stereotype programme
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/app">
              Accueil
            </Link>
            <Typography>Stereotype programme</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <StereoChalAddBtn />
        </Grid>
      </Grid>

      <Divider my={6} />
      <Grid container spacing={6}>
        <>
          {Array.isArray(stereotypechallenges) && (
            <>
              {stereotypechallenges.map((it, i) => {
                return (
                  <Grid item xs={12} lg={6} xl={3} key={i}>
                    <Detail row={it} />
                  </Grid>
                );
              })}
            </>
          )}
        </>
      </Grid>
    </>
  );
}
export const Detail = ({ row }) => {
  const { id, code, title, enable } = row;
  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {title}
        </Typography>

        {enable && <Chip label="Activé" color="success" />}
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <IconButton
          component={NavLink}
          aria-label="details"
          size="small"
          to={`/app/edit-stereotypechallenge/${id}`}
        >
          <EditIcon size="small" />
        </IconButton>

        <DelBtn row={row} />
      </CardActions>
    </Card>
  );
};
// ----------------------------------------------------------------------

const DelBtn = ({ row }) => {
  const [submitting, setsubmitting] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { getStereotypeChallenges, delStereoChallenge } = useChallenge();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirm = async () => {
    try {
      await delStereoChallenge(row);
      await getStereotypeChallenges();
      setAnchorEl(null);
    } catch (error) {
      console.error(error);
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <IconButton aria-label="details" size="small" onClick={handleClick}>
        <DeleteIcon />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card
          sx={{
            minWidth: 275,
          }}
        >
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Êtes vous sûr de supprimer ?
            </Typography>
          </CardContent>
          <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button disabled={submitting} onClick={handleConfirm}>
              Valider
            </Button>
            <Button onClick={handleClose}>Annuler</Button>
          </CardActions>
        </Card>
      </Popover>
    </div>
  );
};
