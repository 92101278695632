import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import { API_URL } from "config";
import {
  startLoading,
  stopLoading,
  initStereoChallenges,
  initChallenges,
  setChalFilters,
} from "redux/slices/programSlice";
import useNotification from "hooks/useNotification";
// ----------------------------------------------------------------------

export default function useChallenge() {
  const dispatch = useDispatch();
  const { chalFilters, challenges, stereotypechallenges } = useSelector(
    (state) => state.programs
  );
  const { notif } = useNotification();
  return {
    stereotypechallenges,
    challenges,
    chalFilters,
    saveVideo: async (o, field, video, type) => {
      try {
        if (!o || !o.id) return;
        if (!field) return;
        if (!type) return;
        dispatch(startLoading());
        const formData = new FormData();
        formData.append("video", video);
        formData.append("field", field);
        formData.append("id", o.id);
        console.log(formData);
        await axios.post(API_URL[type] + "/video", formData);
        notif("Sauvegarde réussie");
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    saveImage: async (o, field, image, type) => {
      try {
        if (!o || !o.id) return;
        if (!field) return;
        if (!type) return;
        dispatch(startLoading());
        const formData = new FormData();
        formData.append("image", image);
        formData.append("field", field);
        formData.append("id", o.id);
        await axios.post(API_URL[type] + "/image", formData);
        notif("Sauvegarde réussie");
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    saveVoice: async (o, field, image, type) => {
      try {
        if (!o || !o.id) return;
        if (!field) return;
        if (!type) return;
        dispatch(startLoading());
        const formData = new FormData();
        formData.append("voice", image);
        formData.append("field", field);
        formData.append("idRef", o.id); //idRef is id of StereoChallenge or Challenge
        if (o[field]) {
          formData.append("id", o[field]);
        }

        await axios.post(API_URL[type] + "/voice", formData);
        notif("Sauvegarde réussie");
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    saveStereoChallenge: async (data) => {
      try {
        dispatch(startLoading());
        if (data.id) {
          await axios.patch(API_URL.stereotypechallenge + "/" + data.id, data);
        } else {
          await axios.post(API_URL.stereotypechallenge, data);
        }
        notif("Sauvegarde réussie");
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw new Error("Stereotype programme ne sont pas disponibles");
      }
    },
    delStereoChallenge: async (data) => {
      try {
        dispatch(startLoading());
        await axios.delete(API_URL.stereotypechallenge + "/" + data.id);
        notif("Suppression réussie");
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw new Error("Stereotype programme ne sont pas disponibles");
      }
    },
    getStereotypeChallenges: async () => {
      try {
        dispatch(startLoading());
        const res = await axios.get(API_URL.stereotypechallenge);
        if (res && res.data) {
          dispatch(initStereoChallenges(res.data));
        } else {
          throw new Error("Stereotype programme ne sont pas disponibles");
        }
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw new Error("Stereotype programme ne sont pas disponibles");
      }
    },
    handleChalFilters: (filters) => {
      try {
        dispatch(setChalFilters({ ...filters }));
      } catch (error) {
        console.error(error);
      }
    },
    getChallenges: async () => {
      try {
        dispatch(startLoading());
        const res = await axios.get(API_URL.challenge, {
          params: {
            sorts: { code: 1 },
          },
        });
        if (res && res.data) {
          dispatch(initChallenges(res.data));
        } else {
          throw new Error("Challenges ne sont pas disponibles");
        }
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw new Error("Challenges ne sont pas disponibles");
      }
    },
    findChallengeById: async (id) => {
      try {
        if (!id) return;

        const res = await axios.get(`${API_URL.challenge}/${id}`);
        if (res && res.data) {
          return res.data;
        } else {
          throw new Error(`Challenge n'est pas valide`);
        }
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw new Error(`Challengee n'est pas valide`);
      }
    },
    findStereoChallengeById: async (id) => {
      try {
        if (!id) return;

        const res = await axios.get(`${API_URL.stereotypechallenge}/${id}`);
        if (res && res.data) {
          return res.data;
        } else {
          throw new Error(`Stereotype programme n'est pas valide`);
        }
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw new Error(`Stereotype programme n'est pas valide`);
      }
    },
    saveChallenge: async (data) => {
      try {
        dispatch(startLoading());
        if (data.id) {
          await axios.patch(API_URL.challenge + "/" + data.id, data);
        } else {
          await axios.post(API_URL.challenge, data);
        }
        notif("Sauvegarde réussie");
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    cloneChallenge: async (o) => {
      try {
        if (!o || !o.id) return;
        await axios.get(`${API_URL.challenge}/clone/${o.id}`);
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    delChallenge: async (o, refName) => {
      try {
        dispatch(startLoading());
        if (o && o.id) {
          await axios.delete(API_URL[refName] + "/" + o.id);
        }
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
  };
}
