import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import {
  Checkbox,
  Button,
  MenuItem,
  TextField as MuiTextField,
  ListItemText,
} from "@mui/material";
import { spacing } from "@mui/system";
import useNotification from "hooks/useNotification";
import useReferentiel from "hooks/useReferentiel";
// ----------------------------------------------------------------------
const TextField = styled(MuiTextField)(spacing);
export default function RefThematicForm({ editData, onComplete, refName }) {
  const { refs, saveRef, initRefs } = useReferentiel();
  const { notif } = useNotification();
  const dataSchema = Yup.object().shape({
    code: Yup.string().max(20).required("Veuillez renseigner le code"),
    label: Yup.string().max(50).required(`Veuillez renseigner le libellé`),
    refTheme: Yup.string().max(50).required(`Veuillez renseigner la thème`),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: editData && editData.code ? editData.code : "",
      label: editData && editData.label ? editData.label : "",
      refTheme: editData && editData.refTheme ? editData.refTheme : "",
    },
    validationSchema: dataSchema,
    onSubmit: async () => {
      try {
        const newData =
          editData && editData.id ? { ...editData, ...values } : { ...values };
        await saveRef(
          {
            ...newData,
          },
          refName
        );
        await initRefs();
        notif("Sauvegarde réussie");
        onComplete();
      } catch (error) {
        console.error(error);
        notif(null, error);
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleChange,
    handleSubmit,
    getFieldProps,
    setSubmitting,
  } = formik;
  if (!refName) return <></>;
  const { refTheme } = refs;
  if (!refTheme) return <></>;
  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <TextField
            fullWidth
            type="text"
            label="Code"
            {...getFieldProps("code")}
            error={Boolean(touched.code && errors.code)}
            helperText={touched.code && errors.code}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            name="label"
            label="Libellé"
            {...getFieldProps("label")}
            error={Boolean(touched.label && errors.label)}
            helperText={touched.label && errors.label}
            my={2}
          />
          <TextField
            fullWidth
            select
            label="Thème"
            value={values.refTheme}
            onChange={handleChange("refTheme")}
            {...getFieldProps("refTheme")}
            error={Boolean(touched.refTheme && errors.refTheme)}
            helperText={touched.refTheme && errors.refTheme}
            my={2}
          >
            {refTheme.map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <Button
            sx={{ my: 2 }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Valider
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
}
