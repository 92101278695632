import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import LoadingScreen from "components/LoadingScreen";
// Auth components
import RequireAuth from "components/auth/RequireAuth";
import RequireRole from "components/auth/RequireRole";
import RequireGuest from "components/auth/RequireGuest";
import AuthLayout from "layouts/Auth";
import DashboardLayout from "layouts/Dashboard";

import Landing from "pages/presentation/Landing";
import SignIn from "pages/auth/SignIn";
import Page404 from "pages/auth/Page404";
import Page500 from "pages/auth/Page500";
import Page401 from "pages/auth/Page401";
//users
import UsersPage from "pages/users/UsersPage";
import UserEditPage from "pages/users/UserEditPage";
import UserProgramEditPage from "pages/users/UserProgramEditPage";
import AppcodesPage from "pages/appcodes/AppcodesPage";
import RefsThemePage from "pages/refs/RefsThemePage";
import RefsAvatarPage from "pages/refs/RefsAvatarPage";
import RefsInterestPage from "pages/refs/RefsInterestPage";
import RefsCatInterestPage from "pages/refs/RefsCatInterestPage";
import RefsSubjectPage from "pages/refs/RefsSubjectPage";
import RefsReportContentPage from "pages/refs/RefsReportContentPage";
import RefsGiftCategoryPage from "pages/refs/RefsGiftCategoryPage";

/** Ref Samy App */
import RefsDemandPage from "pages/refs/RefsDemandPage";
/** contact book */
import RefsContactPage from "pages/refs/RefsContactPage";
import RefsThematicPage from "pages/refs/RefsThematicPage";
import RefsSupportZonePage from "pages/refs/RefsSupportZonePage";
import RefsSupportComPage from "pages/refs/RefsSupportComPage";
import RefsSupportLangPage from "pages/refs/RefsSupportLangPage";
/** article book */
import RefsSubThemPage from "pages/refs/RefsSousThemePage";
import RefsArticlePage from "pages/refs/RefsArticlePage";
import RefsArticleFormatPage from "pages/refs/RefsArticleFormatPage";
import RefsArticleLevelPage from "pages/refs/RefsArticleLevelPage";
/** END contact book */
import GiftPage from "pages/gift/GiftPage";
import GiftEditPage from "pages/gift/GiftEditPage";
import StereoChallengePage from "pages/stereotypechallenge/StereoChallengePage";
import ProgChallengePage from "pages/challenge/ProgChallengePage";
import ChallengeEditPage from "pages/challenge/ChallengeEditPage";
import ProgramPage from "pages/program/ProgramPage";
import BoosterPage from "pages/booster/BoosterPage";
import ProgramEditPage from "pages/program/ProgramEditPage";
import ProgramRulePage from "pages/program/ProgramRulePage";
import ProfileRulePage from "pages/program/ProfileRulePage";
import StereoChallengeEditPage from "pages/stereotypechallenge/StereoChallengeEditPage";
import RefsStereotypePage from "pages/refs/RefsStereotypePage";
import RefsChallengeTypePage from "pages/refs/RefsChallengeTypePage";
import RefsActionPointPage from "pages/refs/RefsActionPointPage";
import RefsBoosterTypePage from "pages/refs/RefsBoosterTypePage";
import RefsCoachVoicesPage from "pages/refs/RefsCoachVoicesPage";
import LibVideoPage from "pages/medialib/LibVideoPage";
import LibImagePage from "pages/medialib/LibImagePage";
import LibMusicPage from "pages/medialib/LibMusicPage";
import PublishProgPage from "pages/publish/PublishProgPage";
import PublishProgDetailPage from "pages/publish/PublishProgDetailPage";
// ----------------------------------------------------------------------
const ROLES_SUPERADMIN = ["superadmin"];
const ROLES_ADMIN = ["superadmin", "admin"];
export default function Router() {
  const appRoutes = useRoutes([
    {
      path: "/app",
      element: (
        <RequireAuth>
          <DashboardLayout />
        </RequireAuth>
      ),
      children: [
        { path: "", element: <Landing /> },
        { path: "dashboard", element: <Landing /> },
        {
          path: "users",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <UsersPage />
            </RequireRole>
          ),
        },
        {
          path: "user-edit/:id",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <UserEditPage />
            </RequireRole>
          ),
        },
        {
          path: "userprog-edit/:id",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <UserProgramEditPage />
            </RequireRole>
          ),
        },

        {
          path: "appcodes",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <AppcodesPage />
            </RequireRole>
          ),
        },
        {
          path: "refs-theme",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <RefsThemePage />
            </RequireRole>
          ),
        },
        {
          path: "refs-avatar",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <RefsAvatarPage />
            </RequireRole>
          ),
        },

        {
          path: "refs-refcatinterest",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <RefsCatInterestPage />
            </RequireRole>
          ),
        },
        {
          path: "refs-interest",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <RefsInterestPage />
            </RequireRole>
          ),
        },
        {
          path: "refs-subject",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <RefsSubjectPage />
            </RequireRole>
          ),
        },
        {
          path: "refs-stereotype",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <RefsStereotypePage />
            </RequireRole>
          ),
        },
        {
          path: "refs-refchallengetype",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <RefsChallengeTypePage />
            </RequireRole>
          ),
        },
        {
          path: "refs-actionpoint",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <RefsActionPointPage />
            </RequireRole>
          ),
        },
        {
          path: "refs-reportcontent",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <RefsReportContentPage />
            </RequireRole>
          ),
        },
        {
          path: "refs-giftcategory",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <RefsGiftCategoryPage />
            </RequireRole>
          ),
        },

        {
          path: "refs-contact",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <RefsContactPage />
            </RequireRole>
          ),
        },
        {
          path: "refs-demand",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <RefsDemandPage />
            </RequireRole>
          ),
        },

        {
          path: "refs-thematic",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <RefsThematicPage />
            </RequireRole>
          ),
        },
        {
          path: "refs-subtheme",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <RefsSubThemPage />
            </RequireRole>
          ),
        },

        {
          path: "refs-supportzone",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <RefsSupportZonePage />
            </RequireRole>
          ),
        },
        {
          path: "refs-supprotcom",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <RefsSupportComPage />
            </RequireRole>
          ),
        },
        {
          path: "refs-supportlang",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <RefsSupportLangPage />
            </RequireRole>
          ),
        },
        {
          path: "refs-article",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <RefsArticlePage />
            </RequireRole>
          ),
        },
        {
          path: "refs-articleformat",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <RefsArticleFormatPage />
            </RequireRole>
          ),
        },
        {
          path: "refs-articlelevel",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <RefsArticleLevelPage />
            </RequireRole>
          ),
        },
        {
          path: "refs-refboostertype",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <RefsBoosterTypePage />
            </RequireRole>
          ),
        },
        {
          path: "refs-coachvoices",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <RefsCoachVoicesPage />
            </RequireRole>
          ),
        },
        {
          path: "library-video",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <LibVideoPage />
            </RequireRole>
          ),
        },
        {
          path: "library-image",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <LibImagePage />
            </RequireRole>
          ),
        },
        {
          path: "library-music",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <LibMusicPage />
            </RequireRole>
          ),
        },
        {
          path: "prog-stereotypechallenge",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <StereoChallengePage />
            </RequireRole>
          ),
        },
        {
          path: "edit-stereotypechallenge/:id",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <StereoChallengeEditPage />
            </RequireRole>
          ),
        },
        {
          path: "prog-challenge",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <ProgChallengePage />
            </RequireRole>
          ),
        },
        {
          path: "edit-challenge/:id",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <ChallengeEditPage />
            </RequireRole>
          ),
        },

        {
          path: "program",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <ProgramPage />
            </RequireRole>
          ),
        },
        {
          path: "edit-program/:id",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <ProgramEditPage />
            </RequireRole>
          ),
        },
        {
          path: "program-rule",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <ProgramRulePage />
            </RequireRole>
          ),
        },
        {
          path: "profile-rule",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <ProfileRulePage />
            </RequireRole>
          ),
        },
        {
          path: "prog-booster",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <BoosterPage />
            </RequireRole>
          ),
        },
        {
          path: "publish-enable",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <PublishProgPage />
            </RequireRole>
          ),
        },
        {
          path: "publish-view/:id",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <PublishProgDetailPage />
            </RequireRole>
          ),
        },
        {
          path: "gift",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <GiftPage />
            </RequireRole>
          ),
        },
        {
          path: "edit-gift/:id",
          element: (
            <RequireRole roles={ROLES_ADMIN}>
              <GiftEditPage />
            </RequireRole>
          ),
        },
      ],
    },
    /* general */
    {
      path: "/",
      element: (
        <RequireGuest>
          <AuthLayout />
        </RequireGuest>
      ),
      children: [
        { path: "", element: <SignIn /> },
        { path: "login", element: <SignIn /> },
      ],
    },
    { path: "401", element: <Page401 /> },
    { path: "500", element: <Page500 /> },
    { path: "404", element: <Page404 /> },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
  return (
    <React.Suspense fallback={<LoadingScreen />}>{appRoutes}</React.Suspense>
  );
}
