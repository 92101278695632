import React from "react";
import useAuth from "hooks/useAuth";
import Page401 from "pages/auth/Page401";
// ----------------------------------------------------------------------
export default function RequireRole({ roles, children }) {
  const { user, isAuthenticated } = useAuth();

  const userHasRequiredRole = () => {
    try {
      const hasRole =
        roles && user && roles.includes(user.role) ? true : false;
      return hasRole;
    } catch (error) {
      return false;
    }
  };

  if (isAuthenticated && !userHasRequiredRole()) {
    return <Page401 />;
  }

  return children;
}
