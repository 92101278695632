import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import {
  Alert as MuiAlert,
  Box,
  MenuItem,
  TextField as MuiTextField,
  ListItemText,
  Button,
  Stack,
  Paper,
  Checkbox,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CircularProgress from "@mui/material/CircularProgress";
import { spacing } from "@mui/system";
import useNotification from "hooks/useNotification";
import useReferentiel from "hooks/useReferentiel";
import UploadSingleFile from "components/file/UploadSingleFile";
import ImgBoxFromUrl from "components/media/ImgBoxFromUrl";
import AudioBoxFromUrl from "components/media/AudioBoxFromUrl";
import EditFormSubtitle from "components/media/EditFormSubtitle";
import { API_URL } from "config";
import { init } from "i18next";
// ----------------------------------------------------------------------
const TextField = styled(MuiTextField)(spacing);
const Item = styled(Paper)(({ theme }) => ({
  //backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
// ----------------------------------------------------------------------

export default function RefsubjectForm({ editData, onComplete }) {
  const { saveRefsubject, initRefs } = useReferentiel();
  const { notif } = useNotification();
  const { refs } = useReferentiel();
  // ----------------------------------------------------------------------
  const [catInterestSelected, setcatInterestSelected] = useState([]);
  const [stereoTypeSelected, setstereoTypeSelected] = useState([]);
  // ----------------------------------------------------------------------

  const dataSchema = Yup.object().shape({
    code: Yup.string().max(20).required("Veuillez renseigner le code"),
    label: Yup.string().max(100).required(`Veuillez renseigner le libellé`),
    refTheme: Yup.string().max(100).required(`Veuillez renseigner le thème`),
    contact: Yup.string()
      .max(100)
      .required(`Veuillez renseigner le contact de confiance`),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: editData && editData.code ? editData.code : "",
      label: editData && editData.label ? editData.label : "",
      refTheme: editData && editData.refTheme ? editData.refTheme.id : "",
      contact: editData && editData.contact ? editData.contact : "",
    },
    validationSchema: dataSchema,
    onSubmit: async () => {
      try {
        const newData =
          editData && editData.id ? { ...editData, ...values } : { ...values };
        newData.refInterests = [...catInterestSelected];
        newData.refStereoTypes = [...stereoTypeSelected];
        await saveRefsubject({
          ...newData,
        });
        await initRefs();
        notif("Sauvegarde réussie");
        onComplete();
      } catch (error) {
        console.error(error);
        notif(null, error);
        setSubmitting(false);
      }
    },
  });
  const handleCompleteTranscript = async () => {
    await initRefs();
  };
  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
    handleChange,
    setFieldValue,
  } = formik;
  // ----------------------------------------------------------------------
  useEffect(() => {
    if (!editData) return;
    if (editData.refInterests)
      setcatInterestSelected(editData.refInterests);
    if (editData.refStereoTypes) setstereoTypeSelected(editData.refStereoTypes);
  }, [editData]);
  // ----------------------------------------------------------------------

  const handleChangeCatInterest = (event) => {
    const {
      target: { value },
    } = event;
    setcatInterestSelected(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleChangeStereotype = (event) => {
    const {
      target: { value },
    } = event;
    setstereoTypeSelected(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  // ----------------------------------------------------------------------

  if (!refs) return;
  const { refStereoType, refInterest, refTheme, contact } = refs;
  if (!contact) return <></>;
  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <TextField
            fullWidth
            type="text"
            label="Code"
            {...getFieldProps("code")}
            error={Boolean(touched.code && errors.code)}
            helperText={touched.code && errors.code}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            name="label"
            label="Libellé"
            {...getFieldProps("label")}
            error={Boolean(touched.label && errors.label)}
            helperText={touched.label && errors.label}
            my={2}
          />

          <TextField
            fullWidth
            select
            label="Thème"
            value={values.refTheme}
            onChange={handleChange("refTheme")}
            {...getFieldProps("refTheme")}
            error={Boolean(touched.refTheme && errors.refTheme)}
            helperText={touched.refTheme && errors.refTheme}
            my={2}
          >
            {refTheme.map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            select
            label="Contact de confiance"
            value={values.contact}
            onChange={handleChange("contact")}
            {...getFieldProps("contact")}
            error={Boolean(touched.contact && errors.contact)}
            helperText={touched.contact && errors.contact}
            my={2}
          >
            {contact.map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {/* interests */}
          <TextField
            fullWidth
            select
            multiple={true}
            label="Intérêts"
            {...getFieldProps("refInterests")}
            SelectProps={{
              multiple: true,
              value: catInterestSelected,
              renderValue: (selected) => {
                if (!refInterest) return;
                const arr = refInterest.filter((it) =>
                  selected.includes(it.id)
                );
                const arrTitle = arr.map((it) => it.label);
                return arrTitle.join(", ");
              },
              onChange: handleChangeCatInterest,
            }}
            my={2}
          >
            {refInterest.map((c, i) => {
              return (
                <MenuItem key={i} value={c.id}>
                  <Checkbox checked={catInterestSelected.indexOf(c.id) > -1} />
                  <ListItemText primary={c.label} />
                </MenuItem>
              );
            })}
          </TextField>
          {/* stereotypes */}
          <TextField
            fullWidth
            select
            multiple={true}
            label="Niveau de maturité"
            {...getFieldProps("refStereoTypes")}
            SelectProps={{
              multiple: true,
              value: stereoTypeSelected,
              renderValue: (selected) => {
                if (!refStereoType) return;
                const arr = refStereoType.filter((it) =>
                  selected.includes(it.id)
                );
                const arrTitle = arr.map((it) => it.label);
                return arrTitle.join(", ");
              },
              onChange: handleChangeStereotype,
            }}
            my={2}
          >
            {refStereoType.map((c, i) => {
              return (
                <MenuItem key={i} value={c.id}>
                  <Checkbox checked={stereoTypeSelected.indexOf(c.id) > -1} />
                  <ListItemText primary={c.label} />
                </MenuItem>
              );
            })}
          </TextField>
          <Button
            sx={{ my: 2 }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            endIcon={
              isSubmitting ? (
                <CircularProgress size={20} />
              ) : (
                <DoneIcon size={20} />
              )
            }
          >
            Valider
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
}
