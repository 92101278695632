import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import {
  Alert as MuiAlert,
  Box,
  MenuItem,
  TextField as MuiTextField,
  ListItemText,
  Button,
  Stack,
  Paper,
  Checkbox,
  Popover,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CircularProgress from "@mui/material/CircularProgress";
import { spacing } from "@mui/system";
import useNotification from "hooks/useNotification";
import useReferentiel from "hooks/useReferentiel";
import UploadSingleFile from "components/file/UploadSingleFile";
import ImgBoxFromUrl from "components/media/ImgBoxFromUrl";
import AudioBoxFromUrl from "components/media/AudioBoxFromUrl";
import EditFormSubtitle from "components/media/EditFormSubtitle";
import { API_URL } from "config";
import { init } from "i18next";
// ----------------------------------------------------------------------
const TextField = styled(MuiTextField)(spacing);
const Item = styled(Paper)(({ theme }) => ({
  //backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
// ----------------------------------------------------------------------

export default function RefContactForm({ editData, onComplete }) {
  const [thematicSelected, setthematicSelected] = useState([]);
  const [zonesSelected, setzonesSelected] = useState([]);
  const [supportcomSelected, setsupportcomSelected] = useState([]);
  const [supportlangSelected, setsupportlangSelected] = useState([]);
  // ----------------------------------------------------------------------

  const { saveContact, sendMailToContact, initRefs } = useReferentiel();
  const { notif } = useNotification();
  const { refs } = useReferentiel();
  // ----------------------------------------------------------------------

  const dataSchema = Yup.object().shape({
    label: Yup.string().max(100).required("Veuillez renseigner le nom"),
    email: Yup.string()
      .email("Fomat d'email invalide")
      .max(255)
      .required("Veuillez renseigner l'email"),
    shortDescription: Yup.string()
      .max(100)
      .required(`Veuillez renseigner la description courte`),
    description: Yup.string()
      .max(3000)
      .required(`Veuillez renseigner la description`),
    telephone: Yup.string().max(100),
    address: Yup.string().max(200),
  });
  // ----------------------------------------------------------------------

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      label: editData && editData.label ? editData.label : "",
      email: editData ? editData.email : "",
      shortDescription:
        editData && editData.shortDescription ? editData.shortDescription : "",
      description: editData && editData.description ? editData.description : "",
      telephone: editData && editData.telephone ? editData.telephone : "",
      address: editData && editData.address ? editData.address : "",
      openingTime: editData && editData.openingTime ? editData.openingTime : "",
      services: editData && editData.services ? editData.services : "",
    },
    validationSchema: dataSchema,
    onSubmit: async () => {
      try {
        const newData =
          editData && editData.id ? { ...editData, ...values } : { ...values };
        /* in case file upload is choiced we need to make formdata */
        newData.thematics = [...thematicSelected];
        newData.supportZones = [...zonesSelected];
        newData.supportComs = [...supportcomSelected];
        newData.supportLangs = [...supportlangSelected];

        await saveContact({
          ...newData,
        });
        await initRefs();
        notif("Sauvegarde réussie");
        onComplete();
      } catch (error) {
        console.error(error);
        notif(null, error);
        setSubmitting(false);
      }
    },
  });
  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
    handleChange,
    setFieldValue,
  } = formik;

  // ----------------------------------------------------------------------
  useEffect(() => {
    if (!editData) return;
    setthematicSelected(editData.thematics);
    setzonesSelected(editData.supportZones);
    setsupportcomSelected(editData.supportComs);
    setsupportlangSelected(editData.supportLangs);
  }, [editData]);
  // ----------------------------------------------------------------------

  if (!refs) return;
  const { refTheme, supportZone, supportCom, supportLang } = refs;
  // ----------------------------------------------------------------------
  const handleChangeThematic = (event) => {
    const {
      target: { value },
    } = event;
    setthematicSelected(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleChangeZone = (event) => {
    const {
      target: { value },
    } = event;
    setzonesSelected(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleChangeSupportCom = (event) => {
    const {
      target: { value },
    } = event;
    setsupportcomSelected(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleChangeSupportLang = (event) => {
    const {
      target: { value },
    } = event;
    setsupportlangSelected(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  // ----------------------------------------------------------------------
  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <TextField
            fullWidth
            type="text"
            label="Nom"
            {...getFieldProps("label")}
            error={Boolean(touched.label && errors.label)}
            helperText={touched.label && errors.label}
            my={2}
          />
          <TextField
            fullWidth
            type="email"
            name="email"
            label="Email de contact"
            {...getFieldProps("email")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            my={2}
          />
          {editData && editData.id && editData.email && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {editData.passwordGeneratedAt ? (
                <h4>
                  Mot de passe du contact créé le {editData.passwordGeneratedAt}
                </h4>
              ) : (
                <h4>Mot de passe du contact n'existe pas</h4>
              )}
              <GeneratePass editData={editData} />
              <Button
                sx={{ my: 2 }}
                type="button"
                variant="outlined"
                color="error"
                onClick={() => sendMailToContact(editData)}
              >
                Envoyer les informations de connexion par mail
              </Button>
            </Box>
          )}
          <TextField
            fullWidth
            type="text"
            name="shortDescription"
            label="Description courte"
            {...getFieldProps("shortDescription")}
            error={Boolean(touched.shortDescription && errors.shortDescription)}
            helperText={touched.shortDescription && errors.shortDescription}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            name="description"
            multiline={true}
            rows={3}
            maxRows={4}
            label="A propos"
            {...getFieldProps("description")}
            error={Boolean(touched.description && errors.description)}
            helperText={touched.description && errors.description}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            name="telephone"
            label="Téléphone"
            {...getFieldProps("telephone")}
            error={Boolean(touched.telephone && errors.telephone)}
            helperText={touched.telephone && errors.telephone}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            name="address"
            label="Adresse"
            {...getFieldProps("address")}
            error={Boolean(touched.address && errors.address)}
            helperText={touched.address && errors.address}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            name="openingTime"
            label="Horraire d'ouverture"
            {...getFieldProps("openingTime")}
            error={Boolean(touched.openingTime && errors.openingTime)}
            helperText={touched.openingTime && errors.openingTime}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            name="services"
            label="Services et expertises"
            {...getFieldProps("services")}
            error={Boolean(touched.services && errors.services)}
            helperText={touched.services && errors.services}
            my={2}
          />

          {/* refTheme */}
          <TextField
            fullWidth
            select
            multiple={true}
            label="Thèmes"
            {...getFieldProps("thematics")}
            SelectProps={{
              multiple: true,
              value: thematicSelected,
              renderValue: (selected) => {
                if (!refTheme) return;
                const arr = refTheme.filter((it) => selected.includes(it.id));
                const arrTitle = arr.map((it) => it.label);
                return arrTitle.join(", ");
              },
              onChange: handleChangeThematic,
            }}
            my={2}
          >
            {refTheme.map((c, i) => {
              return (
                <MenuItem key={i} value={c.id}>
                  <Checkbox checked={thematicSelected.indexOf(c.id) > -1} />
                  <ListItemText primary={c.label} />
                </MenuItem>
              );
            })}
          </TextField>
          {/* zone */}
          <TextField
            fullWidth
            select
            multiple={true}
            label="Zone géographique"
            {...getFieldProps("supportZones")}
            SelectProps={{
              multiple: true,
              value: zonesSelected,
              renderValue: (selected) => {
                if (!supportZone) return;
                const arr = supportZone.filter((it) =>
                  selected.includes(it.id)
                );
                const arrTitle = arr.map((it) => it.label);
                return arrTitle.join(", ");
              },
              onChange: handleChangeZone,
            }}
            my={2}
          >
            {supportZone.map((c, i) => {
              return (
                <MenuItem key={i} value={c.id}>
                  <Checkbox checked={zonesSelected.indexOf(c.id) > -1} />
                  <ListItemText primary={c.label} />
                </MenuItem>
              );
            })}
          </TextField>
          {/* com */}
          <TextField
            fullWidth
            select
            multiple={true}
            label="Moyen de communication"
            {...getFieldProps("supportComs")}
            SelectProps={{
              multiple: true,
              value: supportcomSelected,
              renderValue: (selected) => {
                if (!supportCom) return;
                const arr = supportCom.filter((it) => selected.includes(it.id));
                const arrTitle = arr.map((it) => it.label);
                return arrTitle.join(", ");
              },
              onChange: handleChangeSupportCom,
            }}
            my={2}
          >
            {supportCom.map((c, i) => {
              return (
                <MenuItem key={i} value={c.id}>
                  <Checkbox checked={supportcomSelected.indexOf(c.id) > -1} />
                  <ListItemText primary={c.label} />
                </MenuItem>
              );
            })}
          </TextField>
          {/* lang */}
          <TextField
            fullWidth
            select
            multiple={true}
            label="Language"
            {...getFieldProps("supportLangs")}
            SelectProps={{
              multiple: true,
              value: supportlangSelected,
              renderValue: (selected) => {
                if (!supportLang) return;
                const arr = supportLang.filter((it) =>
                  selected.includes(it.id)
                );
                const arrTitle = arr.map((it) => it.label);
                return arrTitle.join(", ");
              },
              onChange: handleChangeSupportLang,
            }}
            my={2}
          >
            {supportLang.map((c, i) => {
              return (
                <MenuItem key={i} value={c.id}>
                  <Checkbox checked={supportlangSelected.indexOf(c.id) > -1} />
                  <ListItemText primary={c.label} />
                </MenuItem>
              );
            })}
          </TextField>
          {/* END */}
          <Button
            sx={{ my: 2 }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            endIcon={
              isSubmitting ? (
                <CircularProgress size={20} />
              ) : (
                <DoneIcon size={20} />
              )
            }
          >
            Valider
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
}
// ----------------------------------------------------------------------

const GeneratePass = ({ editData }) => {
  const { generatePasswordContact } = useReferentiel();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleConfirm = async () => {
    if (!editData) return;

    await generatePasswordContact(editData);
    handleClose();
  };
  // ----------------------------------------------------------------------

  return (
    <div>
      <Button
        sx={{ my: 2 }}
        type="button"
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        Générer le mot de passe
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card
          sx={{
            minWidth: 275,
          }}
        >
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Un nouveau mot de passe sera créé et envoyé à l'email:
              <strong>{editData.email} </strong> Confirmez-vous ?
            </Typography>
          </CardContent>
          <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={handleConfirm}>Valider</Button>
            <Button onClick={handleClose}>Annuler</Button>
          </CardActions>
        </Card>
      </Popover>
    </div>
  );
};
