import React from "react";
import styled from "@emotion/styled";
import { Grid, Card, CardHeader, Avatar } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { green, pink } from "@mui/material/colors";
import { fDateTimeSuffix } from "utils/formatTime";
import useChallenge from "hooks/useChallenge";
import RefsEditMediaBtn from "pages/refs/RefsEditMediaBtn";
import RefsEditVoiceBtn from "pages/refs/RefsEditVoiceBtn";
// ----------------------------------------------------------------------
export default function GridImages({
  isOnlyImage,
  editData,
  onComplete,
  model,
}) {
  const { saveImage, saveVoice } = useChallenge();
  // ----------------------------------------------------------------------

  const handleComplete = async (editData, field, editObj) => {
    try {
      await saveImage(editData, field, editObj, model);
      onComplete();
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  // ----------------------------------------------------------------------

  const handleCompleteVoice = async (editData, field, editObj) => {
    try {
      await saveVoice(editData, field, editObj, model);
      onComplete();
    } catch (error) {
      console.error(error);
    }
  };
  // ----------------------------------------------------------------------
  const handleCompleteTranscript = async () => {
    try {
      onComplete();
    } catch (error) {
      console.error(error);
    }
  };

  // ----------------------------------------------------------------------
  if (!model) return <></>;
  if (!editData) return <></>;
  return (
    <Grid container spacing={6}>
      <Grid item xs={4}>
        <CardImg
          editData={editData}
          field="image"
          addLabel="Image"
          handleComplete={handleComplete}
        />
      </Grid>
      {!isOnlyImage && (
        <>
          <Grid item xs={4}>
            <CardImg
              editData={editData}
              field="bgimage"
              addLabel="Image de fond"
              handleComplete={handleComplete}
            />
          </Grid>
          <Grid item xs={4}>
            <Card sx={{ maxWidth: 345 }}>
              <CardHeader
                avatar={
                  editData && editData.voiceTranscript ? (
                    <Avatar sx={{ bgcolor: green[500] }}>
                      <TaskAltIcon />
                    </Avatar>
                  ) : (
                    <Avatar sx={{ bgcolor: pink[500] }}>
                      <ErrorOutlineIcon />
                    </Avatar>
                  )
                }
                action={
                  <>
                    {editData && editData.voiceTranscript ? (
                      <RefsEditVoiceBtn
                        field="voiceTranscript"
                        editData={{
                          ...editData,
                          voiceTranscript: editData.voiceTranscript.id,
                        }}
                        addLabel="Audio"
                        handleCompleteVoice={handleCompleteVoice}
                        handleCompleteTranscript={handleCompleteTranscript}
                      />
                    ) : (
                      <RefsEditVoiceBtn
                        field="voiceTranscript"
                        editData={{
                          ...editData,
                        }}
                        addLabel="Audio"
                        handleCompleteVoice={handleCompleteVoice}
                        handleCompleteTranscript={handleCompleteTranscript}
                      />
                    )}
                  </>
                }
                title={
                  editData && editData.voiceTranscript
                    ? "Audio"
                    : "Audio non disponible"
                }
                subheader={
                  editData && editData.voiceTranscript
                    ? fDateTimeSuffix(editData.voiceTranscript.modifiedAt)
                    : ""
                }
              />
            </Card>
          </Grid>
        </>
      )}
    </Grid>
  );
}
// ----------------------------------------------------------------------
const CardImg = ({ editData, field, addLabel, handleComplete }) => {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardHeader
        avatar={
          editData && editData[field] ? (
            <Avatar sx={{ bgcolor: green[500] }}>
              <TaskAltIcon />
            </Avatar>
          ) : (
            <Avatar sx={{ bgcolor: pink[500] }}>
              <ErrorOutlineIcon />
            </Avatar>
          )
        }
        action={
          <RefsEditMediaBtn
            field={field}
            editData={editData}
            addLabel={addLabel}
            handleComplete={handleComplete}
          />
        }
        title={
          editData && editData[field] ? addLabel : addLabel + " non disponible"
        }
      />
    </Card>
  );
};
