import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import useReferentiel from "hooks/useReferentiel";
import useProgram from "hooks/useProgram";
import ImgBoxFromUrl from "components/media/ImgBoxFromUrl";

import ProgramSubjectsEditBtn from "./ProgramSubjectsEditBtn";
// ----------------------------------------------------------------------
export default function ProgramSubjects({ editProgram }) {
  const [chosenSubjects, setchosenSubjects] = useState([]);
  const [choicedSubject, setchoicedSubject] = useState([]);
  const [updatedAt, setupdatedAt] = useState(null);
  // ----------------------------------------------------------------------
  const { refs } = useReferentiel();
  // ----------------------------------------------------------------------
  const { getSubjectsByProg } = useProgram();
  // ----------------------------------------------------------------------
  useEffect(() => {
    if (!editProgram) return;
    init();
  }, [editProgram]);
  const init = async () => {
    if (!editProgram) return;
    const res = await getSubjectsByProg();
    if (res) {
      setchosenSubjects(res.map((it) => it.refSubject)); //getonly refSubject
    } else {
      setchosenSubjects([]);
    }
  };
  useEffect(() => {
    if (!updatedAt) return;
    init();
  }, [updatedAt]);
  // ----------------------------------------------------------------------
  useEffect(() => {
    if (!editProgram) return;
    if (!chosenSubjects) return;
    if (!refs) return;
    if (!refs.refSubject) return;
    const res = refs.refSubject.filter((it) => {
      const notInChosens =
        chosenSubjects.length === 0
          ? true
          : chosenSubjects.findIndex(
              (subj) => String(subj.id) === String(it.id)
            ) < 0;
      return notInChosens;
    });
    setchoicedSubject(res);
  }, [editProgram, refs, chosenSubjects]);
  // ----------------------------------------------------------------------

  if (!refs) return <></>;
  const { refTheme } = refs;
  if (!refTheme) return <></>;
  // ----------------------------------------------------------------------
  if (!choicedSubject) return <></>;
  // ----------------------------------------------------------------------

  return (
    <>
      <Box
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          p: 4,
        }}
      >
        <Typography variant="h6">Gérer les sujets du programme</Typography>

        <Box sx={{ width: "100%", height: "100%" }}>
          <Box
            sx={{
              my: 3,
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {!editProgram.isPublished && (
              <ProgramSubjectsEditBtn
                editProgram={editProgram}
                chosenSubjects={chosenSubjects}
                choicedSubject={choicedSubject}
                onComplete={() => {
                  setupdatedAt(new Date());
                }}
              />
            )}
            <Typography variant="h6" sx={{ ml: 4 }}>
              {`${chosenSubjects.length}`} Sujets choisis:
            </Typography>
          </Box>
          {chosenSubjects && chosenSubjects.length > 0 && (
            <Stack
              direction="row"
              spacing={1}
              sx={{ my: 6 }}
              useFlexGap
              flexWrap="wrap"
            >
              {chosenSubjects.map((it, i) => {
                return <DisplaySubject key={i} subject={it} />;
              })}
            </Stack>
          )}
        </Box>
      </Box>
    </>
  );
}
// ----------------------------------------------------------------------
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
// ----------------------------------------------------------------------

function DisplaySubject({ subject }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // ----------------------------------------------------------------------

  if (!subject) return <></>;
  const { image, code, label } = subject;
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Item>
      <Chip label={`${code}-${label}`} onClick={handleClick} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card
          sx={{
            minWidth: 275,
          }}
        >
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Image
            </Typography>
            {image && open && (
              <ImgBoxFromUrl
                image={`${image}`}
                sx={{
                  borderRadius: 1,
                  objectFit: "cover",
                  width: "250px",
                  height: "auto",
                }}
              />
            )}
          </CardContent>
          <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={handleClose}>Annuler</Button>
          </CardActions>
        </Card>
      </Popover>
    </Item>
  );
}
