import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { Paper, Divider as MuiDivider, Stack, Box } from "@mui/material";

import { Helmet } from "react-helmet-async";
import { spacing } from "@mui/system";
import useReferentiel from "hooks/useReferentiel";

import useChallenge from "hooks/useChallenge";
import BackBtn from "components/custom/BackBtn";
import GridImages from "pages/common/GridImages";
import {
  CHALLENGE_TYPE_NOTE,
  CHALLENGE_TYPE_CHAT,
  CHALLENGE_TYPE_QUIZ,
  CHALLENGE_TYPE_VIDEO,
  QUIZ_TYPE_CHAL,
} from "config";
import QuizEditPanel from "pages/quiz/QuizEditPanel";
import ChatFlowPanel from "pages/tchat/ChatFlowPanel";
import ChallengeForm from "./ChallengeForm";
import ChallengeNoteForm from "./ChallengeNoteForm";
import ChalVideoInspireContentForm from "./ChalVideoInspireContentForm";
import ChallengeVideoUrl from "./ChallengeVideoUrl";
// ----------------------------------------------------------------------
const Divider = styled(MuiDivider)(spacing);
export default function ChallengeEditPage() {
  const { id } = useParams();
  const { saveChallenge, findChallengeById } = useChallenge();
  // ----------------------------------------------------------------------

  const { refs } = useReferentiel();
  const [editData, seteditdata] = useState(null);
  const [updatedAt, setupdatedAt] = useState(null);
  // ----------------------------------------------------------------------

  useEffect(() => {
    if (!id) return;
    init();
  }, [id]);
  useEffect(() => {
    if (!id) return;
    if (!updatedAt) return;
    init();
  }, [id, updatedAt]);
  const init = async () => {
    try {
      const res = await findChallengeById(id);
      if (res) {
        seteditdata(res);
      }
    } catch (error) {
      console.error();
    }
  };
  // ----------------------------------------------------------------------
  const onCompleteSaveImg = () => {
    setupdatedAt(new Date());
  };
  // ----------------------------------------------------------------------
  const onCompleteSaveExternalModel = async (field) => {
    const obj = { ...editData };
    obj[field] = null; //only reset quiz or chatflow field
    await saveChallenge(obj);
    setupdatedAt(new Date());
  };
  // ----------------------------------------------------------------------

  if (!refs) return <></>;
  if (!editData) return <></>;
  return (
    <>
      <Helmet title="Edition Stereotype programme" />
      <BackBtn
        backUrl="/app/prog-challenge"
        needConfirm={true}
        titleConfim="Demande de confirmation"
        msgConfirm="Des données saisies pourraient ne pas être  Voulez-vous quitter cette page ?"
      />
      <Divider my={6} />
      <ChallengeForm
        editData={editData}
        onComplete={() => setupdatedAt(new Date())}
      />
      <Divider my={2} />
      <GridImages
        editData={editData}
        onComplete={onCompleteSaveImg}
        model="challenge"
      />
      <Divider my={3} />
      {editData.refChallengeType && (
        <ResponsiveStack
          editData={editData}
          onComplete={() => setupdatedAt(new Date())}
        />
      )}

      {editData.refChallengeType.id === CHALLENGE_TYPE_QUIZ && (
        <>
          <Divider my={4} />
          <QuizEditPanel
            preDefinedType={QUIZ_TYPE_CHAL}
            editData={editData}
            field="quiz"
            addLabel="Questionnaire"
            handleComplete={() => setupdatedAt(new Date())}
            handleCompleteDelQuiz={() => onCompleteSaveExternalModel("quiz")}
            emptyMsg="Le défi n'a pas encore de questionnaire. Veuillez
        ajouter."
          />
        </>
      )}
      {editData.refChallengeType.id === CHALLENGE_TYPE_CHAT && (
        <>
          <Divider my={4} />
          <ChatFlowPanel
            preDefinedType={QUIZ_TYPE_CHAL}
            editData={editData}
            field="tChat"
            addLabel="Conversation chat"
            handleComplete={() => setupdatedAt(new Date())}
            handleCompleteDelQuiz={() => onCompleteSaveExternalModel("tchat")}
            emptyMsg="Le défi n'a pas encore de flux de conversation. Veuillez
        ajouter."
          />
        </>
      )}

      <Box sx={{ my: 3, width: "100%" }} />
    </>
  );
}
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  display: "flex",
  alignItems: "center",
}));
// ----------------------------------------------------------------------
const ResponsiveStack = ({ editData, onComplete }) => {
  return (
    <div>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        {editData.refChallengeType.id === CHALLENGE_TYPE_VIDEO && (
          <Item>
            <ChallengeVideoUrl editData={editData} onComplete={onComplete} />
          </Item>
        )}
        {editData.refChallengeType.id === CHALLENGE_TYPE_NOTE && (
          <Item>
            <ChallengeNoteForm editData={editData} onComplete={onComplete} />
          </Item>
        )}

        {editData.refChallengeType.id !== CHALLENGE_TYPE_NOTE && (
          <Item>
            <ChalVideoInspireContentForm
              editData={editData}
              onComplete={onComplete}
              field="inspireContent"
            />
          </Item>
        )}
      </Stack>
    </div>
  );
};
