import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import useQuiz from "hooks/useQuiz";
import QuestionEditBtn from "./QuestionEditBtn";
import QuestionDelBtn from "./QuestionDelBtn";
import AnswerEditBtn from "./AnswerEditBtn";
import AnswerPanel from "./AnswerPanel";
// ----------------------------------------------------------------------

export default function QuestionItem({ quiz, item, handleComplete, bgColor }) {
  // ----------------------------------------------------------------------
  const { getAnswersByQuestion } = useQuiz();
  // ----------------------------------------------------------------------
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [updatedAt, setupdatedAt] = useState(null);
  const [editAnswers, seteditAnswers] = useState(null);
  // ----------------------------------------------------------------------
  useEffect(() => {
    if (!item) return;
    if (!accordionOpen) return;

    init();
  }, [item, accordionOpen]);
  useEffect(() => {
    if (!item) return;
    if (!updatedAt) return;
    init();
  }, [item, updatedAt]);
  const init = async () => {
    try {
      const res = await getAnswersByQuestion(item.id);
      if (res) {
        seteditAnswers(res);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // ----------------------------------------------------------------------
  const handleCompleteQuestion = () => {
    handleComplete();
  };
  // ----------------------------------------------------------------------
  if (!item) return <></>;
  // ----------------------------------------------------------------------

  return (
    <div>
      <Accordion expanded={accordionOpen}>
        <AccordionSummary
          aria-controls="panel1-content"
          id="panel1-header"
          expandIcon={
            <ArrowDownwardIcon
              style={{ cursor: "pointer" }}
              onClick={() => setAccordionOpen(!accordionOpen)}
            />
          }
          sx={{ cursor: "unset !important", backgroundColor: bgColor }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              pr: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <QuestionDelBtn
                editQuestion={item}
                handleCompleteDelQuestion={handleCompleteQuestion}
              />
              <Box sx={{ mr: 3 }} />
              <QuestionEditBtn
                editQuestion={item}
                quiz={quiz}
                handleComplete={handleCompleteQuestion}
              />
              <Box sx={{ mr: 3 }} />
            </Box>

            <Typography noWrap>{item.title}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <AnswerEditBtn
            quiz={quiz}
            question={item}
            addLabel="Nouvelle réponse"
            handleComplete={() => setupdatedAt(new Date())}
          />
          {editAnswers && (
            <AnswerPanel
              quiz={quiz}
              question={item}
              items={editAnswers}
              handleComplete={() => setupdatedAt(new Date())}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
// ----------------------------------------------------------------------
