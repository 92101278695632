import React from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  MenuItem,
  TextField as MuiTextField,
  FormHelperText,
  ListSubheader,
} from "@mui/material";
import { spacing } from "@mui/system";

import useNotification from "hooks/useNotification";
import useReferentiel from "hooks/useReferentiel";
import useDataUser from "hooks/useDataUser";
import CodeGen from "components/CodeGen";
// ----------------------------------------------------------------------
const TextField = styled(MuiTextField)(spacing);
export default function UserForm({ editUser, onComplete }) {
  const { saveUser, initUsers } = useDataUser();
  const { notif } = useNotification();
  const { refs } = useReferentiel();
  const UserSchema = Yup.object().shape({
    pseudo: Yup.string().max(255),
    email: Yup.string()
      .email("Fomat d'email invalide")
      .max(255)
      .required("Veuillez renseigner l'email"),
    firstname: Yup.string().required(`Veuillez renseigner le prénom`),
    lastname: Yup.string().required("Veuillez renseigner le nom"),
    refStatus: Yup.string().max(255).required("Veuillez renseigner le statut"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      pseudo: editUser ? editUser.pseudo : "",
      email: editUser ? editUser.email : "",
      firstname: editUser ? editUser.firstname : "",
      lastname: editUser ? editUser.firstname : "",
      refStatus: editUser && editUser.refStatus ? editUser.refStatus.id : "",
      refprofiletype:
        editUser && editUser.refprofiletype ? editUser.refprofiletype.id : "",
      refStereoType:
        editUser && editUser.refStereoType ? editUser.refStereoType.id : "",
      isEmailVerified: editUser ? Boolean(editUser.isEmailVerified) : true,
    },
    validationSchema: UserSchema,
    onSubmit: async () => {
      try {
        const newU =
          editUser && editUser.id ? { ...editUser, ...values } : { ...values };
        await saveUser(newU);
        await initUsers();
        notif("Sauvegarde réussie");
        onComplete();
      } catch (error) {
        console.error(error);
        notif(null, error);
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
    handleChange,
    setFieldValue,
  } = formik;

  const refStatus = Array.isArray(refs.refStatus) ? refs.refStatus : [];
  const refStereoType = Array.isArray(refs.refStereoType)
    ? refs.refStereoType
    : [];
  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          {editUser && editUser.id && (
            <TextField
              fullWidth
              type="text"
              name="pseudo"
              label="Pseudo"
              {...getFieldProps("pseudo")}
              error={Boolean(touched.pseudo && errors.pseudo)}
              helperText={touched.pseudo && errors.pseudo}
              my={2}
            />
          )}

          <TextField
            fullWidth
            type="email"
            name="email"
            label="Email"
            {...getFieldProps("email")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            name="firstname"
            label="Prénom"
            {...getFieldProps("firstname")}
            error={Boolean(touched.firstname && errors.firstname)}
            helperText={touched.firstname && errors.firstname}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            name="lastname"
            label="Nom"
            {...getFieldProps("lastname")}
            error={Boolean(touched.lastname && errors.lastname)}
            helperText={touched.lastname && errors.lastname}
            my={2}
          />
          <TextField
            fullWidth
            select
            label="Statut"
            value={values.refStatus}
            onChange={handleChange("refStatus")}
            {...getFieldProps("refStatus")}
            error={Boolean(touched.refStatus && errors.refStatus)}
            helperText={touched.refStatus && errors.refStatus}
            my={2}
          >
            {refStatus.map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          {editUser && editUser.id && (
            <TextField
              fullWidth
              select
              label="Stereotype"
              value={values.refStereoType}
              onChange={handleChange("refStereoType")}
              {...getFieldProps("refStereoType")}
              error={Boolean(touched.refStereoType && errors.refStereoType)}
              helperText={touched.refStereoType && errors.refStereoType}
              my={2}
            >
              {refStereoType.map((option, i) => (
                <MenuItem key={i} value={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Valider
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
}
