import React, { useEffect } from "react";
import useAuth from "hooks/useAuth";
import useReferentiel from "hooks/useReferentiel";
import LoadingScreen from "components/LoadingScreen";
// ----------------------------------------------------------------------
const AppContext = React.createContext();
export default function AppProvider({ ...props }) {
  const { initialized, user, initAuth } = useAuth();
  const { initAppconfigs, initRefs } = useReferentiel();
  useEffect(() => {
    initAuth();
  }, []);
  useEffect(() => {
    if (!user || !user._id) return;
    initAppconfigs();
    initRefs();
  }, [user]);
  return (
    <AppContext.Provider value={{}}>
      {initialized ? <>{props.children}</> : <LoadingScreen />}
    </AppContext.Provider>
  );
}
