import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useTheme } from "@mui/material/styles";

import { withTheme } from "@emotion/react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Bar } from "react-chartjs-2";

import { MoreVertical } from "react-feather";

import {
  Avatar,
  AvatarGroup as MuiAvatarGroup,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  CardContent,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Link,
  CardHeader,
  Typography as MuiTypography,
  IconButton,
  Menu,
  Pagination,
  Stack,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from "@mui/material";

import SportsGymnasticsIcon from "@mui/icons-material/SportsGymnastics";
import MicExternalOnIcon from "@mui/icons-material/MicExternalOn";
import MissedVideoCallIcon from "@mui/icons-material/MissedVideoCall";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import EditIcon from "@mui/icons-material/Edit";
import { spacing } from "@mui/system";
import { getMediaUrl } from "utils/objectUtil";
import CardMediaFromUrl from "components/media/CardMediaFromUrl";
import RefsList from "./RefsList";
// ----------------------------------------------------------------------

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Grid = styled(MuiGrid)(spacing);

const Typography = styled(MuiTypography)(spacing);
const AvatarGroup = styled(MuiAvatarGroup)`
  margin-left: ${(props) => props.theme.spacing(2)};
`;
const DF_ITEMS_PER_PAGE = [20, 30, 50, 100, 200];

export default function PaginationRefsGrid({ filteredItems, refName }) {
  const [itemsperpage, setitemsperpage] = useState(DF_ITEMS_PER_PAGE[0]);
  const [page, setPage] = useState(1);
  const [count, setcount] = useState(null);
  const [items, setitems] = useState(null);
  useEffect(() => {
    if (!Array.isArray(filteredItems)) return;
    const countNb =
      filteredItems.length <= itemsperpage
        ? 1
        : Math.ceil(filteredItems.length / itemsperpage);
    setcount(countNb);
  }, [filteredItems, itemsperpage]);

  useEffect(() => {
    if (!Array.isArray(filteredItems)) return;
    if (!page) return;

    const fromIndex = itemsperpage * (Number(page) - 1);
    const toIndex = fromIndex + itemsperpage;
    const newItems = filteredItems.slice(fromIndex, toIndex);
    setitems(newItems);
  }, [filteredItems, page, itemsperpage]);

  const handleChange = (event, value) => {
    setPage(value);
  };
  const handleChangeItemsPerPage = (e) => {
    setitemsperpage(e.target.value);
    setPage(1); //reset page
  };
  if (!items) return <></>;
  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-end"
        sx={{ mb: 3 }}
        alignItems="center"
      >
        <Stack
          spacing={2}
          direction="row"
          style={{ minWidth: "200px" }}
          sx={{ alignItems: "center" }}
        >
          <Pagination count={count} page={page} onChange={handleChange} />
          <FormControl sx={{ ml: 8 }}>
            <InputLabel id="demo-simple-select-label">Par page</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={itemsperpage}
              label="Par page"
              onChange={handleChangeItemsPerPage}
            >
              {DF_ITEMS_PER_PAGE.map((it) => {
                return (
                  <MenuItem key={it} value={it}>
                    {it}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Stack>
      </Box>
      <Grid container>
        <>
          {Array.isArray(items) && (
            <>
              <RefsList items={items} refName={refName} />
            </>
          )}
        </>
      </Grid>
    </>
  );
}
