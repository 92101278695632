import React from "react";
import {
  Button,
  TextField as MuiTextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import CloseIcon from "@mui/icons-material/Close";
import useTchat from "hooks/useTchat";
import ChatFlowTree from "./ChatFlowTree";
// ----------------------------------------------------------------------
export default function ChatFlowEditTree({
  editData,
  addLabel,
  handleComplete,
}) {
  // ----------------------------------------------------------------------
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // ----------------------------------------------------------------------
  const onComplete = () => {
    handleComplete();
  };
  if (!editData) return;
  return (
    <div>
      <Tooltip title={addLabel}>
        <Button
          variant="contained"
          aria-label="details"
          size="small"
          onClick={handleClickOpen}
        >
          <AccountTreeIcon /> Modifier la conversation
        </Button>
      </Tooltip>
      <Dialog fullScreen open={open}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {`${addLabel} ${editData.title}`}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <ChatFlowTree editData={editData} onComplete={onComplete} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

// ----------------------------------------------------------------------
