import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Link,
  Typography as MuiTypography,
} from "@mui/material";

import { spacing } from "@mui/system";
import useReferentiel from "hooks/useReferentiel";
import RefsEditBtn from "pages/refs/RefsEditBtn";
import PaginationRefsGrid from "pages/refs//PaginationRefsGrid";

// ----------------------------------------------------------------------
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const Typography = styled(MuiTypography)(spacing);

export default function ProgramRulePage() {
  const { refs } = useReferentiel();
  // ----------------------------------------------------------------------
  const { programMotor } = refs;
  if (!programMotor) return <></>;
  return (
    <>
      <Helmet title="Configurer des programmes" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Configuration des programmes
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/app">
              Accueil
            </Link>
            <Typography>Règles de d'affectation du programme</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <RefsEditBtn refName="programmotor" addLabel="Nouvelle règle" />
        </Grid>
      </Grid>

      <Divider my={6} />
      <PaginationRefsGrid filteredItems={programMotor} refName="programmotor" />
    </>
  );
}
