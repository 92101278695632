import * as React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Typography } from "@mui/material";
// ----------------------------------------------------------------------
import useReferentiel from "hooks/useReferentiel";
// ----------------------------------------------------------------------
import TabSubjectEdit from "./TabSubjectEdit";
// ----------------------------------------------------------------------

export default function SubjectsTabs({ editProgram }) {
  const [value, setValue] = React.useState(0);
  // ----------------------------------------------------------------------
  const { refs } = useReferentiel();
  // ----------------------------------------------------------------------

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // ----------------------------------------------------------------------

  const getContentTab = () => {
    try {
      if (!getContentTab) return <></>;
      if (!editProgram) return <></>;

      const theme = refTheme.find((it, i) => Number(i) === Number(value));
      if (!theme) return <></>;
      return <TabSubjectEdit editProgram={editProgram} theme={theme} />;
    } catch (error) {
      console.error(error);
      return <></>;
    }
  };
  if (!refs) return <></>;
  const { refTheme } = refs;
  if (!refTheme) return <></>;
  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        p: 4,
      }}
    >
      <Typography variant="h6">
        Gérer les sujets du programme par thème
      </Typography>
      <Tabs value={value} onChange={handleChange} centered>
        {refTheme.map((it, i) => {
          return <Tab key={i} label={it.label} />;
        })}
      </Tabs>
      <Box sx={{ width: "100%", height: "100%" }}>{getContentTab()}</Box>
    </Box>
  );
}
// ----------------------------------------------------------------------
