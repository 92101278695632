import React, { useEffect } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  MenuItem,
  TextField as MuiTextField,
  FormHelperText,
  ListSubheader,
  RadioGroup,
  Box,
  Typography,
  FormLabel,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { spacing } from "@mui/system";
import RadioBtnStyled from "components/custom/RadioBtnStyled";
import useNotification from "hooks/useNotification";
import useReferentiel from "hooks/useReferentiel";
import useProgram from "hooks/useProgram";
import useDataAppcode from "hooks/useDataAppcode";
import CodeGen from "components/CodeGen";
import useDataUser from "hooks/useDataUser";
import { fDateTime } from "utils/formatTime";
// ----------------------------------------------------------------------
const USER_MODES = [
  {
    code: "standard",
    label: "Standard",
  },
  {
    code: "demo",
    label: "Démo",
  },
  {
    code: "test",
    label: "Test",
  },
];
const TextField = styled(MuiTextField)(spacing);
export default function AppcodeForm({ editData, onComplete }) {
  const { programs, getPublishPrograms } = useProgram();
  const { saveAppcode, initAppcodes } = useDataAppcode();
  const { users } = useDataUser();
  const { notif } = useNotification();
  const { refs } = useReferentiel();
  useEffect(() => {
    getPublishPrograms();
  }, []);
  const today = new Date(Date.now() - 86400000);
  const dataSchema = Yup.object().shape({
    code: Yup.string().max(255).required("Veuillez renseigner le code"),
    description: Yup.string().required(`Veuillez renseigner la description`),
    refStatus: Yup.string().max(255).required("Veuillez renseigner le statut"),
    userApp: Yup.string()
      .max(255)
      .required("Veuillez renseigner l'utilisateur d'application"),
    userMode: Yup.string()
      .max(50)
      .required("Veuillez renseigner le mode utilisé"),
    programs: Yup.string().max(255).required("Veuillez renseigner le statut"),
    enabledAt: Yup.date()
      .required(`Veuillez renseigner la date d'activation`)
      .min(
        today,
        "La date d'activation devrait être supérieure à la date courante"
      ),
    isEmailSended: Yup.boolean(),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: editData && editData.appCode ? editData.appCode.code : "",
      description:
        editData && editData.appCode ? editData.appCode.description : "",
      refStatus:
        editData && editData.appCode && editData.appCode.refStatus
          ? editData.appCode.refStatus.id
          : "",
      userApp: editData && editData.userApp ? editData.userApp.id : "",
      userMode:
        editData && editData.userMode ? editData.userMode : USER_MODES[0].code,
      programs: editData && editData.programs ? editData.programs.id : "",
      isEmailSended:
        editData && editData.appCode
          ? Boolean(editData.appCode.isEmailSended)
          : true,
    },
    validationSchema: dataSchema,
    onSubmit: async () => {
      try {
        let newData = {};
        if (editData && editData.id) {
          newData = { ...editData, appCode: { ...values } };
        } else {
          const obj = { programs: values.programs, userApp: values.userApp };
          delete values.programs;
          delete values.userApp;
          newData = { ...obj, appCode: { ...values } };
        }
        await saveAppcode(newData);
        await initAppcodes();
        notif("Sauvegarde réussie");
        onComplete();
      } catch (error) {
        console.error(error);
        notif(null, error);
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
    handleChange,
    setFieldValue,
  } = formik;
  useEffect(() => {
    if (!setFieldValue) return;
    if (editData && editData.appCode.enabledAt) {
      setFieldValue("enabledAt", editData.appCode.enabledAt);
    } else {
      setFieldValue("enabledAt", new Date());
    }
  }, [setFieldValue, editData]);

  const refStatus = Array.isArray(refs.refStatus) ? refs.refStatus : [];
  if (!users || users.length === 0)
    return (
      <>
        Aucun utilisateur d'application n'est trouvé. Veuillez créer au moins un
        utilisateur d'application avant la création d'un code d'invitation
      </>
    );
  if (!programs || programs.length < 1)
    return (
      <>
        Aucun programme publié n'est trouvé. Veuillez créer au moins un avant la
        création d'un code d'invitation
      </>
    );
  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          {editData && editData.appCode && editData.code ? (
            <>
              <TextField
                fullWidth
                type="text"
                label="Code"
                value={editData.appCode.code}
                disabled
                my={2}
              />
            </>
          ) : (
            <>
              <CodeGen
                name="code"
                label="Code d'application"
                title="Générer le code"
                placeholder="Code d'application"
                value={values.code}
                onChange={(val) => {
                  setFieldValue("code", val);
                }}
                error={Boolean(touched.code && errors.v)}
              />

              {errors.code && (
                <FormHelperText error>{errors.code} </FormHelperText>
              )}
            </>
          )}

          <TextField
            fullWidth
            type="text"
            name="description"
            label="Description"
            {...getFieldProps("description")}
            error={Boolean(touched.description && errors.description)}
            helperText={touched.description && errors.description}
            my={2}
          />

          <TextField
            fullWidth
            select
            label="Statut"
            value={values.refStatus}
            onChange={handleChange("refStatus")}
            {...getFieldProps("refStatus")}
            error={Boolean(touched.refStatus && errors.refStatus)}
            helperText={touched.refStatus && errors.refStatus}
            my={2}
          >
            {refStatus.map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            select
            label="Utilisateur"
            value={values.userApp}
            onChange={handleChange("userApp")}
            {...getFieldProps("userApp")}
            error={Boolean(touched.userApp && errors.userApp)}
            helperText={touched.userApp && errors.userApp}
            my={2}
          >
            {users.map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {`${option.pseudo ? option.pseudo + " | " : ""}${
                  option.firstname
                } ${option.lastname} | ${option.email}`}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            select
            label="Mode"
            value={values.userMode}
            onChange={handleChange("userMode")}
            {...getFieldProps("userMode")}
            error={Boolean(touched.userMode && errors.userMode)}
            helperText={touched.userMode && errors.userMode}
            my={2}
          >
            {USER_MODES.map((option, i) => (
              <MenuItem key={i} value={option.code}>
                {`${option.label}`}
              </MenuItem>
            ))}
          </TextField>
          {editData && editData.id && editData.programs ? (
            <>
              <TextField
                fullWidth
                type="text"
                label="Programme affecté"
                value={editData.programs.title}
                disabled
                my={2}
              />
            </>
          ) : (
            <>
              <TextField
                fullWidth
                select
                label="Programme affecté"
                value={values.programs}
                onChange={handleChange("programs")}
                {...getFieldProps("programs")}
                error={Boolean(touched.programs && errors.programs)}
                helperText={touched.programs && errors.programs}
                my={2}
              >
                {programs.map((option, i) => (
                  <MenuItem key={i} value={option.id}>
                    {`${option.title}`}
                  </MenuItem>
                ))}
              </TextField>
            </>
          )}
          {editData && editData.code ? (
            <></>
          ) : (
            <DatePicker
              onChange={(value) => {
                setFieldValue("enabledAt", value, true);
              }}
              value={values.enabledAt}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  label="Date d'activation"
                  {...getFieldProps("enabledAt")}
                  error={Boolean(touched.enabledAt && errors.enabledAt)}
                  helperText={touched.enabledAt && errors.enabledAt}
                  my={2}
                  {...params}
                />
              )}
            />
          )}

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={2}
          >
            <FormLabel component="legend">
              Envoyé un email d'invitation
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={values.isEmailSended}
              onChange={handleChange("isEmailSended")}
            >
              <FormControlLabel
                value={Boolean(true)}
                control={<RadioBtnStyled />}
                label={<Typography>Oui</Typography>}
              />
              <FormControlLabel
                value={Boolean(false)}
                control={<RadioBtnStyled />}
                label={<Typography>Non</Typography>}
              />
            </RadioGroup>
          </Box>
          {editData && editData.code && (
            <>
              <TextField
                fullWidth
                type="text"
                label="Terminal utilisé"
                value={editData.device}
                disabled
                my={2}
              />

              <TextField
                fullWidth
                type="text"
                label="Date d'activation"
                value={fDateTime(editData.enabledAt)}
                disabled
                my={2}
              />
              {editData.lastUsedAt && (
                <TextField
                  fullWidth
                  type="text"
                  label="Date de désactivation"
                  value={fDateTime(editData.lastUsedAt)}
                  disabled
                  my={2}
                />
              )}
              <TextField
                fullWidth
                type="text"
                label="Créé par"
                value={editData.createdby.firstname}
                disabled
                my={2}
              />
              <TextField
                fullWidth
                type="text"
                label="Mis à jour par"
                value={editData.modifiedby.lastname}
                disabled
                my={2}
              />
            </>
          )}
          <Button
            sx={{ my: 2 }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Valider
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
}
