import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  isLoading: true, //by default because we need to initialize appProvider
  isAuthenticated: false,
  user: {},
  accessToken: "",
  refreshToken: null,
  remember: true,
  initialized: false,
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    // INITIALISE
    setInitialize(state, action) {
      state.isLoading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.initialized = true;
      setSession(action.payload.accessToken, action.payload.refreshToken, true);
    },
    setUser(state, action) {
      state.isLoading = false;
      state.user = action.payload.user;
      state.remember = action.payload.remember;
      state.isAuthenticated = true;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      setSession(
        action.payload.accessToken,
        action.payload.refreshToken,
        action.payload.remember
      );
    },
    logout(state) {
      state.isAuthenticated = false;
      state.user = null;
      setSession(null);
    },
  },
});
export const { stopLoading, startLoading, setUser, setInitialize, logout } =
  authSlice.actions;
const authReducer = authSlice.reducer;

export default authReducer;
const setSession = (accessToken, refreshToken, remember) => {
  if (accessToken) {
    if (remember) {
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
    } else {
      sessionStorage.setItem("accessToken", accessToken);
      sessionStorage.setItem("refreshToken", refreshToken);
    }
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    delete axios.defaults.headers.common.Authorization;
  }
};
