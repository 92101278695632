import React, { useEffect, useState } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Divider as MuiDivider } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { spacing } from "@mui/system";
import useNotification from "hooks/useNotification";
import useReferentiel from "hooks/useReferentiel";
import useChallenge from "hooks/useChallenge";
import BackBtn from "components/custom/BackBtn";
import { QUIZ_TYPE_STEREO } from "config";
import QuizEditPanel from "pages/quiz/QuizEditPanel";
import GridImages from "pages/common/GridImages";
import StereoChallengeForm from "./StereoChallengeForm";

// ----------------------------------------------------------------------
const Divider = styled(MuiDivider)(spacing);
// ----------------------------------------------------------------------
export default function StereoChallengeEditPage() {
  const navigate = useNavigate();
  const { id } = useParams();

  const { saveStereoChallenge, findStereoChallengeById } = useChallenge();
  const { notif } = useNotification();
  const { refs } = useReferentiel();
  const [editData, seteditdata] = useState(null);
  const [updatedAt, setupdatedAt] = useState(null);
  // ----------------------------------------------------------------------
  useEffect(() => {
    if (!id) return;
    init();
  }, [id]);
  // ----------------------------------------------------------------------
  useEffect(() => {
    if (!id) return;
    if (!updatedAt) return;
    init();
  }, [id, updatedAt]);

  const init = async () => {
    try {
      const res = await findStereoChallengeById(id);
      if (res) {
        seteditdata(res);
      }
    } catch (error) {
      console.error();
    }
  };
  // ----------------------------------------------------------------------
  const onCompleteSaveImg = () => {
    setupdatedAt(new Date());
  };
  // ----------------------------------------------------------------------

  const handleCompleteDelQuiz = async () => {
    if (!editData) return;
    await saveStereoChallenge({ id: editData.id, quiz: null }); //only reset quiz field
    setupdatedAt(new Date());
  };
  // ----------------------------------------------------------------------
  if (!refs) return <></>;
  if (!editData) return <></>;
  // ----------------------------------------------------------------------

  return (
    <>
      <Helmet title="Edition Stereotype programme" />
      <BackBtn
        backUrl="/app/prog-stereotypechallenge"
        needConfirm={true}
        titleConfim="Demande de confirmation"
        msgConfirm="Des données saisies pourraient ne pas être  Voulez-vous quitter cette page ?"
      />
      <Divider my={6} />
      <StereoChallengeForm editData={editData} />
      <Divider my={2} />
      <GridImages
        editData={editData}
        onComplete={onCompleteSaveImg}
        model="stereotypechallenge"
      />
      <Divider my={2} />
      <QuizEditPanel
        preDefinedType={QUIZ_TYPE_STEREO}
        editData={editData}
        field="quiz"
        addLabel="Questionnaire"
        handleComplete={() => setupdatedAt(new Date())}
        handleCompleteDelQuiz={handleCompleteDelQuiz}
        emptyMsg="Le stéréotype défi n'a pas encore de questionnaire. Veuillez
        ajouter."
      />
    </>
  );
}
