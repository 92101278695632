import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useTheme } from "@mui/material/styles";

import { withTheme } from "@emotion/react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Bar } from "react-chartjs-2";

import { MoreVertical } from "react-feather";

import {
  AvatarGroup as MuiAvatarGroup,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Link,
  Typography as MuiTypography,
} from "@mui/material";

import { spacing } from "@mui/system";
import useChallenge from "hooks/useChallenge";

import ChallengeFilters from "./ChallengeFilters";
import PaginationGridChallenge from "./PaginationGridChallenge";
import ChallengeAddBtn from "./ChallengeAddBtn";

// ----------------------------------------------------------------------
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const Typography = styled(MuiTypography)(spacing);

export default function ProgChallengePage() {
  const [filteredChallenges, setfilteredChallenges] = useState(null);
  const { handleChalFilters, chalFilters, challenges, getChallenges } =
    useChallenge();
  useEffect(() => {
    getChallenges();
  }, []);
  useEffect(() => {
    if (chalFilters) {
      if (!Array.isArray(challenges)) return;
      try {
        const arr = challenges.filter((it) => {
          let isValid = true;
          const myEntries = Object.entries(chalFilters);
          for (let i = 0; i < myEntries.length; i++) {
            const [e, k] = myEntries[i];
            isValid = checkFilter([e, k], it);
            if (!isValid) break;
          }
          return isValid;
        });
        setfilteredChallenges(arr);
      } catch (error) {
        console.error(error);
      }
    } else {
      setfilteredChallenges(challenges);
    }
  }, [challenges, chalFilters]);

  const onChangeFilters = (filters) => {
    try {
      if (!Array.isArray(challenges)) return;
      if (!filters) return;
      handleChalFilters(filters);
    } catch (error) {
      console.error(error);
    }
  };
  const checkFilter = ([e, k], it) => {
    try {
      if (k === "") return true;
      if (e === "theme") {
        return String(it.refSubject.refTheme) === String(k);
      } else if (e === "subject") {
        return String(it.refSubject.id) === String(k);
      } else if (e === "type") {
        return String(it.refChallengeType.id) === String(k);
      } else if (e === "stereotype") {
        return String(it.refStereoType.id) === String(k);
      }
      return true;
    } catch (error) {
      return true;
    }
  };
  return (
    <>
      <Helmet title="Challenge programme" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Défis
            {Array.isArray(filteredChallenges)
              ? `(${filteredChallenges.length})`
              : ""}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/app">
              Accueil
            </Link>
            <Typography>Tous les défis</Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item>
          <ChallengeAddBtn />
        </Grid>
      </Grid>

      <Divider my={6} />
      <ChallengeFilters onChangeFilters={onChangeFilters} />
      {Array.isArray(filteredChallenges) && (
        <PaginationGridChallenge filteredChallenges={filteredChallenges} />
      )}
    </>
  );
}
