import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import {
  Box,
  MenuItem,
  TextField as MuiTextField,
  Button,
  Paper,
  Popover,
  Card,
  CardActions,
  Divider as MuiDivider,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { spacing } from "@mui/system";
import useNotification from "hooks/useNotification";
import useReferentiel from "hooks/useReferentiel";
import useProgram from "hooks/useProgram";
import { getLabelOperator, getLabelValues } from "utils/ruleUtil";
import RuleDataForm from "./RuleDataForm";
// ----------------------------------------------------------------------

const Divider = styled(MuiDivider)(spacing);

// ----------------------------------------------------------------------
const TextField = styled(MuiTextField)(spacing);
// ----------------------------------------------------------------------
const ItemPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  height: "auto",
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: "flex-start",
}));
// ----------------------------------------------------------------------

export default function RefProgramRuleForm({ editData, refName, onComplete }) {
  const { refs, saveRef, initRefs } = useReferentiel();
  const { notif } = useNotification();
  // ----------------------------------------------------------------------
  const handleSave = async (data, field) => {
    try {
      const newData = { ...editData };
      newData[field] = data;
      await saveRef(
        {
          ...newData,
        },
        refName
      );
      await initRefs();
      notif("Sauvegarde réussie");
    } catch (error) {
      console.error(error);
      notif(null, error);
    }
  };
  // ----------------------------------------------------------------------

  const getArr = () => {
    if (!editData) return [];
    const arr = [
      {
        field: "snotes",
        label: "Niveau de maturité",
        refData: refs.refStereoType,
        item: editData.snotes,
        formEdit: (
          <RuleDataForm
            editData={editData.snotes}
            handleSave={handleSave}
            field="snotes"
            refData={refs.refStereoType}
            typeRule="arrayType"
            fieldLabel="Snote"
          />
        ),
      },
      {
        field: "interests",
        label: "Intérêts",
        item: editData.interests,
        refData: refs.refInterest,
        formEdit: (
          <RuleDataForm
            editData={editData.interests}
            handleSave={handleSave}
            field="interests"
            refData={refs.refInterest}
            typeRule="arrayType"
            fieldLabel="Intérêts"
          />
        ),
      },
    ];
    return arr;
  };

  // ----------------------------------------------------------------------

  return (
    <>
      <FormEdit editData={editData} />
      <Divider my={3} />
      {editData && (
        <>
          {getArr().map((it, i) => {
            const { refData, field, label, formEdit, item } = it;
            const operatorLabel = getLabelOperator(item);
            const operatorValues = getLabelValues(refData, item);
            return (
              <ItemPaper square={false} key={i}>
                <Box
                  display="flex"
                  sx={{ width: "100%" }}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <RuleEditBtn
                    editData={editData[field]}
                    handleSave={handleSave}
                    field={field}
                    refData={refData}
                    formEdit={formEdit}
                  />
                  <h5>
                    {label}&nbsp;
                    {operatorLabel && (
                      <span style={{ color: "red" }}>{operatorLabel} </span>
                    )}{" "}
                  </h5>
                  &nbsp;
                  {operatorValues}
                </Box>
              </ItemPaper>
            );
          })}
        </>
      )}
    </>
  );
}
// ----------------------------------------------------------------------

const RuleEditBtn = ({ formEdit }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Button
        aria-label="details"
        size="small"
        onClick={handleClick}
        color="primary"
      >
        <EditIcon /> Modifier
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card
          sx={{
            minWidth: 575,
            p: 4,
          }}
        >
          {formEdit}
          <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={handleClose}>Annuler</Button>
          </CardActions>
        </Card>
      </Popover>
    </div>
  );
};

// ----------------------------------------------------------------------
const FormEdit = ({ editData }) => {
  // ----------------------------------------------------------------------

  const refName = "programmotor";
  const { programs, getPublishPrograms } = useProgram();
  const { refs, saveRef, initRefs } = useReferentiel();
  const { notif } = useNotification();
  // ----------------------------------------------------------------------

  useEffect(() => {
    getPublishPrograms();
  }, []);
  const dataSchema = Yup.object().shape({
    code: Yup.string().max(20).required("Veuillez renseigner le code"),
    label: Yup.string().max(50).required(`Veuillez renseigner le libellé`),
    programs: Yup.string()
      .max(50)
      .required(`Veuillez renseigner le programme publié`),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: editData && editData.code ? editData.code : "",
      label: editData && editData.label ? editData.label : "",
      programs: editData && editData.programs ? editData.programs : "",
    },
    validationSchema: dataSchema,
    onSubmit: async () => {
      try {
        const newData =
          editData && editData.id ? { ...editData, ...values } : { ...values };
        await saveRef(
          {
            ...newData,
          },
          refName
        );
        await initRefs();
        notif("Sauvegarde réussie");
      } catch (error) {
        console.error(error);
        notif(null, error);
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleChange,
    handleSubmit,
    getFieldProps,
    setSubmitting,
  } = formik;
  if (!programs) return <>Aucun programme publié n'est trouvé</>;
  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <TextField
            fullWidth
            type="text"
            label="Code"
            {...getFieldProps("code")}
            error={Boolean(touched.code && errors.code)}
            helperText={touched.code && errors.code}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            name="label"
            label="Libellé"
            {...getFieldProps("label")}
            error={Boolean(touched.label && errors.label)}
            helperText={touched.label && errors.label}
            my={2}
          />
          <TextField
            fullWidth
            select
            label="Programme publié"
            value={values.programs}
            onChange={handleChange("programs")}
            {...getFieldProps("programs")}
            error={Boolean(touched.programs && errors.programs)}
            helperText={touched.programs && errors.programs}
            my={2}
          >
            {programs.map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {option.title}
              </MenuItem>
            ))}
          </TextField>
          <Button
            sx={{ my: 2 }}
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Valider
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
};
