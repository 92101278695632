import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { MoreVertical } from "react-feather";

import {
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  CardHeader,
  Typography as MuiTypography,
  IconButton,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";

import DraftsIcon from "@mui/icons-material/Drafts";
import AppShortcutIcon from "@mui/icons-material/AppShortcut";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { spacing } from "@mui/system";
import ProgramDelBtn from "./ProgramDelBtn";
import ProgramCopyBtn from "./ProgramCopyBtn";

// ----------------------------------------------------------------------

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Typography = styled(MuiTypography)(spacing);

/* item card */
export default function DetailProgram({ data }) {
  const getChip = () => {
    try {
      if (!data) return <></>;
      if (data.isPublished) {
        return (
          <Chip
            label="Publié"
            onClick={() => console.log()}
            onDelete={() => console.log()}
            color="success"
            variant="contained"
            deleteIcon={<AppShortcutIcon />}
            sx={{ my: 1 }}
          />
        );
      } else if (data.isValidated) {
        return (
          <Chip
            label="Validé"
            onClick={() => console.log()}
            onDelete={() => console.log()}
            color="primary"
            variant="contained"
            deleteIcon={<BookmarkAddedIcon />}
            sx={{ my: 1 }}
          />
        );
      } else if (data.isDraff) {
        return (
          <Chip
            label="Brouillon"
            onClick={() => console.log()}
            onDelete={() => console.log()}
            color="warning"
            variant="contained"
            deleteIcon={<DraftsIcon />}
            sx={{ my: 1 }}
          />
        );
      }
      return <></>;
    } catch (error) {
      return <></>;
    }
  };

  if (!data) return <></>;
  return (
    <Card>
      <CardHeader action={<PositionedMenu data={data} />} title={data.title} />
      <CardContent>
        {getChip()}
        <Typography mb={4} color="textSecondary" component="p">
          {`${data.description}`}
        </Typography>
      </CardContent>
    </Card>
  );
}
// ----------------------------------------------------------------------

const PositionedMenu = ({ data }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertical />
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem component={NavLink} to={`/app/edit-program/${data.id}`}>
          <Button
            aria-label="details"
            size="small"
            onClick={handleClick}
            color="primary"
          >
            {data.isPublished ? (
              <>
                <VisibilityIcon /> Voir
              </>
            ) : (
              <>
                <EditIcon /> Modifier
              </>
            )}
          </Button>
        </MenuItem>

        {!data.isPublished && (
          <MenuItem>
            <ProgramDelBtn editData={data} onComplete={handleClose} />
          </MenuItem>
        )}
        <MenuItem>
          <ProgramCopyBtn editData={data} onComplete={handleClose} />
        </MenuItem>
      </Menu>
    </div>
  );
};
