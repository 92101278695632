import { useDispatch, useSelector } from "react-redux";
import {
  startLoading,
  stopLoading,
  setInitAppconfig,
  setInitialize,
} from "redux/slices/refSlice";
import axios from "axios";
import { API_URL } from "config";
import useNotification from "hooks/useNotification";
// ----------------------------------------------------------------------

export default function useReferentiel() {
  const dispatch = useDispatch();
  const { refs, appconfigs } = useSelector((state) => state.ref);
  const { notif } = useNotification();
  return {
    refs,
    appconfigs,
    initRefs: async () => {
      try {
        dispatch(startLoading());
        const res = await axios.get(API_URL.refs);
        if (res && res.data) {
          const refs = res.data;
          dispatch(setInitialize({ refs }));
        } else {
          throw new Error("Référentiels ne sont pas disponibles");
        }
      } catch (error) {
        dispatch(stopLoading());
        console.error(error);
        throw new Error("Référentiels ne sont pas disponibles");
      }
    },
    initAppconfigs: async () => {
      try {
        dispatch(startLoading());
        const res = await axios.get(API_URL.appconfig);
        if (res && res.data) {
          const apponfigs = res.data;
          dispatch(setInitAppconfig([...apponfigs]));
        } else {
          throw new Error("Configurations ne sont pas disponibles");
        }
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    saveRef: async (o, refName) => {
      try {
        dispatch(startLoading());
        delete o.modifiedby;
        delete o.createdby;
        if (o && o.id) {
          await axios.patch(API_URL[refName] + "/" + o.id, o);
        } else {
          await axios.post(API_URL[refName], o);
        }
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    delRef: async (o, refName) => {
      try {
        dispatch(startLoading());
        if (o && o.id) {
          await axios.delete(API_URL[refName] + "/" + o.id);
        }
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    saveReftheme: async (o) => {
      try {
        dispatch(startLoading());
        delete o.modifiedby;
        delete o.createdby;
        if (o && o.id) {
          await axios.patch(API_URL.reftheme + "/" + o.id, o);
        } else {
          await axios.post(API_URL.reftheme, o);
        }
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    saveRefsubject: async (o) => {
      try {
        dispatch(startLoading());
        delete o.modifiedby;
        delete o.createdby;
        if (o && o.id) {
          await axios.patch(API_URL.refsubject + "/" + o.id, o);
        } else {
          await axios.post(API_URL.refsubject, o);
        }
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    saveImageByRefName: async (o, field, image, refName) => {
      try {
        if (!o || !o.id) return;
        if (!field) return;
        if (!refName) return;
        dispatch(startLoading());
        const formData = new FormData();
        formData.append("image", image);
        formData.append("field", field);
        formData.append("id", o.id);
        await axios.post(API_URL[refName] + "/image", formData);
        notif("Sauvegarde réussie");
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    saveVoiceRefsubject: async (o, field, image) => {
      try {
        if (!o || !o.id) return;
        if (!field) return;
        dispatch(startLoading());
        const formData = new FormData();
        formData.append("voice", image);
        formData.append("field", field);
        formData.append("idRef", o.id);
        if (o[field]) {
          formData.append("id", o[field]);
        }

        await axios.post(API_URL.refsubject + "/voice", formData);
        notif("Sauvegarde réussie");
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    cloneRefsubject: async (o) => {
      try {
        dispatch(startLoading());
        await axios.get(API_URL.refsubject + "/clone/" + o.id);
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    saveRefActionPoint: async (o) => {
      try {
        dispatch(startLoading());
        delete o.modifiedby;
        delete o.createdby;
        if (o && o.id) {
          await axios.patch(API_URL.refactionpoint + "/" + o.id, o);
        } else {
          await axios.post(API_URL.refactionpoint, o);
        }
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    delRefsubject: async (o) => {
      try {
        dispatch(startLoading());
        if (o && o.id) {
          await axios.delete(API_URL.refsubject + "/" + o.id);
        }
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    /** contact book */
    saveContact: async (o) => {
      try {
        dispatch(startLoading());
        if (o && o.id) {
          await axios.patch(API_URL.contact + "/" + o.id, o);
        } else {
          await axios.post(API_URL.contact, o);
        }
        notif("Sauvegarde réussie");
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },

    generatePasswordContact: async (o) => {
      try {
        dispatch(startLoading());
        if (o && o.id) {
          await axios.get(API_URL.contact + "/" + o.id + "/generatepass");
          notif("Le mot de passe est généré et envoyé par email");
        }
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    sendMailToContact: async (o) => {
      try {
        dispatch(startLoading());
        if (o && o.id) {
          await axios.get(API_URL.contact + "/" + o.id + "/sendmail");
          notif("Envoie les informations par mail réussie");
        }
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    delContact: async (o) => {
      try {
        dispatch(startLoading());
        if (o && o.id) {
          await axios.delete(API_URL.contact + "/" + o.id);
          notif("Suppression réussie");
        }
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    /** thematic book */
    saveThematic: async (o) => {
      try {
        dispatch(startLoading());
        if (o && o.id) {
          await axios.patch(API_URL.thematic + "/" + o.id, o);
        } else {
          await axios.post(API_URL.thematic, o);
        }
        notif("Sauvegarde réussie");
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    delThematic: async (o) => {
      try {
        dispatch(startLoading());
        if (o && o.id) {
          await axios.delete(API_URL.thematic + "/" + o.id);
          notif("Suppression réussie");
        }
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    /** Support zone book */
    saveSupportZone: async (o) => {
      try {
        dispatch(startLoading());
        if (o && o.id) {
          await axios.patch(API_URL.supportzone + "/" + o.id, o);
        } else {
          await axios.post(API_URL.supportzone, o);
        }
        notif("Sauvegarde réussie");
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },

    delSupportZone: async (o) => {
      try {
        dispatch(startLoading());
        if (o && o.id) {
          await axios.delete(API_URL.supportzone + "/" + o.id);
          notif("Suppression réussie");
        }
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    /** Support communication book */
    saveSupportCom: async (o) => {
      try {
        dispatch(startLoading());
        if (o && o.id) {
          await axios.patch(API_URL.supportcom + "/" + o.id, o);
        } else {
          await axios.post(API_URL.supportcom, o);
        }
        notif("Sauvegarde réussie");
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },

    delSupportCom: async (o) => {
      try {
        dispatch(startLoading());
        if (o && o.id) {
          await axios.delete(API_URL.supportcom + "/" + o.id);
          notif("Suppression réussie");
        }
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    /** Support lang book */
    saveSupportLang: async (o) => {
      try {
        dispatch(startLoading());
        if (o && o.id) {
          await axios.patch(API_URL.supportlang + "/" + o.id, o);
        } else {
          await axios.post(API_URL.supportlang, o);
        }
        notif("Sauvegarde réussie");
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },

    delSupportLang: async (o) => {
      try {
        dispatch(startLoading());
        if (o && o.id) {
          await axios.delete(API_URL.supportlang + "/" + o.id);
          notif("Suppression réussie");
        }
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    getSubjectsByProgRule: async (idProgramRule) => {
      try {
        if (!idProgramRule) return;
        const res = await axios.get(
          `${API_URL.programmotor}/${idProgramRule}/subjects`
        );
        if (res && res.data) {
          return res.data;
        }
        return null;
      } catch (error) {
        notif(null, error);
        throw error;
      }
    },
  };
}
