import { useDispatch, useSelector } from "react-redux";
import { startLoading, initGifts, stopLoading } from "redux/slices/giftSlice";
import axios from "axios";
import { API_URL } from "config";
import useNotification from "hooks/useNotification";
// ----------------------------------------------------------------------

export default function useDataUser() {
  const dispatch = useDispatch();
  const { gifts, isLoading, isLoaded } = useSelector((state) => state.gift);
  const { notif } = useNotification();
  return {
    gifts,
    isLoaded,
    isLoading,
    getGifts: async () => {
      try {
        dispatch(startLoading());
        const res = await axios.get(API_URL.gift);
        if (res && res.data) {
          dispatch(initGifts([...res.data]));
        } else {
          throw new Error("Cadeaux ne sont pas disponibles");
        }
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw new Error("Cadeaux ne sont pas disponibles");
      }
    },

    getGiftById: async (id) => {
      try {
        const res = await axios.get(API_URL.gift + "/" + id);
        if (res && res.data) {
          return res.data;
        } else {
          throw new Error("UsersData ne sont pas disponibles");
        }
      } catch (error) {
        notif(null, error);
        throw new Error("Référentiels ne sont pas disponibles");
      }
    },
    saveGift: async (o) => {
      try {
        delete o.createdby;
        delete o.modifiedby;
        if (o && o.id) {
          const res = await axios.patch(API_URL.gift + "/" + o.id, o);
        } else {
          const res = await axios.post(API_URL.gift, o);
        }
        notif("Sauvegarde réussie");
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    saveImageGift: async (o, field, image) => {
      try {
        if (!o || !o.id) return;
        if (!field) return;
        dispatch(startLoading());
        const formData = new FormData();
        formData.append("image", image);
        formData.append("field", field);
        formData.append("id", o.id);
        await axios.post(API_URL.gift + "/image", formData);
        notif("Sauvegarde réussie");
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    saveGalleryGift: async (o, field, images) => {
      try {
        if (!o || !o.id) return;
        if (!field) return;
        dispatch(startLoading());
        const formData = new FormData();
        images.forEach((it) => {
          formData.append("images", it);
        });

        formData.append("field", field);
        formData.append("id", o.id);
        await axios.post(API_URL.gift + "/images", formData);
        notif("Sauvegarde réussie");
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    delGift: async (o) => {
      try {
        dispatch(startLoading());
        if (o && o.id) {
          await axios.delete(API_URL.gift + "/" + o.id);
        }
        notif("Suppression réussie");
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    delImgFromGallery: async (o, img) => {
      try {
        dispatch(startLoading());
        if (o && o.id) {
          await axios.delete(API_URL.gift + "/" + o.id + "/galleryimg/" + img);
        }
        notif("Suppression réussie");
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    getStockByGift: async (idGift) => {
      try {
        const res = await axios.get(API_URL.giftStock + "/byGift/" + idGift);
        if (res && res.data) {
          return res.data;
        } else {
          throw new Error("UsersData ne sont pas disponibles");
        }
      } catch (error) {
        console.error(error);
        throw new Error("Référentiels ne sont pas disponibles");
      }
    },
    saveGiftStock: async (o) => {
      try {
        delete o.createdby;
        delete o.modifiedby;
        if (o && o.id) {
          const res = await axios.patch(API_URL.giftStock + "/" + o.id, o);
        } else {
          const res = await axios.post(API_URL.giftStock, o);
        }
        notif("Sauvegarde réussie");
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
  };
}
