import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import {
  Box,
  TextField as MuiTextField,
  Button,
  Paper,
  Divider as MuiDivider,
  Container,
  Typography,
  Grid,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Helmet } from "react-helmet-async";
import { spacing } from "@mui/system";
import useProgram from "hooks/useProgram";
import BackBtn from "components/custom/BackBtn";
import useReferentiel from "hooks/useReferentiel";
import ProgramCreateBtn from "./ProgramCreateBtn";
import PublishBtn from "./PublishBtn";
import ProgramCreateForm from "./ProgramCreateForm";
import RefsEditMediaBtn from "../refs/RefsEditMediaBtn";
import SubjectsTabs from "./SubjectsTabs";
import ProgramSubjects from "./ProgramSubjects";
// ----------------------------------------------------------------------

const Divider = styled(MuiDivider)(spacing);
// ----------------------------------------------------------------------

export default function ProgramEditPage() {
  const { id } = useParams();
  const { refs } = useReferentiel();
  const { saveImageProgram, appProgram, isLoaded, initProgramById } =
    useProgram();
  // ----------------------------------------------------------------------
  const [updatedAt, setupdatedAt] = useState(null);
  // ----------------------------------------------------------------------
  useEffect(() => {
    if (!id) return;
    initProgramById(id);
  }, [id]);
  // ----------------------------------------------------------------------

  useEffect(() => {
    if (!id) return;
    initProgramById(id);
  }, [id]);
  useEffect(() => {
    if (!id) return;
    if (!updatedAt) return;
    initProgramById(id);
  }, [id, updatedAt]);
  // ----------------------------------------------------------------------
  const handleComplete = async (editData, field, editObj) => {
    try {
      await saveImageProgram(editData, field, editObj);
      await initProgramById(id);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  // ----------------------------------------------------------------------
  // ----------------------------------------------------------------------

  if (!appProgram && isLoaded) return <EmptyProgram />;
  if (!appProgram.id) return <></>;
  if (!appProgram || !appProgram.id) return <></>;
  const disabledEdit =
    appProgram && appProgram.id && appProgram.isPublished ? true : false;
  return (
    <>
      <Helmet title="Edition Programme" />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <BackBtn
          backUrl="/app/program"
          needConfirm={true}
          titleConfim="Demande de confirmation"
          msgConfirm="Des données saisies pourraient ne pas être enregistrées. Voulez-vous quitter cette page ?"
        />

        <PublishBtn
          editProgram={appProgram}
          onComplete={() => setupdatedAt(new Date())}
        />
      </Box>
      <Divider my={6} />
      <ProgramCreateForm
        editProgram={appProgram}
        onComplete={() => setupdatedAt(new Date())}
        disabledEdit={disabledEdit}
      />
      <Divider my={2} />
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography variant="h6" sx={{ mr: 3 }}>
          {appProgram.image
            ? "Image enregistrée, voulez-vous la modifier ? Cliquez sur ce bouton"
            : "Ajouter une image en cliquant sur ce bouton"}
        </Typography>

        <RefsEditMediaBtn
          field="image"
          editData={appProgram}
          addLabel="Image"
          handleComplete={handleComplete}
          disabledEdit={disabledEdit}
        />
      </Box>
      <Divider my={4} />
      {/*  edit by theme maybe we need in the future <SubjectsTabs editProgram={appProgram} /> */}
      <ProgramSubjects editProgram={appProgram}/>
    </>
  );
}
// ----------------------------------------------------------------------

const EmptyProgram = () => {
  const [display, setdisplay] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setdisplay(true);
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return (
    <Container>
      {display && (
        <>
          <Typography variant="h2" component="h3" gutterBottom>
            Le programme demandé n'est pas disponible.
          </Typography>
          <Box mb={8} />
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Button
                component={NavLink}
                to="/app/program"
                variant="contained"
                color="primary"
              >
                <ArrowBackIosIcon />
                Retourner au catalogue
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <ProgramCreateBtn />
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  );
};
// ----------------------------------------------------------------------
