import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import {
  MenuItem,
  TextField as MuiTextField,
  Button,
  Divider as MuiDivider,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { spacing } from "@mui/system";
import useReferentiel from "hooks/useReferentiel";
import useGift from "hooks/useGift";
import AlertMsg from "components/custom/AlertMsg";
// ----------------------------------------------------------------------
const Divider = styled(MuiDivider)(spacing);
const TextField = styled(MuiTextField)(spacing);

export default function GiftForm({ editData, onComplete }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { getStockByGift, getGifts, getGiftById, saveGift } = useGift();
  const { refs } = useReferentiel();
  const { refGiftCategory } = refs;

  const today = new Date(Date.now() - 86400000);
  const dataSchema = Yup.object().shape({
    code: Yup.string().max(20).required("Veuillez renseigner le code"),
    title: Yup.string().max(100).required(`Veuillez renseigner le titre`),
    description: Yup.string(),
    conditions: Yup.string()
      .max(500)
      .required(`Veuillez renseigner les conditions`),
    pointValue: Yup.number().required(`Veuillez renseigner le titre`),
    refGiftCategory: Yup.string()
      .max(100)
      .required(`Veuillez renseigner le type`),
    enableFrom: Yup.date()
      .required(`Veuillez renseigner le début de validité`)
      .min(today, "La date devrait être supérieure à la date courante"),
    enableEnd: Yup.date()
      .required(`Veuillez renseigner la fin de validité`)
      .min(today, "La date devrait être supérieure à la date courante"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      code: editData && editData.code ? editData.code : "",
      title: editData && editData.title ? editData.title : "",
      description: editData && editData.description ? editData.description : "",
      conditions: editData && editData.conditions ? editData.conditions : "",
      pointValue: editData && editData.pointValue ? editData.pointValue : "",
      refGiftCategory:
        editData && editData.refGiftCategory ? editData.refGiftCategory.id : "",
    },
    validationSchema: dataSchema,
    onSubmit: async () => {
      try {
        const newData =
          editData && editData.id ? { ...editData, ...values } : { ...values };
        await saveGift({
          ...newData,
        });
        await getGifts();
        onComplete();
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
    handleChange,
    setFieldValue,
  } = formik;
  useEffect(() => {
    if (!setFieldValue) return;
    if (editData && editData.enableFrom) {
      setFieldValue("enableFrom", editData.enableFrom);
    } else {
      setFieldValue("enableFrom", new Date());
    }
    if (editData && editData.enableEnd) {
      setFieldValue("enableEnd", editData.enableEnd);
    } else {
      setFieldValue("enableEnd", new Date());
    }
  }, [setFieldValue, editData]);
  if (!refs) return;
  if (!refGiftCategory) return <>Veuillez entrer au moin un type de cadeau</>;
  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <AlertMsg errors={errors} />
          <TextField
            fullWidth
            type="text"
            label="Code"
            {...getFieldProps("code")}
            error={Boolean(touched.code && errors.code)}
            helperText={touched.code && errors.code}
            my={2}
          />
          <TextField
            fullWidth
            type="title"
            label="Titre"
            {...getFieldProps("title")}
            error={Boolean(touched.title && errors.title)}
            helperText={touched.title && errors.title}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            name="description"
            label="Description"
            {...getFieldProps("description")}
            error={Boolean(touched.description && errors.description)}
            helperText={touched.description && errors.description}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            name="conditions"
            label="Disponibilités & Conditions"
            {...getFieldProps("conditions")}
            error={Boolean(touched.conditions && errors.conditions)}
            helperText={touched.conditions && errors.conditions}
            my={2}
            multiline
            rows={3}
            maxRows={6}
          />
          <TextField
            fullWidth
            type="number"
            name="pointValue"
            label="Prix d'achat (en meuz'coins)"
            {...getFieldProps("pointValue")}
            error={Boolean(touched.pointValue && errors.pointValue)}
            helperText={touched.pointValue && errors.pointValue}
            my={2}
          />
          <DatePicker
            onChange={(value) => {
              setFieldValue("enableFrom", value, true);
            }}
            value={values.enableFrom}
            renderInput={(params) => (
              <TextField
                fullWidth
                label="Début de validité"
                {...getFieldProps("enableFrom")}
                error={Boolean(touched.enableFrom && errors.enableFrom)}
                helperText={touched.enableFrom && errors.enableFrom}
                my={2}
                {...params}
              />
            )}
          />
          <DatePicker
            onChange={(value) => {
              setFieldValue("enableEnd", value, true);
            }}
            value={values.enableEnd}
            renderInput={(params) => (
              <TextField
                fullWidth
                label="Fin de validité"
                {...getFieldProps("enableEnd")}
                error={Boolean(touched.enableEnd && errors.enableEnd)}
                helperText={touched.enableEnd && errors.enableEnd}
                my={2}
                {...params}
              />
            )}
          />
          <TextField
            fullWidth
            select
            label="Type de cadeau"
            value={values.refGiftCategory}
            onChange={handleChange("refGiftCategory")}
            {...getFieldProps("refGiftCategory")}
            error={Boolean(touched.refGiftCategory && errors.refGiftCategory)}
            helperText={touched.refGiftCategory && errors.refGiftCategory}
            my={2}
          >
            {refGiftCategory.map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {`${option.label}`}
              </MenuItem>
            ))}
          </TextField>
          <Divider my={3} />
          <Button
            sx={{ my: 2 }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Valider
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
}
