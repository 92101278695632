import React from "react";
import {
  TextField as MuiTextField,
  Box,
  Stack,
  Typography,
  Paper,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import QuizEditBtn from "./QuizEditBtn";
import QuizDelBtn from "./QuizDelBtn";
import QuestionPanel from "./QuestionPanel";

// ----------------------------------------------------------------------
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));
// ----------------------------------------------------------------------
export default function QuizEditPanel({
  editData,
  preDefinedType,
  field,
  addLabel,
  handleComplete,
  handleCompleteDelQuiz,
  emptyMsg,
}) {
  return (
    <>
      <Box sx={{ flexGrow: 1, overflow: "hidden", px: 3 }}>
        <Item
          sx={{
            my: 1,
            mx: "auto",
            p: 2,
          }}
        >
          {editData[field] ? (
            <>
              <Stack
                spacing={2}
                direction="row"
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                sx={{ width: "100%" }}
              >
                <QuizEditBtn
                  preDefinedType={preDefinedType}
                  editData={editData}
                  field={field}
                  handleComplete={handleComplete}
                  addLabel={addLabel}
                />
                <Typography noWrap>{editData[field].title}</Typography>
                <QuizDelBtn
                  field={field}
                  editData={editData}
                  handleCompleteDelQuiz={handleCompleteDelQuiz}
                />
              </Stack>
            </>
          ) : (
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              sx={{ width: "100%" }}
            >
              <Typography noWrap>{emptyMsg}</Typography>
              <QuizEditBtn
                preDefinedType={preDefinedType}
                editData={editData}
                field={field}
                addLabel="Questionnaire"
                handleComplete={handleComplete}
              />
            </Stack>
          )}
        </Item>
      </Box>

      {editData[field] && <QuestionPanel quiz={editData[field]} />}
    </>
  );
}
// ----------------------------------------------------------------------
