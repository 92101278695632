import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Popover,
  Card,
  CardActions,
  CardContent,
  Button,
  TextField,
  ListSubheader,
  Paper,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  Chip,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { lime } from "@mui/material/colors";
import useReferentiel from "../../hooks/useReferentiel";
// ----------------------------------------------------------------------
const MuiPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(4),
}));
// ----------------------------------------------------------------------

export default function LastNodeContactEdit({ editOptions, onComplete }) {
  const { refs } = useReferentiel();
  const [anchorEl, setAnchorEl] = React.useState(null);
  // ----------------------------------------------------------------------
  const theme = useTheme();
  // ----------------------------------------------------------------------
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // ----------------------------------------------------------------------
  const handleComplete = (val) => {
    onComplete(val);
    handleClose();
  };
  // ----------------------------------------------------------------------
  const { contact } = refs;
  // ----------------------------------------------------------------------
  if (!contact) return <>Aucun contact n'est trouvé</>;
  return (
    <Box
      sx={{
        py: 2,
        width: "100%",
        maxWidth: 460,
      }}
    >
      <div>
        <Button
          fullWidth
          aria-label="details"
          size="small"
          onClick={handleClick}
          color="error"
          variant="outlined"
        >
          <EditIcon style={{ fontSize: 18 }} />
          Gérer les contacts
        </Button>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={() => console.log()}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Card
            sx={{
              minWidth: 475,
              backgroundColor: theme.palette.warning.main,
            }}
          >
            <CardContent>
              {open && (
                <FormEdit
                  contact={contact}
                  editOptions={editOptions}
                  onComplete={handleComplete}
                />
              )}
            </CardContent>
            <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                size="small"
                color="error"
                variant="outlined"
                onClick={handleClose}
                sx={{ color: "white" }}
              >
                Annuler
              </Button>
            </CardActions>
          </Card>
        </Popover>
      </div>
      <MuiPaper>
        <List
          sx={{ backgroundColor: "#fff" }}
          subheader={
            <ListSubheader
              sx={{ color: "red" }}
              component="div"
              id="nested-list-subheader"
            >
              {editOptions && editOptions.length > 0
                ? "Les options disponibles"
                : "Aucune option disponible"}
            </ListSubheader>
          }
        >
          {editOptions && editOptions.length > 0 && (
            <>
              {editOptions.map((it, i) => {
                return (
                  <React.Fragment key={i}>
                    <ListItem>
                      <ListItemText primary={it.name} />
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                );
              })}
            </>
          )}
        </List>
      </MuiPaper>
    </Box>
  );
}
// ----------------------------------------------------------------------
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 350,
    },
  },
};
// ----------------------------------------------------------------------

const FormEdit = ({ contact, editOptions, onComplete }) => {
  // ----------------------------------------------------------------------
  const [options, setoptions] = useState([]);
  // ----------------------------------------------------------------------
  useEffect(() => {
    if (!editOptions) return;
    setoptions(editOptions.map((it) => it.id));
  }, [editOptions]);
  // ----------------------------------------------------------------------
  const handleValidate = () => {
    if (!options) return;
    if (!contact) return;
    const contactFounded = contact
      .filter((it) => options.includes(it.id))
      .map((it) => {
        //format contact before persist to database
        const { address, telephone, id, label, shortDescription, description } =
          it;
        const objContact = {
          id,
          name: label,
          phone: telephone,
          site: address && address.startsWith("http") ? address : "",
          address,
          shortDescription,
          notes: description,
        };
        return objContact;
      });
    onComplete(contactFounded);
  };

  // ----------------------------------------------------------------------

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setoptions(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  // ----------------------------------------------------------------------

  return (
    <Box
      sx={{
        bgcolor: "#fff",
        p: 4,
        minHeight: "250px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <FormControl sx={{ m: 1, maxWidth: "400px" }}>
        <InputLabel id="demo-multiple-checkbox-label">Contacts</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          fullWidth
          value={options}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => {
            const contactFounded = contact.filter((it) =>
              selected.includes(it.id)
            );
            if (!contactFounded) return "none";
            return (
              <>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {contactFounded.map((it, i) => (
                    <Chip key={i} label={it.label} />
                  ))}
                </Box>
              </>
            );
          }}
          MenuProps={MenuProps}
        >
          {contact.map((it, i) => (
            <MenuItem key={i} value={it.id}>
              <Checkbox checked={options.indexOf(it.id) > -1} />
              <ListItemText primary={it.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {options && options.length > 0 && (
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleValidate}
        >
          Valider
        </Button>
      )}
    </Box>
  );
};
