import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import {
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grid,
  Paper,
  Box,
  CardMedia as MuiCardMedia,
  Tooltip,
} from "@mui/material";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import UploadSingleFile from "components/file/UploadSingleFile";
import AudioBoxFromUrl from "components/media/AudioBoxFromUrl";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { API_URL } from "config";
import EditFormSubtitle from "components/media/EditFormSubtitle";

// ----------------------------------------------------------------------
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------
export default function RefsEditVoiceBtn({
  editData,
  field,
  addLabel,
  handleCompleteVoice,
  handleCompleteTranscript,
}) {
  const [submitting, setsubmitting] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [editAudio, seteditAudio] = useState(null);
  const [currentAudio, setcurrentAudio] = useState(null);

  // ----------------------------------------------------------------------
  useEffect(() => {
    if (!open) return;
    if (!editData) return;
    if (!editData[field]) return;
    const init = async () => {
      try {
        const res = await axios.get(
          `${API_URL.voiceTranscript}/${editData[field]}`
        );
        if (res && res.data) {
          setcurrentAudio(res.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [editData, open]);
  // ----------------------------------------------------------------------
  useEffect(() => {
    if (open) return;
    seteditAudio(null);
  }, [open]);
  // ----------------------------------------------------------------------
  useEffect(() => {
    return () => {
      seteditAudio(null);
    };
  }, []);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // ----------------------------------------------------------------------

  const handleValidate = async () => {
    setsubmitting(true);
    try {
      if (!editAudio) return;
      await handleCompleteVoice(editData, field, editAudio);
      handleClose();
    } catch (error) {
      console.error(error);
    }
    setsubmitting(false);
  };
  // ----------------------------------------------------------------------
  const onCompleteTranscript = async () => {
    await handleCompleteTranscript();
    handleClose();
  };
  // ----------------------------------------------------------------------
  const isDisabledValidate = editAudio ? false : true;
  const color = editData && editData[field] ? "primary" : "default";
  return (
    <div>
      <Tooltip title="Voie et transcripts">
        <IconButton
          aria-label="details"
          size="small"
          onClick={handleClickOpen}
          color={color}
        >
          <RecordVoiceOverIcon size="small" />
        </IconButton>
      </Tooltip>
      <Dialog maxWidth="md" open={open} fullWidth>
        <DialogTitle> {addLabel}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Item>
                {currentAudio ? (
                  <AudioBoxFromUrl audio={`${currentAudio.voice}`} />
                ) : (
                  <></>
                )}
              </Item>
            </Grid>
            <Grid item xs={12}>
              <Item>
                {currentAudio && currentAudio.transcript && (
                  <EditFormSubtitle
                    voiceTranscript={currentAudio}
                    onComplete={onCompleteTranscript}
                  />
                )}
              </Item>
            </Grid>
            <Grid item xs={12}>
              <Item>
                <UploadSingleFile
                  value={editAudio}
                  label={addLabel}
                  onChange={(val) => seteditAudio(val)}
                  caption="(Les formats *.mp3 sont acceptés)"
                  sx={{ maxHeight: "150px" }}
                  acceptFile={{
                    accept: {
                      "audio/mp3": [".mp3"],
                    },
                  }}
                  mediaType="audio"
                />
              </Item>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box sx={{ width: "100%", my: 2 }}>
            {submitting ? (
              <CircularProgress size={20} />
            ) : (
              <Button
                sx={{ my: 2 }}
                disabled={isDisabledValidate}
                onClick={handleValidate}
              >
                Valider
              </Button>
            )}
          </Box>

          <Button color="error" onClick={handleClose}>
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
