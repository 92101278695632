import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import GppGoodIcon from "@mui/icons-material/GppGood";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Switch from "@mui/material/Switch";
import AnswerEditBtn from "./AnswerEditBtn";
import AnswerDelBtn from "./AnswerDelBtn";
// ----------------------------------------------------------------------
export default function AnswerPanel({ quiz, question, items, handleComplete }) {
  const [checked, setChecked] = React.useState(["wifi"]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  // ----------------------------------------------------------------------

  return (
    <List
      sx={{ width: "100%", bgcolor: "background.paper" }}
      subheader={<ListSubheader>{items.length} réponses </ListSubheader>}
    >
      {items.map((it, i) => {
        const styleCorrectedRes =
          it && it.isCorrect
            ? {
                backgroundColor: "#2e7d32",
                borderRadius: "4px",
                padding: "5px",
                color: "white",
                fontWeight: "500!important",
              }
            : {};
        return (
          <ListItem key={i}>
            <ListItemIcon>
              <AnswerEditBtn
                quiz={quiz}
                question={question}
                editAnswer={it}
                addLabel="Nouvelle réponse"
                handleComplete={handleComplete}
              />
            </ListItemIcon>
            <ListItemText
              id="switch-list-label-wifi"
              primary={it.title}
              style={styleCorrectedRes}
            />
            {it.isCorrect && (
              <ListItemIcon disabled>
                <GppGoodIcon />
              </ListItemIcon>
            )}

            <AnswerDelBtn
              editAnswer={it}
              handleCompleteDelAnswer={handleComplete}
            />
          </ListItem>
        );
      })}
    </List>
  );
}
