import React, { useEffect, useState } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import {
  Alert as MuiAlert,
  Box,
  MenuItem,
  TextField as MuiTextField,
  ListItemText,
  Button,
  Stack,
  Paper,
  Divider as MuiDivider,
  Container,
  Typography,
  Grid,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Helmet } from "react-helmet-async";
import { spacing } from "@mui/system";
import useNotification from "hooks/useNotification";
import useReferentiel from "hooks/useReferentiel";
import UploadSingleFile from "components/file/UploadSingleFile";
import ImgBoxFromUrl from "components/media/ImgBoxFromUrl";
import AudioBoxFromUrl from "components/media/AudioBoxFromUrl";
import VideoBoxFromUrl from "components/media/VideoBoxFromUrl";
import { API_URL } from "config";
import BackBtn from "components/custom/BackBtn";
import AlertMsg from "components/custom/AlertMsg";
import { getMediaUrl } from "utils/objectUtil";
import useDataUser from "hooks/useDataUser";
import UserProfile from "./UserProfile";
// ----------------------------------------------------------------------
const Divider = styled(MuiDivider)(spacing);
export default function UserEditPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { userApp, isLoaded, initUserAppById } = useDataUser();
  useEffect(() => {
    if (!id) return;
    initUserAppById(id);
  }, [id]);
  if (!userApp && isLoaded) return <EmptyData />;
  return (
    <>
      <Helmet title="Détail" />
      <BackBtn
        backUrl="/app/users"
        needConfirm={true}
        titleConfim="Demande de confirmation"
        msgConfirm="Des données saisies pourraient ne pas être  Voulez-vous quitter cette page ?"
      />
      <Divider my={6} />
      {userApp && <UserProfile userData={userApp} />}
    </>
  );
}

const EmptyData = () => {
  const [display, setdisplay] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setdisplay(true);
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return (
    <Container>
      {display && (
        <>
          <Typography variant="h2" component="h3" gutterBottom>
            L'utilisateur demandé n'est pas disponible.
          </Typography>
          <Box mb={8} />
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Button
                component={NavLink}
                to="/app/users"
                variant="contained"
                color="primary"
              >
                <ArrowBackIosIcon />
                Retourner à la liste des utilisateurs
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  );
};
