import React from "react";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Grid,
  Link,
  Typography as MuiTypography,
  Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";
import useReferentiel from "hooks/useReferentiel";
import RefsList from "./RefsList";
import RefsEditBtn from "./RefsEditBtn";
// ----------------------------------------------------------------------
const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
// ----------------------------------------------------------------------

export default function RefsSupportZonePage() {
  const { refs } = useReferentiel();

  if (!refs) return <></>;
  const { supportZone } = refs;
  return (
    <>
      <Helmet title="Gestion des contacts" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Zone géographique
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/app">
              Accueil
            </Link>
            <Typography>Zone géographique</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <RefsEditBtn refName="supportzone" addLabel="Nouvelle zone" />
        </Grid>
      </Grid>

      <Divider my={6} />
      <Grid container spacing={6}>
        <RefsList items={supportZone} refName="supportzone" />
      </Grid>
    </>
  );
}
