import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Popover,
  Card,
  CardActions,
  CardContent,
  Button,
  TextField,
  ListSubheader,
  Paper,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { lime } from "@mui/material/colors";
// ----------------------------------------------------------------------
const MuiPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(4),
}));
// ----------------------------------------------------------------------

export default function LastNodeEdit({ editOptions, onComplete }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  // ----------------------------------------------------------------------
  const theme = useTheme();
  // ----------------------------------------------------------------------
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // ----------------------------------------------------------------------
  const handleComplete = (val) => {
    onComplete(val);
    handleClose();
  };
  // ----------------------------------------------------------------------
  return (
    <Box
      sx={{
        py: 2,
        width: "100%",
        maxWidth: 460,
      }}
    >
      <div>
        <Button
          fullWidth
          aria-label="details"
          size="small"
          onClick={handleClick}
          color="error"
          variant="outlined"
        >
          <EditIcon style={{ fontSize: 18 }} />
          Gérer les options
        </Button>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={() => console.log()}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Card
            sx={{
              minWidth: 475,
              backgroundColor: theme.palette.warning.main,
            }}
          >
            <CardContent>
              {open && (
                <FormEdit
                  editOptions={editOptions}
                  onComplete={handleComplete}
                />
              )}
            </CardContent>
            <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                size="small"
                color="error"
                variant="outlined"
                onClick={handleClose}
                sx={{ color: "white" }}
              >
                Annuler
              </Button>
            </CardActions>
          </Card>
        </Popover>
      </div>
      <MuiPaper>
        <List
          sx={{ backgroundColor: "#fff" }}
          subheader={
            <ListSubheader
              sx={{ color: "red" }}
              component="div"
              id="nested-list-subheader"
            >
              {editOptions && editOptions.length > 0
                ? "Les options disponibles"
                : "Aucune option disponible"}
            </ListSubheader>
          }
        >
          {editOptions && editOptions.length > 0 && (
            <>
              {editOptions.map((it, i) => {
                return (
                  <React.Fragment key={i}>
                    <ListItem>
                      <ListItemText primary={it.title} />
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                );
              })}
            </>
          )}
        </List>
      </MuiPaper>
    </Box>
  );
}
// ----------------------------------------------------------------------
const FormEdit = ({ editOptions, onComplete }) => {
  // ----------------------------------------------------------------------
  const [options, setoptions] = useState([]);
  // ----------------------------------------------------------------------
  useEffect(() => {
    if (!editOptions) return;
    setoptions(editOptions);
  }, [editOptions]);
  // ----------------------------------------------------------------------
  const handleValidate = () => {
    onComplete(options.filter((it) => it.title && it.title.length > 0));
  };
  const handleAddOption = () => {
    const newOptions = [...options];
    newOptions.push({ title: "" });
    setoptions(newOptions);
  };
  // ----------------------------------------------------------------------

  const handleChange = (e, idx) => {
    const newOptions = [...options];
    const opt = newOptions[idx];
    opt.title = e.target.value;
    newOptions[idx] = { ...opt };
    setoptions(newOptions);
  };
  // ----------------------------------------------------------------------

  const handleDelete = (idx) => {
    const newOptions = [...options].filter((it, i) => i !== idx);
    setoptions(newOptions);
  };
  // ----------------------------------------------------------------------

  return (
    <Box
      sx={{
        bgcolor: "#fff",
        p: 4,
        minHeight: "250px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <Button variant="contained" color="warning" onClick={handleAddOption}>
          Ajouter une option
        </Button>
      </Box>
      {options && options.length > 0 && (
        <List>
          {options.map((it, i) => {
            return (
              <React.Fragment key={i}>
                <ListItem
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDelete(i)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <TextField
                    sx={{ mx: 2, flexGrow: 1 }}
                    id={`option_${i}`}
                    multiline
                    maxRows={4}
                    onChange={(e) => handleChange(e, i)}
                    value={it.title}
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            );
          })}
        </List>
      )}

      {options && options.length > 0 && (
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleValidate}
        >
          Valider
        </Button>
      )}
    </Box>
  );
};
