export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const auth0Config = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const cognitoConfig = {
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
};
const HOST = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "http://localhost:3001/v1";
export const API_URL = {
  login: `${HOST}/auth/login`,
  logout: `${HOST}/auth/logout`,
  authenticateByToken: `${HOST}/auth/me`,
  svgOptim: `${HOST}/svg/optim`,
  patient: `${HOST}/patient`,
  refs: `${HOST}/ref`,
  appconfig: `${HOST}/appconfig`,
  users: `${HOST}/users`,
  userapp: `${HOST}/userapp`,
  appcodes: `${HOST}/appcode`,
  reftheme: `${HOST}/reftheme`,
  refsubject: `${HOST}/refsubject`,
  refavatar: `${HOST}/refavatar`,
  refdemand: `${HOST}/refdemand`,
  refinterest: `${HOST}/refinterest`,
  voiceTranscript: `${HOST}/voiceTranscript`,
  refstereotype: `${HOST}/refstereotype`,
  refcatinterest: `${HOST}/refcatinterest`,
  getstream: `${HOST}/getstream/by-bo`,
  stereotypechallenge: `${HOST}/stereochallenge`,
  quiz: `${HOST}/quiz`,
  tchat: `${HOST}/tchat`,
  question: `${HOST}/question`,
  answer: `${HOST}/answer`,
  challenge: `${HOST}/challenge`,
  refchallengetype: `${HOST}/refchallengetype`,
  refactionpoint: `${HOST}/refactionpoint`,
  refreportcontent: `${HOST}/refreportcontent`,
  refgiftcategory: `${HOST}/refgiftcategory`,
  programmotor: `${HOST}/programmotor`,
  contact: `${HOST}/contact`,
  thematic: `${HOST}/thematic`,
  subtheme: `${HOST}/subtheme`,
  profilerule: `${HOST}/profilerule`,
  supportzone: `${HOST}/supportzone`,
  supportcom: `${HOST}/supportcom`,
  supportlang: `${HOST}/supportlang`,

  article: `${HOST}/article`,
  refarticleformat: `${HOST}/refarticleformat`,
  refarticlelevel: `${HOST}/refarticlelevel`,

  gift: `${HOST}/gift`,
  giftStock: `${HOST}/giftStock`,
  program: `${HOST}/program`,
  userProgram: `${HOST}/userprogram`,
  dayflow: `${HOST}/dayflow`,
  daychoice: `${HOST}/daychoice`,
  publishedprog: `${HOST}/publishedprog`,
  booster: `${HOST}/booster`,
  appvideo: `${HOST}/appvideo`,
  appmusic: `${HOST}/appmusic`,
  appimage: `${HOST}/appimage`,
  appcode: `${HOST}/appcode`,
  transcript: `${HOST}/transcript`,
  refCoachVoice: `${HOST}/refCoachVoice`,
  manageUserApp: `${HOST}/manageUserApp`,
};
export const maxdaysprog = "maxdaysprog";
export const maxsubjectsbyday = "maxsubjectsbyday";
export const maxchallengesbysubject = "maxchallengesbysubject";
export const refBoosterExe = "63a584080dd85e0820de6ca4";
export const QUIZ_TYPE_STEREO = "65d1f51daf578b42fa618846";
export const QUIZ_TYPE_CHAL = "65d1f548af578b42fa618847";
export const QUIZ_TYPE_DEMAND = "664d94526e4fe2b0a48dd3b4";
/** challenge types */
export const CHALLENGE_TYPE_AUDIO = "63945a9991e8970b002dbf03";
export const CHALLENGE_TYPE_NOTE = "63945aa491e8970b002dbf0b";
export const CHALLENGE_TYPE_VIDEO = "63945aac91e8970b002dbf13";
export const CHALLENGE_TYPE_EXERCICE = "6395eca2d8b1785dc4abfac3";
export const CHALLENGE_TYPE_FREE = "63d19fb0b6f8e672a869083f";
export const CHALLENGE_TYPE_CHAT = "65d0827bc5af495898f20da6";
export const CHALLENGE_TYPE_QUIZ = "65d08294c5af495898f20db3";

export const TCHAT_NODE_SINGLE = "65d4b96472fe905a77baf3b3";
export const TCHAT_NODE_MULTIPLE = "65d4b96d72fe905a77baf3b4";
export const TCHAT_NODE_NONE = "65e18c2c80035ac357c5933d";
export const TCHAT_NODE_TEXT = "65e18c7480035ac357c5933e";
export const TCHAT_NODE_CONTACT_SINGLECHOICE = "664da009d67df9260c90354a";

export const RULE_OPERATORS = {
  arrayType: [
    {
      id: "contains",
      label: "contient",
    },
    { id: "doesNotContain", label: "ne contient pas" },
    {
      id: "in",
      label: "est présent dans",
    },
    {
      id: "notIn",
      label: "n'est pas présent dans",
    },
  ],
  numberType: [
    {
      id: "equal",
      label: "égal",
    },
    { id: "notEqual", label: "inégal" },
    {
      id: "lessThan",
      label: "lessThan",
    },
    {
      id: "lessThanInclusive",
      label: "lessThanInclusive",
    },
    {
      id: "greaterThan",
      label: "greaterThan",
    },
    {
      id: "greaterThanInclusive",
      label: "greaterThanInclusive",
    },
  ],
  stringType: [
    {
      id: "equal",
      label: "égal",
    },
    { id: "notEqual", label: "inégal" },
    {
      id: "lessThan",
      label: "lessThan",
    },
  ],
};
export const GENDERS = [
  {
    id: "female",
    label: "Féminin",
  },
  {
    id: "male",
    label: "Masculin",
  },
  {
    id: "non-binary",
    label: "Non binaire",
  },
];
export const AGES = Array.from({ length: 8 }, (_, i) => {
  const a = i + 11;
  return {
    id: a,
    label: `${a} ans`,
  };
});
