import {
  BookOpen,
  Settings,
  Briefcase,
  Calendar,
  CheckSquare,
  CreditCard,
  Grid,
  Heart,
  Layout,
  List,
  Map,
  ShoppingCart,
  PieChart,
  Sliders,
  Users,
  ShoppingBag,
  Airplay,
  Gift,
} from "react-feather";

const pagesSection = [
  {
    href: "/app/users",
    icon: Users,
    title: "Utilisateurs",
    children: [
      {
        href: "/app/users",
        title: "Tous",
      },
    ],
  },
  {
    href: "/pages",
    icon: Layout,
    title: "Codes d'application",
    children: [
      {
        href: "/app/appcodes",
        title: "Codes",
      },
    ],
  },
];

const refsSection = [
  {
    icon: Settings,
    title: "Programmes",
    children: [
      {
        href: "/app/refs-theme",
        title: "Thèmes",
      },
      {
        href: "/app/refs-subject",
        title: "Sujets",
      },
      {
        href: "/app/refs-stereotype",
        title: "Stereotype",
      },
      {
        href: "/app/refs-refchallengetype",
        title: "Type de défi",
      },
      {
        href: "/app/refs-actionpoint",
        title: "Meuz'coins",
      },
      {
        href: "/app/refs-avatar",
        title: "Avatars",
      },
      {
        href: "/app/refs-interest",
        title: "Intérêts",
      },
    ],
  },
  {
    icon: Gift,
    title: "Gifts",
    children: [
      {
        href: "/app/refs-giftcategory",
        title: "Type de coupon cadeau",
      },
    ],
  },
  {
    icon: Heart,
    title: "Contact",
    children: [
      {
        href: "/app/refs-contact",
        title: "Contact de confiance",
      },

      {
        href: "/app/refs-supportzone",
        title: "Zone géographique",
      },
      {
        href: "/app/refs-supprotcom",
        title: "Moyen de communication",
      },
      {
        href: "/app/refs-supportlang",
        title: "Language supporté",
      },
    ],
  },
  {
    icon: Briefcase,
    title: "Article",
    children: [
      {
        href: "/app/refs-thematic",
        title: "Thématique",
      },
      {
        href: "/app/refs-subtheme",
        title: "Sous-thème",
      },
      {
        href: "/app/refs-article",
        title: "Article de presse",
      },
      {
        href: "/app/refs-articleformat",
        title: "Format d'article",
      },
    ],
  },
  {
    icon: Sliders,
    title: "Samy App",
    children: [
      {
        href: "/app/refs-demand",
        title: "Demandes",
      },
    ],
  },
];
const progChidren = [
  {
    href: "/app/prog-stereotypechallenge",
    title: "Stereotype challenge",
  },
  {
    href: "/app/prog-challenge",
    title: "Challenges",
  },
  {
    href: "/app/program",
    title: "Programmes",
  },
];
const ruleProg = {
  href: "/app/program-rule",
  title: "Base de règles",
};
const ruleInterest = {
  href: "/app/profile-rule",
  title: "Règles de profiling",
};
const progSection = [
  {
    icon: BookOpen,
    title: "App. programmes",
    children:
      process.env.REACT_APP_NODE_ENV === "local"
        ? [...progChidren, { ...ruleProg }, { ...ruleInterest }]
        : [...progChidren],
  },
  {
    href: "/pages",
    icon: Airplay,
    title: "Prog. publiés",
    children: [
      {
        href: "/app/publish-enable",
        title: "Activés",
      },
    ],
  },
];
const giftSection = [
  {
    icon: ShoppingCart,
    title: "Cadeaux",
    children: [
      {
        href: "/app/gift",
        title: "Tous les cadeaux",
      },
    ],
  },
];
const navItems = [
  {
    title: "Utilisateurs et codes",
    pages: pagesSection,
  },
  {
    title: "Référentiels",
    pages: refsSection,
  },
  {
    title: "Gestion des programmes",
    pages: progSection,
  },

  {
    title: "Boutique",
    pages: giftSection,
  },
  /* {
    title: "Emeuse Pro",
    pages: docsSection,
  }, */
];

export default navItems;
