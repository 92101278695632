import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Link,
  Typography as MuiTypography,
} from "@mui/material";

import { spacing } from "@mui/system";
import useProgram from "hooks/useProgram";
import DetailProgram from "../program/DetailProgam";
// ----------------------------------------------------------------------
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const Typography = styled(MuiTypography)(spacing);

export default function ProgramPage() {
  const { programs, getPublishPrograms } = useProgram();
  useEffect(() => {
    getPublishPrograms();
  }, []);
  return (
    <>
      <Helmet title="Catalogue des programmes" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Catalogue des programmes
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/app">
              Accueil
            </Link>
            <Typography>Tous les programmes publiés</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Grid container spacing={6}>
        <>
          {Array.isArray(programs) && (
            <>
              {programs.map((it, i) => {
                return (
                  <Grid item xs={12} lg={6} xl={3} key={i}>
                    <DetailProgram data={it} />
                  </Grid>
                );
              })}
            </>
          )}
        </>
      </Grid>
    </>
  );
}
