import React, { useEffect } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import {
  TextField as MuiTextField,
  Button,
  Paper,
  Divider as MuiDivider,
  MenuItem,
} from "@mui/material";
import { spacing } from "@mui/system";
import useProgram from "hooks/useProgram";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------
const TextField = styled(MuiTextField)(spacing);
const Divider = styled(MuiDivider)(spacing);
// ----------------------------------------------------------------------
export default function ProgramCreateForm({
  editProgram,
  onComplete,
  disabledEdit,
}) {
  const { initConfigProgram, stereotypechallenges, getPrograms, saveProgram } =
    useProgram();
  const navigate = useNavigate();
  // ----------------------------------------------------------------------
  useEffect(() => {
    initConfigProgram();
  }, []);
  // ----------------------------------------------------------------------

  const dataSchema = Yup.object().shape({
    code: Yup.string().max(20).required("Veuillez renseigner le code"),
    title: Yup.string().max(100).required(`Veuillez renseigner le titre`),
    description: Yup.string().max(250),
    numberOfStep: Yup.number()
      .max(30)
      .required(`Veuillez renseigner le nombre de partie joué`),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: editProgram && editProgram.code ? editProgram.code : "",
      title: editProgram && editProgram.title ? editProgram.title : "",
      description:
        editProgram && editProgram.description ? editProgram.description : "",
      numberOfStep:
        editProgram && editProgram.numberOfStep ? editProgram.numberOfStep : 1,
    },
    validationSchema: dataSchema,
    onSubmit: async () => {
      try {
        const newData =
          editProgram && editProgram.id
            ? { ...editProgram, ...values }
            : { ...values };
        /* in case file upload is choiced we need to make formdata */
        const dataCreated = await saveProgram({
          ...newData,
        });
        await getPrograms();

        if (onComplete) {
          onComplete();
        }
        if (dataCreated) {
          navigate(`/app/edit-program/${dataCreated.id}`);
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
    handleChange,
    setFieldValue,
  } = formik;
  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <TextField
            disabled={disabledEdit}
            fullWidth
            type="text"
            label="Code"
            {...getFieldProps("code")}
            error={Boolean(touched.code && errors.code)}
            helperText={touched.code && errors.code}
            my={2}
          />
          <TextField
            disabled={disabledEdit}
            fullWidth
            type="text"
            name="title"
            label="Titre"
            {...getFieldProps("title")}
            error={Boolean(touched.title && errors.title)}
            helperText={touched.title && errors.title}
            my={2}
          />
          <TextField
            disabled={disabledEdit}
            fullWidth
            type="text"
            name="description"
            label="Description"
            {...getFieldProps("description")}
            error={Boolean(touched.description && errors.description)}
            helperText={touched.description && errors.description}
            my={2}
          />
          <TextField
            disabled={disabledEdit}
            fullWidth
            type="number"
            name="numberOfStep"
            label="Nombre de partie"
            {...getFieldProps("numberOfStep")}
            error={Boolean(touched.numberOfStep && errors.numberOfStep)}
            helperText={touched.numberOfStep && errors.numberOfStep}
            my={2}
          />
          <Divider my={6} />
          <Button
            sx={{ my: 2 }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting || disabledEdit}
          >
            Valider
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
}
