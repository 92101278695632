import React from "react";
import {
  Button,
  MenuItem,
  TextField as MuiTextField,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Tooltip,
  IconButton,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import DoneIcon from "@mui/icons-material/Done";
import RateReviewIcon from "@mui/icons-material/RateReview";
import CircularProgress from "@mui/material/CircularProgress";
import { spacing } from "@mui/system";
import useReferentiel from "hooks/useReferentiel";
import useTchat from "hooks/useTchat";

// ----------------------------------------------------------------------
const TextField = styled(MuiTextField)(spacing);
// ----------------------------------------------------------------------
export default function ChatFlowEditBtn({
  editData,
  preDefinedType,
  field,
  addLabel,
  handleComplete,
}) {
  // ----------------------------------------------------------------------
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // ----------------------------------------------------------------------
  const onComplete = () => {
    handleComplete();
    handleClose();
  };
  return (
    <div>
      <Tooltip title={addLabel}>
        <IconButton
          variant="contained"
          aria-label="details"
          size="small"
          onClick={handleClickOpen}
        >
          <RateReviewIcon />
        </IconButton>
      </Tooltip>
      <Dialog maxWidth="md" open={open} fullWidth>
        <DialogTitle> {addLabel}</DialogTitle>
        <DialogContent>
          <FormEdit
            field={field}
            parentData={editData}
            editData={editData[field]}
            preDefinedType={preDefinedType}
            onComplete={onComplete}
          />
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

// ----------------------------------------------------------------------
const FormEdit = ({
  field,
  parentData,
  editData,
  preDefinedType,
  onComplete,
}) => {
  const { refs } = useReferentiel();
  const { save } = useTchat();
  const dataSchema = Yup.object().shape({
    title: Yup.string().max(100).required(`Veuillez renseigner le titre`),
    refQuizType: Yup.string()
      .max(100)
      .required(`Veuillez renseigner le type du tchat`),
  });
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      title: editData && editData.title ? editData.title : "",
      refQuizType: preDefinedType
        ? preDefinedType
        : editData && editData.refQuizType
        ? editData.refQuizType.id
        : "",
    },
    validationSchema: dataSchema,
    onSubmit: async () => {
      try {
        const newData =
          editData && editData.id
            ? { id: editData.id, ...values }
            : { ...values, idParent: parentData.id, fieldParent: field }; //sauve only   title,
        await save(newData);
        onComplete();
      } catch (error) {
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
    handleChange,
  } = formik;
  if (!refs) return;
  const { refQuizType } = refs;
  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <TextField
            fullWidth
            type="text"
            name="title"
            label="Titre"
            {...getFieldProps("title")}
            error={Boolean(touched.title && errors.title)}
            helperText={touched.title && errors.title}
            my={2}
          />
          <TextField
            disabled={preDefinedType ? true : false}
            fullWidth
            select
            label="Type du tchat"
            value={values.refQuizType}
            onChange={handleChange("refQuizType")}
            {...getFieldProps("refQuizType")}
            error={Boolean(touched.refQuizType && errors.refQuizType)}
            helperText={touched.refQuizType && errors.refQuizType}
            my={2}
          >
            {refQuizType.map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <Button
            sx={{ my: 2 }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            endIcon={
              isSubmitting ? (
                <CircularProgress size={20} />
              ) : (
                <DoneIcon size={20} />
              )
            }
          >
            Valider
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
};
