import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { Box, Divider as MuiDivider, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { spacing } from "@mui/system";

import useGift from "hooks/useGift";
import BackBtn from "components/custom/BackBtn";
import GalleryImg from "./GalleryImg";
import GiftStockBtn from "./GiftStockBtn";
import GiftForm from "./GiftForm";
import RefsEditMediaBtn from "../refs/RefsEditMediaBtn";
import GalleryImgUpload from "./GalleryImgUpload";

// ----------------------------------------------------------------------
const Divider = styled(MuiDivider)(spacing);
// ----------------------------------------------------------------------

export default function GiftEditPage() {
  const { id } = useParams();
  const { getStockByGift, getGiftById, saveImageGift, saveGalleryGift } =
    useGift();
  const [editData, seteditdata] = useState(null);
  const [updatedAt, setupdatedAt] = useState(null);
  const [stock, setstock] = useState(null);
  // ----------------------------------------------------------------------

  useEffect(() => {
    if (!id) return;
    init();
  }, [id]);
  useEffect(() => {
    if (!id) return;
    if (!updatedAt) return;
    init();
  }, [id, updatedAt]);
  // ----------------------------------------------------------------------
  const init = async () => {
    try {
      const res = await getGiftById(id);
      if (res) {
        seteditdata(res);
      }
    } catch (error) {
      console.error();
    }
  };
  // ----------------------------------------------------------------------

  useEffect(() => {
    if (!editData || !editData.id) return;

    initGiftStock();
  }, [editData]);
  // ----------------------------------------------------------------------

  const initGiftStock = async () => {
    try {
      if (!editData) return;
      if (!editData.id) return;
      const res = await getStockByGift(editData.id);
      if (res) {
        setstock(res);
      } else {
        setstock(null);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // ----------------------------------------------------------------------
  const handleCompleteImage = async (editData, field, editObj) => {
    try {
      await saveImageGift(editData, field, editObj);
      setupdatedAt(new Date());
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  // ----------------------------------------------------------------------
  const handleCompleteGallery = async (editData, field, editObj) => {
    try {
      await saveGalleryGift(editData, field, editObj);
      setupdatedAt(new Date());
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  // ----------------------------------------------------------------------

  if (!editData || !editData.id) return <></>;
  return (
    <>
      <Helmet title="Edition de cadeau" />
      <Box
        sx={{ with: "100%" }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <BackBtn
          backUrl="/app/gift"
          needConfirm={true}
          titleConfim="Demande de confirmation"
          msgConfirm="Des données saisies pourraient ne pas être  Voulez-vous quitter cette page ?"
        />
        {editData && editData.id && (
          <GiftStockBtn
            editData={editData}
            stock={stock}
            onComplete={initGiftStock}
          />
        )}
      </Box>
      <Divider my={6} />
      <GiftForm
        editData={editData}
        onComplete={() => setupdatedAt(new Date())}
      />
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography variant="h6" sx={{ mr: 3 }}>
          {editData.image
            ? "Image enregistrée, voulez-vous la modifier ? Cliquez sur ce bouton"
            : "Ajouter une image en cliquant sur ce bouton"}
        </Typography>
        <Divider my={6} />
        <RefsEditMediaBtn
          field="image"
          editData={editData}
          addLabel="Image"
          handleComplete={handleCompleteImage}
          disabledEdit={false}
        />
      </Box>
      <Divider my={6} />

      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography variant="h6" sx={{ mr: 3 }}>
          {editData.galleryImg && editData.galleryImg.length > 0
            ? "Galerie enregistrée, voulez-vous ajouter les photos ? Cliquez sur ce bouton"
            : "Ajouter une galerie de photo en cliquant sur ce bouton"}
        </Typography>
        <GalleryImgUpload
          field="galleryImg"
          editData={editData}
          onComplete={handleCompleteGallery}
        />
      </Box>
      <Divider my={6} />
      <Box display="flex" justifyContent="center" alignItems="center">
        <GalleryImg
          editData={editData}
          onComplete={() => setupdatedAt(new Date())}
          addLabel="Ajouter les photos dans la galerie"
        />
      </Box>
    </>
  );
}
