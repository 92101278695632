import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  IconButton,
  Card,
  Popover,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// ----------------------------------------------------------------------
export default function BackBtn({
  backUrl,
  needConfirm,
  titleConfim,
  msgConfirm,
}) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirm = async () => {
    try {
      if (!needConfirm) return;
      if (!backUrl) return;
      navigate(backUrl);
      setAnchorEl(null);
    } catch (error) {
      console.error(error);
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClick}>
        <ArrowBackIosIcon />
        Retourner
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card  sx={{
            minWidth: 275,
            
          }}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {titleConfim}
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {msgConfirm}
            </Typography>
          </CardContent>
          <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={handleConfirm}>Valider</Button>
            <Button onClick={handleClose}>Annuler</Button>
          </CardActions>
        </Card>
      </Popover>
    </div>
  );
}
