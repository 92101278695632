import React, { useState } from "react";

import {
  Button,
  IconButton,
  Card,
  Popover,
  CardActions,
  CardContent,
  TextField as MuiTextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { spacing } from "@mui/system";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import useNotification from "hooks/useNotification";
import useTchat from "hooks/useTchat";
// ----------------------------------------------------------------------
const TextField = styled(MuiTextField)(spacing);
// ----------------------------------------------------------------------

export default function ChatNodeClone({
  editData,
  onSelectCloneNode,
  disabled,
}) {
  return (
    <div>
      <IconButton
        variant="contained"
        color={disabled ? "primary" : "error"}
        aria-label="details"
        onClick={() => onSelectCloneNode(editData.id)}
        size="small"
      >
        <FileCopyIcon style={{ fontSize: 15 }} />
      </IconButton>
    </div>
  );
}
