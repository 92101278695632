import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import {
  Checkbox,
  Button,
  MenuItem,
  TextField as MuiTextField,
  ListItemText,
} from "@mui/material";
import { spacing } from "@mui/system";
import useNotification from "hooks/useNotification";
import useReferentiel from "hooks/useReferentiel";
// ----------------------------------------------------------------------
const TextField = styled(MuiTextField)(spacing);
export default function RefDemandForm({ editData, onComplete, refName }) {
  const { refs, saveRef, initRefs } = useReferentiel();
  const { notif } = useNotification();
  const dataSchema = Yup.object().shape({
    code: Yup.string().max(20).required("Veuillez renseigner le code"),
    title: Yup.string().max(50).required(`Veuillez renseigner le titre`),
    description: Yup.string().max(250).required(`Veuillez renseigner le titre`),
    question: Yup.string().max(250).required(`Veuillez renseigner le titre`),
    anwser: Yup.string().max(1000).required(`Veuillez renseigner le titre`),
    refTheme: Yup.string().max(50).required(`Veuillez renseigner le thème`),
    refLevel: Yup.string()
      .max(50)
      .required(`Veuillez renseigner le niveau d'intervention`),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: editData && editData.code ? editData.code : "",
      title: editData && editData.title ? editData.title : "",
      description: editData && editData.description ? editData.description : "",
      question: editData && editData.question ? editData.question : "",
      anwser: editData && editData.anwser ? editData.anwser : "",
      thematic: editData && editData.thematic ? editData.thematic : "",
      refTheme: editData && editData.refTheme ? editData.refTheme.id : "",
      refLevel: editData && editData.refLevel ? editData.refLevel.id : "",
      posX: editData && editData.posX ? editData.posX : "",
      posY: editData && editData.posY ? editData.posY : "",
    },
    validationSchema: dataSchema,
    onSubmit: async () => {
      try {
        const newData =
          editData && editData.id ? { ...editData, ...values } : { ...values };
        delete newData.tChat;
        await saveRef(
          {
            ...newData,
          },
          refName
        );
        await initRefs();
        notif("Sauvegarde réussie");
        onComplete();
      } catch (error) {
        console.error(error);
        notif(null, error);
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleChange,
    handleSubmit,
    getFieldProps,
    setSubmitting,
  } = formik;
  if (!refName) return <></>;
  const { refTheme, refLevelDemand } = refs;
  if (!refTheme) return <></>;
  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <TextField
            fullWidth
            type="text"
            label="Code"
            {...getFieldProps("code")}
            error={Boolean(touched.code && errors.code)}
            helperText={touched.code && errors.code}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            name="title"
            label="Titre"
            {...getFieldProps("title")}
            error={Boolean(touched.title && errors.title)}
            helperText={touched.title && errors.title}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            label="Description"
            {...getFieldProps("description")}
            error={Boolean(touched.description && errors.description)}
            helperText={touched.description && errors.description}
            my={2}
          />

          <TextField
            fullWidth
            select
            label="Thème"
            value={values.refTheme}
            onChange={handleChange("refTheme")}
            {...getFieldProps("refTheme")}
            error={Boolean(touched.refTheme && errors.refTheme)}
            helperText={touched.refTheme && errors.refTheme}
            my={2}
          >
            {refTheme.map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            select
            label="Niveau d'intervention"
            value={values.refLevel}
            onChange={handleChange("refLevel")}
            {...getFieldProps("refLevel")}
            error={Boolean(touched.refLevel && errors.refLevel)}
            helperText={touched.refLevel && errors.refLevel}
            my={2}
          >
            {refLevelDemand.map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            type="text"
            label="Position X"
            {...getFieldProps("posX")}
            error={Boolean(touched.posX && errors.posX)}
            helperText={touched.posX && errors.posX}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            label="Position Y"
            {...getFieldProps("posY")}
            error={Boolean(touched.posY && errors.posY)}
            helperText={touched.posY && errors.posY}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            label="Question"
            {...getFieldProps("question")}
            error={Boolean(touched.question && errors.question)}
            helperText={touched.question && errors.question}
            my={2}
          />

          <TextField
            fullWidth
            type="text"
            multiline
            rows={3}
            name="anwser"
            label="Réponse"
            {...getFieldProps("anwser")}
            error={Boolean(touched.anwser && errors.anwser)}
            helperText={touched.anwser && errors.anwser}
            my={2}
          />

          <Button
            sx={{ my: 2 }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Valider
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
}
