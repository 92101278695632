import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Bar } from "react-chartjs-2";

import { MoreVertical } from "react-feather";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Link,
  CardHeader,
  Typography as MuiTypography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { spacing } from "@mui/system";
import useGift from "hooks/useGift";
import CardMediaFromUrl from "components/media/CardMediaFromUrl";
import GiftDelBtn from "./GiftDelBtn";
import GiftAddBtn from "./GiftAddBtn";
// ----------------------------------------------------------------------
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const Typography = styled(MuiTypography)(spacing);

const REF_MODEL = "gift";
export default function GiftPage() {
  const { gifts, getGifts } = useGift();
  useEffect(() => {
    getGifts();
  }, []);
  return (
    <>
      <Helmet title="Catalogue des coupons cadeau" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Catalogue des cadeaux
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/app">
              Accueil
            </Link>
            <Typography>Tous les cadeaux</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <GiftAddBtn />
        </Grid>
      </Grid>

      <Divider my={6} />
      <Grid container spacing={6}>
        <>
          {Array.isArray(gifts) && (
            <>
              {gifts.map((it, i) => {
                return (
                  <Grid item xs={12} lg={6} xl={3} key={i}>
                    <Detail data={it} />
                  </Grid>
                );
              })}
            </>
          )}
        </>
      </Grid>
    </>
  );
}
/* item card */
const Detail = ({ data }) => {
  if (!data) return <></>;
  return (
    <Card>
      <CardHeader action={<PositionedMenu data={data} />} title={data.title} />
      {data.image ? (
        <CardMediaFromUrl image={data.image} title={data.title} />
      ) : null}
      <CardContent>
        <Typography mb={4} color="textSecondary" component="p">
          {`${data.description}`}
        </Typography>
      </CardContent>
    </Card>
  );
};

const PositionedMenu = ({ data }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertical />
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem component={NavLink} to={`/app/edit-gift/${data.id}`}>
          <Button
            aria-label="details"
            size="small"
            onClick={handleClick}
            color="primary"
          >
            <EditIcon /> Modifier
          </Button>
        </MenuItem>
        <MenuItem>
          <GiftDelBtn
            editData={data}
            refName={REF_MODEL}
            onComplete={handleClose}
          />
        </MenuItem>
      </Menu>
    </div>
  );
};
