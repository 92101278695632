import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import SaveIcon from "@mui/icons-material/Save";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItemIcon from "@mui/material/ListItemIcon";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import Checkbox from "@mui/material/Checkbox";
import { useEffect } from "react";
import { Box, ListItem } from "@mui/material";
import useProgram from "hooks/useProgram";

// ----------------------------------------------------------------------

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
// ----------------------------------------------------------------------

export default function ProgramSubjectsEditBtn({
  editProgram,
  theme,
  choicedSubject,
  chosenSubjects,
  onComplete,
}) {
  // ----------------------------------------------------------------------

  const [open, setOpen] = React.useState(false);
  // ----------------------------------------------------------------------

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleComplete = () => {
    onComplete();
    handleClose();
  };
  // ----------------------------------------------------------------------
  /* if (!theme) return <></>; */
  if (!editProgram) return <></>;
  if (!Array.isArray(choicedSubject)) return <></>;
  if (!Array.isArray(chosenSubjects)) return <></>;

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        Modifier les sujets
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {editProgram.title} - Gérer les sujets{" "}
              {/* du thème {theme.label} */}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <SelectAllTransferList
          editProgram={editProgram}
          choicedSubject={choicedSubject}
          chosenSubjects={chosenSubjects}
          onComplete={handleComplete}
          /* theme={theme} */
        />
      </Dialog>
    </React.Fragment>
  );
}

// ----------------------------------------------------------------------

function not(a, b) {
  return a.filter((value) => {
    const existInB = b.findIndex((it) => String(it.id) === String(value.id));
    return existInB === -1;
  });
}

function intersection(a, b) {
  return a.filter((value) => {
    const existInB = b.findIndex((it) => String(it.id) === String(value.id));
    return existInB !== -1;
  });
}

function union(a, b) {
  return [...a, ...not(b, a)];
}
// ----------------------------------------------------------------------

function SelectAllTransferList({
  editProgram,
  choicedSubject,
  chosenSubjects,
  onComplete,
  theme,
}) {
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  // ----------------------------------------------------------------------
  const { saveSubjects, initProgramById } = useProgram();
  // ----------------------------------------------------------------------
  useEffect(() => {
    if (!choicedSubject) return;
    setLeft(choicedSubject);
  }, [choicedSubject]);
  useEffect(() => {
    if (!chosenSubjects) return;
    setRight(chosenSubjects);
  }, [chosenSubjects]);
  // ----------------------------------------------------------------------

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };
  // ----------------------------------------------------------------------
  const handeSave = async () => {
    if (!editProgram) return;
    /* if (!theme) return; */

    const ids = right.map((it) => it.id);
    /* await saveSubjects(ids, theme.id); */
    await saveSubjects(ids);
    await initProgramById(editProgram.id);
    onComplete();
  };
  // ----------------------------------------------------------------------
  const isChecked = (item) => {
    return checked.length === 0
      ? false
      : checked.findIndex((it) => String(it.id) === String(item.id)) !== -1;
  };
  // ----------------------------------------------------------------------
  const handleChangeOrder = (index, item, type) => {
    try {
      const newItems = [...right];
      if (index === 0 && type === "up") {
        return;
      }
      if (index === newItems.length - 1 && type === "down") {
        return;
      }
      const newIdx = type === "up" ? index - 1 : index + 1;
      const itemMoved = newItems[newIdx];
      newItems[newIdx] = item;
      newItems[index] = itemMoved;
      setRight(newItems);
    } catch (error) {
      console.error(error);
    }
  };
  // ----------------------------------------------------------------------

  const customList = (title, items, movable) => {
    if (!items) return <></>;

    return (
      <>
        <Card sx={{ minWidth: 350, minHeight: 600 }}>
          <CardHeader
            sx={{ px: 2, py: 1 }}
            avatar={
              <Checkbox
                onClick={handleToggleAll(items)}
                checked={
                  numberOfChecked(items) === items.length && items.length !== 0
                }
                indeterminate={
                  numberOfChecked(items) !== items.length &&
                  numberOfChecked(items) !== 0
                }
                disabled={items.length === 0}
                inputProps={{
                  "aria-label": "all items selected",
                }}
              />
            }
            title={title}
            subheader={`${numberOfChecked(items)}/${
              items.length
            } sélectionné(s)`}
          />
          <Divider />
          <List
            sx={{
              bgcolor: "background.paper",
              overflow: "auto",
            }}
            dense
            component="div"
            role="list"
          >
            {items.map((value, i) => {
              const labelValue = value.code + "-" + value.label;
              const labelId = `transfer-list-all-item-${labelValue}-label`;
              const isCheck = isChecked(value);
              return (
                <ListItem
                  key={i}
                  secondaryAction={
                    <>
                      {movable && (
                        <>
                          <IconButton
                            edge="end"
                            aria-label="monter"
                            color="primary"
                            sx={{ mx: 1 }}
                            onClick={() => handleChangeOrder(i, value, "up")}
                          >
                            <ArrowCircleUpIcon />
                          </IconButton>
                          <IconButton
                            edge="end"
                            aria-label="descendre"
                            color="warning"
                            onClick={() => handleChangeOrder(i, value, "down")}
                          >
                            <ArrowCircleDownIcon />
                          </IconButton>
                        </>
                      )}
                    </>
                  }
                  disablePadding
                >
                  <ListItemButton role="listitem" onClick={handleToggle(value)}>
                    <ListItemIcon>
                      <Checkbox
                        checked={isCheck}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={`${labelValue}`} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Card>
      </>
    );
  };
  // ----------------------------------------------------------------------
  if (!Array.isArray(choicedSubject)) return <></>;
  if (!Array.isArray(chosenSubjects)) return <></>;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        my: 4,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Button
        variant="contained"
        onClick={handeSave}
        sx={{ my: 3, maxWidth: 200 }}
      >
        <SaveIcon />
        Enregistrer
      </Button>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item sx={{ height: "100%" }}>
          {customList("Sujets disponibles", left)}
        </Grid>
        <Grid item sx={{ height: "100%" }}>
          <Grid container direction="column" alignItems="center">
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item sx={{ height: "100%" }}>
          {customList("Sujets du programme", right, true)}
        </Grid>
      </Grid>
    </Box>
  );
}
