import React from "react";

import {
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import ChallengeForm from "./ChallengeForm";

// ----------------------------------------------------------------------
export default function ChallengeAddBtn({ addLabel }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        <AddIcon />
        {addLabel ? addLabel : "Nouveau"}
      </Button>
      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogTitle> Ajouter un Défi</DialogTitle>
        <DialogContent>
          <ChallengeForm onComplete={handleClose} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
