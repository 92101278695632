import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import {
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grid,
  Paper,
  Box,
  CardMedia as MuiCardMedia,
  Tooltip,
} from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import ImgBoxFromUrl from "components/media/ImgBoxFromUrl";
import UploadSingleFile from "components/file/UploadSingleFile";

// ----------------------------------------------------------------------
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------
export default function RefsEditMediaBtn({
  editData,
  field,
  addLabel,
  handleComplete,
  disabledEdit,
}) {
  const [open, setOpen] = React.useState(false);
  const [editObj, seteditObj] = useState(null);

  // ----------------------------------------------------------------------
  useEffect(() => {
    if (open) return;
    seteditObj(null);
  }, [open]);
  // ----------------------------------------------------------------------
  useEffect(() => {
    return () => {
      seteditObj(null);
    };
  }, []);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getIcon = () => {
    try {
      if (!editData || !editData.id) return <></>;
      const color = editData[field] ? "primary" : "default";
      //image or background image
      if (field === "image") {
        return (
          <Tooltip title={addLabel}>
            <IconButton
              aria-label="details"
              size="small"
              onClick={handleClickOpen}
              color={color}
            >
              <AddPhotoAlternateIcon size="small" />
            </IconButton>
          </Tooltip>
        );
      } else {
        return (
          <Tooltip title={addLabel}>
            <IconButton
              variant="contained"
              size="small"
              onClick={handleClickOpen}
              color={color}
            >
              <WallpaperIcon />
            </IconButton>
          </Tooltip>
        );
      }
    } catch (error) {
      return <></>;
    }
  };
  // ----------------------------------------------------------------------

  const handleValidate = async () => {
    try {
      if (!editObj) return;
      await handleComplete(editData, field, editObj);
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };
  // ----------------------------------------------------------------------

  const isDisabledValidate = editObj ? false : true;
  return (
    <div>
      {getIcon()}
      <Dialog maxWidth="md" open={open} fullWidth>
        <DialogTitle> {addLabel}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Item>
                {editData[field] ? (
                  <ImgBoxFromUrl
                    image={`${editData[field]}`}
                    sx={{
                      borderRadius: 1,
                      objectFit: "cover",
                      width: "250px",
                      height: "auto",
                    }}
                  />
                ) : null}
              </Item>
            </Grid>
            {!disabledEdit && (
              <Grid item xs={12}>
                <Item>
                  <UploadSingleFile
                    value={editObj}
                    label={addLabel}
                    onChange={(val) => seteditObj(val)}
                    caption="(Les formats *.jpeg and *.png sont acceptés)"
                    sx={{ maxHeight: "150px" }}
                    acceptFile={{
                      accept: {
                        "image/jpeg": [".jpeg", ".png"],
                      },
                    }}
                    mediaType="image"
                  />
                </Item>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          {!disabledEdit && (
            <Box sx={{ width: "100%", my: 2 }}>
              <Button
                sx={{ my: 2 }}
                disabled={isDisabledValidate}
                onClick={handleValidate}
              >
                Valider
              </Button>
            </Box>
          )}

          <Button color="error" onClick={handleClose}>
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
