import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import {
  MenuItem,
  TextField as MuiTextField,
  ListItemText,
  Button,
  Paper,
  Checkbox,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CircularProgress from "@mui/material/CircularProgress";
import { spacing } from "@mui/system";
import useNotification from "hooks/useNotification";
import useReferentiel from "hooks/useReferentiel";
import { set } from "date-fns";

// ----------------------------------------------------------------------
const TextField = styled(MuiTextField)(spacing);
const Item = styled(Paper)(({ theme }) => ({
  //backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
// ----------------------------------------------------------------------

export default function ArticleForm({ editData, onComplete, refName }) {
  // ----------------------------------------------------------------------
  const { saveRef, initRefs } = useReferentiel();
  const { notif } = useNotification();
  const { refs } = useReferentiel();
  // ----------------------------------------------------------------------

  const dataSchema = Yup.object().shape({
    label: Yup.string().max(100).required("Veuillez renseigner le nom"),
    description: Yup.string()
      .max(500)
      .required(`Veuillez renseigner la description`),
    website: Yup.string().max(200),
    subTheme: Yup.string()
      .max(100)
      .required(`Veuillez renseigner le niveau`),
  });
  // ----------------------------------------------------------------------

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      label: editData && editData.label ? editData.label : "",
      description: editData && editData.description ? editData.description : "",
      website: editData && editData.website ? editData.website : "",
      subTheme: editData && editData.subTheme ? editData.subTheme : "",
      format: editData && editData.format ? editData.format : "",
    },
    validationSchema: dataSchema,
    onSubmit: async () => {
      try {
        const newData =
          editData && editData.id ? { ...editData, ...values } : { ...values };
        await saveRef(
          {
            ...newData,
          },
          refName
        );
        await initRefs();
        notif("Sauvegarde réussie");
        onComplete();
      } catch (error) {
        console.error(error);
        notif(null, error);
        setSubmitting(false);
      }
    },
  });
  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
    handleChange,
    setFieldValue,
  } = formik;

  // ----------------------------------------------------------------------

  if (!refs) return;
  const { subTheme, refArticleFormat } = refs;

  // ----------------------------------------------------------------------

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <TextField
            fullWidth
            type="text"
            label="Titre"
            {...getFieldProps("label")}
            error={Boolean(touched.label && errors.label)}
            helperText={touched.label && errors.label}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            name="description"
            label="Description"
            {...getFieldProps("description")}
            error={Boolean(touched.description && errors.description)}
            helperText={touched.description && errors.description}
            my={2}
          />
          <TextField
            fullWidth
            select
            label="Sous-thème"
            value={values.subTheme}
            onChange={handleChange("subTheme")}
            {...getFieldProps("subTheme")}
            error={Boolean(touched.subTheme && errors.subTheme)}
            helperText={touched.subTheme && errors.subTheme}
            my={2}
          >
            {subTheme.map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            type="text"
            name="website"
            label="Site web"
            {...getFieldProps("website")}
            error={Boolean(touched.website && errors.website)}
            helperText={touched.website && errors.website}
            my={2}
          />
          <TextField
            fullWidth
            select
            label="Format"
            value={values.format}
            onChange={handleChange("format")}
            {...getFieldProps("format")}
            error={Boolean(touched.format && errors.format)}
            helperText={touched.format && errors.format}
            my={2}
          >
            {refArticleFormat.map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          {/* END */}
          <Button
            sx={{ my: 2 }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            endIcon={
              isSubmitting ? (
                <CircularProgress size={20} />
              ) : (
                <DoneIcon size={20} />
              )
            }
          >
            Valider
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
}
