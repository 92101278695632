import { Chip } from "@mui/material";
import { RULE_OPERATORS } from "config";

// ----------------------------------------------------------------------

export const getLabelOperator = (item) => {
  try {
    if (!item) return "";
    let arr = [];
    Object.values(RULE_OPERATORS).forEach((it) => {
      arr = [...arr, ...it];
    });
    const opFounded = arr.find((r) => r.id === item.operator);
    return opFounded ? opFounded.label : "";
  } catch (error) {
    console.error(error);
  }
  return "";
};
// ----------------------------------------------------------------------

export const getLabelValues = (refData, item) => {
  try {
    if (!refData) return <></>;
    if (!item) return <></>;
    const { data } = item;
    if (!data || data.length === 0) return <></>;
    const dataString =
      typeof data === "string" ? data : data.map((it) => String(it));
    const objs = refData.filter((it) => {
      return dataString.includes(String(it.id));
    });

    return (
      <>
        {objs.map((it, i) => {
          return (
            <Chip key={i} label={it.label} variant="outlined" sx={{ ml: 3 }} />
          );
        })}
      </>
    );
  } catch (error) {
    console.error(error);
  }
  return "";
};
