import React from "react";
import styled from "@emotion/styled";
import { Grid, Card, CardHeader, Avatar } from "@mui/material";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { green, pink } from "@mui/material/colors";
import { fDateTimeSuffix } from "utils/formatTime";
import useChallenge from "hooks/useChallenge";
import RefsEditVideoBtn from "pages/refs/RefsEditVideoBtn";
import { CHALLENGE_TYPE_VIDEO } from "config";
// ----------------------------------------------------------------------
const addLabel = "Vidéo d'inspiration";
const model = "challenge";

// ----------------------------------------------------------------------
export default function ChalVideoInspireContentForm({
  editData,
  field,
  onComplete,
}) {
  const { saveVideo } = useChallenge();
  // ----------------------------------------------------------------------

  const handleComplete = async (editData, field, editObj) => {
    try {
      await saveVideo(editData, field, editObj, model);
      onComplete();
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  if (
    editData &&
    editData.refChallengeType.id === CHALLENGE_TYPE_VIDEO &&
    editData.youtubeUrl &&
    editData.youtubeUrl.length > 10
  )
    return <></>;
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardHeader
        avatar={
          editData && editData[field] ? (
            <Avatar sx={{ bgcolor: green[500] }}>
              <PersonalVideoIcon />
            </Avatar>
          ) : (
            <Avatar sx={{ bgcolor: pink[500] }}>
              <ErrorOutlineIcon />
            </Avatar>
          )
        }
        action={
          <RefsEditVideoBtn
            field={field}
            editData={editData}
            addLabel={addLabel}
            model={model}
            handleComplete={handleComplete}
          />
        }
        title={
          editData && editData[field] ? addLabel : addLabel + " non disponible"
        }
      />
    </Card>
  );
}
