import React, { useState } from "react";
import { useEffect } from "react";
import useProgram from "hooks/useProgram";
import useReferentiel from "hooks/useReferentiel";
import {
  Popover,
  Box,
  Typography,
  CardActions,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import ImgBoxFromUrl from "components/media/ImgBoxFromUrl";
import ProgramSubjectsEditBtn from "./ProgramSubjectsEditBtn";

// ----------------------------------------------------------------------

export default function TabSubjectEdit({ editProgram, theme }) {
  const [chosenSubjects, setchosenSubjects] = useState([]);
  const [choicedSubject, setchoicedSubject] = useState([]);
  const [updatedAt, setupdatedAt] = useState(null);
  // ----------------------------------------------------------------------
  const { refs } = useReferentiel();

  const { getSubjectsByProg } = useProgram();
  // ----------------------------------------------------------------------
  useEffect(() => {
    if (!editProgram) return;
    init();
  }, [editProgram, theme]);
  const init = async () => {
    if (!theme) return;
    const res = await getSubjectsByProg(theme.id);
    if (res) {
      setchosenSubjects(res.map((it) => it.refSubject)); //getonly refSubject
    } else {
      setchosenSubjects([]);
    }
  };
  useEffect(() => {
    if (!updatedAt) return;
    init();
  }, [updatedAt]);

  // ----------------------------------------------------------------------
  useEffect(() => {
    if (!theme) return;
    if (!chosenSubjects) return;
    if (!refs) return;
    if (!refs.refSubject) return;
    const res = refs.refSubject.filter((it) => {
      const sameTheme = it.refTheme.id === theme.id;
      const notInChosens =
        chosenSubjects.length === 0
          ? true
          : chosenSubjects.findIndex(
              (subj) => String(subj.id) === String(it.id)
            ) < 0;
      return sameTheme && notInChosens;
    });
    setchoicedSubject(res);
  }, [theme, refs, chosenSubjects]);
  // ----------------------------------------------------------------------
  if (!choicedSubject) return <></>;
  return (
    <>
      <Box
        sx={{
          my: 3,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        {!editProgram.isPublished && (
          <ProgramSubjectsEditBtn
            editProgram={editProgram}
            theme={theme}
            chosenSubjects={chosenSubjects}
            choicedSubject={choicedSubject}
            onComplete={() => {
              setupdatedAt(new Date());
            }}
          />
        )}
        <Typography variant="h6" sx={{ ml: 4 }}>
          {theme.label}
        </Typography>
      </Box>
      {chosenSubjects && chosenSubjects.length > 0 && (
        <Stack direction="row" spacing={1} sx={{ my: 6 }}>
          {chosenSubjects.map((it, i) => {
            return <DisplaySubject key={i} subject={it} />;
          })}
        </Stack>
      )}
    </>
  );
}
// ----------------------------------------------------------------------

function DisplaySubject({ subject }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // ----------------------------------------------------------------------

  if (!subject) return <></>;
  const { image, code, label } = subject;
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Chip label={`${code}-${label}`} onClick={handleClick} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card
          sx={{
            minWidth: 275,
          }}
        >
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Image
            </Typography>
            {image && open && (
              <ImgBoxFromUrl
                image={`${image}`}
                sx={{
                  borderRadius: 1,
                  objectFit: "cover",
                  width: "250px",
                  height: "auto",
                }}
              />
            )}
          </CardContent>
          <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={handleClose}>Annuler</Button>
          </CardActions>
        </Card>
      </Popover>
    </>
  );
}
