import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  MenuItem,
  TextField as MuiTextField,
  FormHelperText,
  ListSubheader,
  RadioGroup,
  Box,
  Typography,
  FormLabel,
  OutlinedInput,
  InputLabel,
  FormControl,
  ListItemText,
  Select,
} from "@mui/material";
import { spacing } from "@mui/system";
import useNotification from "hooks/useNotification";
import useReferentiel from "hooks/useReferentiel";
// ----------------------------------------------------------------------
const TextField = styled(MuiTextField)(spacing);
export default function RefthemeForm({ editData, onComplete }) {
  const [periodsSelected, setperiodsSelected] = useState([]);
  const { saveReftheme, initRefs } = useReferentiel();
  const { notif } = useNotification();

  const dataSchema = Yup.object().shape({
    code: Yup.string().max(20).required("Veuillez renseigner le code"),
    label: Yup.string().max(50).required(`Veuillez renseigner le libellé`),
    description: Yup.string().max(100),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: editData && editData.code ? editData.code : "",
      label: editData && editData.label ? editData.label : "",
      description: editData && editData.description ? editData.description : "",
      posX: editData && editData.posX ? editData.posX : "",
      posY: editData && editData.posY ? editData.posY : "",
    },
    validationSchema: dataSchema,
    onSubmit: async () => {
      try {
        const newData =
          editData && editData.id ? { ...editData, ...values } : { ...values };
        const arrPeriod = periodsSelected
          ? [...periodsSelected.sort((a, b) => a - b)]
          : [];
        await saveReftheme({
          ...newData,
          periods: [...arrPeriod],
        });
        await initRefs();
        notif("Sauvegarde réussie");
        onComplete();
      } catch (error) {
        console.error(error);
        notif(null, error);
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
  } = formik;
  useEffect(() => {
    if (!values) return;
    if (!values.periods) return;

    setperiodsSelected(values.periods);
  }, [values]);

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <TextField
            fullWidth
            type="text"
            label="Code"
            {...getFieldProps("code")}
            error={Boolean(touched.code && errors.code)}
            helperText={touched.code && errors.code}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            name="label"
            label="Libellé"
            {...getFieldProps("label")}
            error={Boolean(touched.label && errors.label)}
            helperText={touched.label && errors.label}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            name="description"
            label="Description"
            {...getFieldProps("description")}
            error={Boolean(touched.description && errors.description)}
            helperText={touched.description && errors.description}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            label="Position X"
            {...getFieldProps("posX")}
            error={Boolean(touched.posX && errors.posX)}
            helperText={touched.posX && errors.posX}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            label="Position Y"
            {...getFieldProps("posY")}
            error={Boolean(touched.posY && errors.posY)}
            helperText={touched.posY && errors.posY}
            my={2}
          />
          <Button
            sx={{ my: 2 }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Valider
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
