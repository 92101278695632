import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import {
  Alert as MuiAlert,
  Box,
  MenuItem,
  TextField as MuiTextField,
  ListItemText,
  Button,
  Stack,
  Paper,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Typography,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CircularProgress from "@mui/material/CircularProgress";
import { spacing } from "@mui/system";
import useReferentiel from "hooks/useReferentiel";
import useChallenge from "hooks/useChallenge";
import RadioBtnStyled from "components/custom/RadioBtnStyled";
import { isBooleanTrue } from "utils/objectUtil";
// ----------------------------------------------------------------------
const TextField = styled(MuiTextField)(spacing);
export default function StereoChallengeForm({ editData, onComplete }) {
  const { refs } = useReferentiel();
  const { getStereotypeChallenges, saveStereoChallenge } = useChallenge();
  const dataSchema = Yup.object().shape({
    code: Yup.string().max(20).required("Veuillez renseigner le code"),
    title: Yup.string().max(100).required(`Veuillez renseigner le titre`),
  });
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      code: editData && editData.code ? editData.code : "",
      title: editData && editData.title ? editData.title : "",
      enable: editData && isBooleanTrue(editData.enable) ? true : false,
    },
    validationSchema: dataSchema,
    onSubmit: async () => {
      try {
        const newData =
          editData && editData.id
            ? { id: editData.id, ...values }
            : { ...values }; //sauve only code, title, enable
        await saveStereoChallenge(newData);
        await getStereotypeChallenges();
        onComplete();
      } catch (error) {
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
    handleChange,
  } = formik;
  if (!refs) return;
  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <TextField
            fullWidth
            type="text"
            label="Code"
            {...getFieldProps("code")}
            error={Boolean(touched.code && errors.code)}
            helperText={touched.code && errors.code}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            name="title"
            label="Titre"
            {...getFieldProps("title")}
            error={Boolean(touched.title && errors.title)}
            helperText={touched.title && errors.title}
            my={2}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={2}
          >
            <FormLabel component="legend">Activé ?</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={values.enable}
              onChange={handleChange("enable")}
            >
              <FormControlLabel
                value={Boolean(true)}
                control={<RadioBtnStyled />}
                label={<Typography>Oui</Typography>}
              />
              <FormControlLabel
                value={Boolean(false)}
                control={<RadioBtnStyled />}
                label={<Typography>Non</Typography>}
              />
            </RadioGroup>
          </Box>
          <Button
            sx={{ my: 2 }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            endIcon={
              isSubmitting ? (
                <CircularProgress size={20} />
              ) : (
                <DoneIcon size={20} />
              )
            }
          >
            Valider
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
}
