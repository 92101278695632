import React, { useState } from "react";
import {
  Button,
  IconButton,
  Card,
  TextField as MuiTextField,
  Popover,
  CardActions,
  CardContent,
  Typography,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Box,
  MenuItem,
  Paper,
  CardHeader,
} from "@mui/material";
import { spacing } from "@mui/system";
import { styled, useTheme } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import useNotification from "hooks/useNotification";
import useReferentiel from "hooks/useReferentiel";
import RadioBtnStyled from "components/custom/RadioBtnStyled";
import CircularProgress from "@mui/material/CircularProgress";
import DoneIcon from "@mui/icons-material/Done";
import AddIcon from "@mui/icons-material/Add";
import { orange } from "@mui/material/colors";
import { isTrue, isFalse, isBooleanTrue } from "utils/objectUtil";
import useTchat from "../../hooks/useTchat";
import LastNodeEdit from "./LastNodeEdit";
import LastNodeContactEdit from "./LastNodeContactEdit";
import { TCHAT_NODE_TEXT, TCHAT_NODE_CONTACT_SINGLECHOICE } from "../../config";
// ----------------------------------------------------------------------
const TextField = styled(MuiTextField)(spacing);
// ----------------------------------------------------------------------
export default function ChatNodeForm({ editData, onComplete, parentTitle }) {
  const { notif } = useNotification();

  const [anchorEl, setAnchorEl] = React.useState(null);
  // ----------------------------------------------------------------------
  const theme = useTheme();
  // ----------------------------------------------------------------------

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleComplete = async () => {
    handleClose();
    await onComplete();
  };
  // ----------------------------------------------------------------------
  return (
    <div>
      <IconButton
        aria-label="details"
        size="small"
        onClick={handleClick}
        color="error"
      >
        {editData.id ? (
          <EditIcon style={{ fontSize: 15 }} />
        ) : (
          <AddIcon style={{ fontSize: 15 }} />
        )}
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => console.log()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card
          sx={{
            minWidth: 275,
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <CardContent>
            {open && (
              <>
                {parentTitle && (
                  <Typography
                    variant="h6"
                    sx={{
                      my: 3,
                      color: "#fff",
                      maxWidth: "400px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {parentTitle}
                  </Typography>
                )}
                <FormEdit editData={editData} onComplete={handleComplete} />
              </>
            )}
          </CardContent>
          <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button sx={{ color: "white" }} onClick={handleClose}>
              Annuler
            </Button>
          </CardActions>
        </Card>
      </Popover>
    </div>
  );
}
// ----------------------------------------------------------------------
const MuiPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(4),
  width: 400,
}));

// ----------------------------------------------------------------------
const FormEdit = ({ editData, onComplete }) => {
  const { refs } = useReferentiel();
  const { saveNode } = useTchat();
  // ----------------------------------------------------------------------
  const dataSchema = Yup.object().shape({
    title: Yup.string().max(1000).required(`Veuillez renseigner le titre`),
  });
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      title: editData && editData.title ? editData.title : "",
      orderNum: editData && editData.orderNum ? editData.orderNum : 0,
      levelNum: editData && editData.levelNum ? editData.levelNum : "",
      isRoot: editData && isBooleanTrue(editData.isRoot) ? true : false,
      isLast: editData && isBooleanTrue(editData.isLast) ? true : false,
      options: editData && editData.options ? editData.options : [],
      nodeParent: editData && editData.nodeParent ? editData.nodeParent : "",
      refTChatNodeType:
        editData && editData.refTChatNodeType
          ? editData.refTChatNodeType
          : TCHAT_NODE_TEXT,
    },
    validationSchema: dataSchema,
    onSubmit: async () => {
      try {
        const newData =
          editData && editData.id
            ? { id: editData.id, ...values }
            : { ...editData, ...values };
        if (isFalse(values.isLast)) {
          delete newData.options;
        }
        if (values.isRoot) {
          delete newData.orderNum;
        }
        await saveNode(newData);
        onComplete();
      } catch (error) {
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
    handleChange,
    setFieldValue,
  } = formik;
  const { refTChatNodeType } = refs;
  if (!refTChatNodeType) return;
  return (
    <MuiPaper>
      <FormikProvider value={formik}>
        <Form
          sx={{ display: "flex", flexDirection: "column" }}
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
        >
          <TextField
            fullWidth
            type="text"
            name="title"
            label="Titre"
            {...getFieldProps("title")}
            error={Boolean(touched.title && errors.title)}
            helperText={touched.title && errors.title}
            my={2}
          />
          {!values.isRoot && isFalse(values.isLast) && (
            <TextField
              fullWidth
              type="number"
              name="orderNum"
              label="Ordre"
              {...getFieldProps("orderNum")}
              error={Boolean(touched.orderNum && errors.orderNum)}
              helperText={touched.orderNum && errors.orderNum}
              my={2}
            />
          )}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={2}
          >
            <FormLabel component="legend">Dernier noeud ?</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={values.isLast}
              onChange={handleChange("isLast")}
            >
              <FormControlLabel
                value={Boolean(true)}
                control={<RadioBtnStyled />}
                label={<Typography>Oui</Typography>}
              />
              <FormControlLabel
                value={Boolean(false)}
                control={<RadioBtnStyled />}
                label={<Typography>Non</Typography>}
              />
            </RadioGroup>
          </Box>

          <TextField
            fullWidth
            select
            label="Type de noeud"
            value={values.refTChatNodeType}
            onChange={handleChange("refTChatNodeType")}
            {...getFieldProps("refTChatNodeType")}
            error={Boolean(touched.refTChatNodeType && errors.refTChatNodeType)}
            helperText={touched.refTChatNodeType && errors.refTChatNodeType}
            my={2}
          >
            {refTChatNodeType.map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {isTrue(values.isLast) && (
            <>
              {values.refTChatNodeType &&
              values.refTChatNodeType === TCHAT_NODE_CONTACT_SINGLECHOICE ? (
                <>
                  <LastNodeContactEdit
                    editOptions={values.options}
                    onComplete={(val) => setFieldValue("options", val)}
                  />
                </>
              ) : (
                <>
                  <LastNodeEdit
                    editOptions={values.options}
                    onComplete={(val) => setFieldValue("options", val)}
                  />
                </>
              )}
            </>
          )}

          <Button
            sx={{ my: 2 }}
            type="submit"
            fullWidth={editData && editData.id ? false : true}
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            endIcon={
              isSubmitting ? (
                <CircularProgress size={20} />
              ) : (
                <DoneIcon size={20} />
              )
            }
          >
            Valider
          </Button>
        </Form>
      </FormikProvider>
    </MuiPaper>
  );
};
// ----------------------------------------------------------------------
