import React, { useState, useEffect } from "react";
import {
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Box,
  Tooltip,
} from "@mui/material";
import CollectionsIcon from "@mui/icons-material/Collections";
import UploadMultiFiles from "components/file/UploadMultiFiles";
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function GalleryImgUpload({
  editData,
  field,
  onComplete,
  addLabel,
}) {
  const [images, setimages] = useState(null);
  // ----------------------------------------------------------------------
  // ----------------------------------------------------------------------
  const [open, setOpen] = React.useState(false);

  // ----------------------------------------------------------------------
  useEffect(() => {
    if (open) return;
    setimages(null);
  }, [open]);
  // ----------------------------------------------------------------------
  useEffect(() => {
    return () => {
      setimages(null);
    };
  }, []);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // ----------------------------------------------------------------------

  const handleValidate = async () => {
    try {
      if (!images) return;
      await onComplete(editData, field, images);
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };
  // ----------------------------------------------------------------------

  const isDisabledValidate = images ? false : true;
  // ----------------------------------------------------------------------

  return (
    <>
      <Tooltip title={addLabel}>
        <IconButton aria-label="details" size="small" onClick={handleClickOpen}>
          <CollectionsIcon size="small" />
        </IconButton>
      </Tooltip>
      <Dialog maxWidth="lg" open={open} fullWidth>
        <DialogTitle> {addLabel}</DialogTitle>
        <DialogContent>
          <UploadMultiFiles
            value={images}
            label="Galerie d'image"
            onChange={(val) => setimages(val)}
            caption="(Les formats *.jpeg and *.png sont acceptés)"
            acceptFile={{
              accept: {
                "image/jpeg": [".jpeg", ".png"],
              },
            }}
            mediaType="image"
          />
        </DialogContent>
        <DialogActions>
          <Box sx={{ width: "100%", my: 2 }}>
            <Button
              sx={{ my: 2 }}
              disabled={isDisabledValidate}
              onClick={handleValidate}
            >
              Valider
            </Button>
          </Box>

          <Button color="error" onClick={handleClose}>
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
