import { API_URL } from "config";

export const getObjectWithoutPropsNull = (myObject) => {
  try {
    const newObject = {};
    Object.keys(myObject).forEach(function (key) {
      if (
        typeof myObject[key] !== "undefined" &&
        myObject[key] !== null &&
        myObject[key] !== ""
      ) {
        newObject[key] = myObject[key];
      }
    });
    return newObject;
  } catch (error) {
    return myObject;
  }
};
export const getMediaUrl = (it, imgField, refName) => {
  try {
    const imgValue = it[imgField];
    if (!it || !imgValue) return null;
    return `${API_URL.getstream}/${refName}_${it.id}/${imgValue}`;
  } catch (error) {
    console.error(error);
    return null;
  }
};
export const isBoolean = (val) => {
  return typeof val === "boolean";
};
export const isBooleanFalse = (val) => {
  return typeof val === "boolean" && val === false;
};
export const isBooleanTrue = (val) => {
  return typeof val === "boolean" && val === true;
};
export const isFalse = (val) => {
  if (typeof val === "boolean") {
    return val === false;
  }
  if (typeof val === "string") {
    return val.toUpperCase() === "FALSE" || val === "0";
  }
  if (typeof val === "number") {
    return val === 0;
  }
  return true;
};
export const isTrue = (val) => {
  if (typeof val === "boolean") {
    return val === true;
  }
  if (typeof val === "string") {
    return val.toUpperCase() === "TRUE" || val === "1";
  }
  if (typeof val === "number") {
    return val === 1;
  }
  return true;
};
