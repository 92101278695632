import React, { useState } from "react";

import {
  Button,
  IconButton,
  Card,
  Popover,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import useNotification from "hooks/useNotification";
import useTchat from "hooks/useTchat";
// ----------------------------------------------------------------------
export default function ChatNodeDel({ editData, onComplete }) {
  const { delNode } = useTchat();

  const { notif } = useNotification();
  const [submitting, setsubmitting] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirm = async () => {
    try {
      if (!editData) return;
      setsubmitting(true);
      await delNode(editData.id);
      await onComplete();
      notif("Suppression réussie");
      setsubmitting(false);
      setAnchorEl(null);
    } catch (error) {
      console.error(error);
      notif(null, error);
      setsubmitting(false);
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <IconButton
        variant="contained"
        color="error"
        aria-label="details"
        onClick={handleClick}
        size="small"
      >
        <DeleteIcon style={{ fontSize: 15 }} />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card
          sx={{
            minWidth: 275,
          }}
        >
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Êtes vous sûr de supprimer ?
            </Typography>
          </CardContent>
          <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button disabled={submitting} onClick={handleConfirm}>
              Valider
            </Button>
            <Button onClick={handleClose}>Annuler</Button>
          </CardActions>
        </Card>
      </Popover>
    </div>
  );
}
