import axios from "axios";
import { API_URL } from "config";

import useNotification from "hooks/useNotification";
// ----------------------------------------------------------------------

export default function useQuiz() {
  const { notif } = useNotification();
  return {
    save: async (o) => {
      try {
        if (!o) return;
        if (o.id) {
          await axios.patch(API_URL.quiz + "/" + o.id, o);
        } else {
          await axios.post(API_URL.quiz, o);
        }
        notif("Sauvegarde réussie");
      } catch (error) {
        notif(null, error);
        throw error;
      }
    },
    delQuiz: async (id) => {
      try {
        if (!id) return;

        await axios.delete(API_URL.quiz + "/" + id);
        notif("Suppression réussie");
      } catch (error) {
        notif(null, error);
        throw error;
      }
    },
    getQuestionsByQuiz: async (id) => {
      try {
        if (!id) return;

        const res = await axios.get(API_URL.question, {
          params: {
            filters: { quiz: id },
            sorts: { orderNum: 1 },
          },
        });
        return res ? res.data : null;
      } catch (error) {
        notif(null, error);
        throw error;
      }
    },
    saveQuestion: async (o) => {
      try {
        if (!o) return;
        if (o.id) {
          await axios.patch(API_URL.question + "/" + o.id, o);
        } else {
          await axios.post(API_URL.question, o);
        }
        notif("Sauvegarde réussie");
      } catch (error) {
        notif(null, error);
        throw error;
      }
    },
    delQuestion: async (id) => {
      try {
        if (!id) return;
        await axios.delete(API_URL.question + "/" + id);
        notif("Suppression réussie");
      } catch (error) {
        notif(null, error);
        throw error;
      }
    },
    getAnswersByQuestion: async (id) => {
      try {
        if (!id) return;

        const res = await axios.get(API_URL.answer, {
          params: {
            filters: { question: id },
            sorts: { orderNum: 1 },
          },
        });
        return res ? res.data : null;
      } catch (error) {
        notif(null, error);
        throw error;
      }
    },
    saveAnswer: async (o) => {
      try {
        if (!o) return;
        if (o.id) {
          await axios.patch(API_URL.answer + "/" + o.id, o);
        } else {
          await axios.post(API_URL.answer, o);
        }
        notif("Sauvegarde réussie");
      } catch (error) {
        notif(null, error);
        throw error;
      }
    },
    delAnswer: async (id) => {
      try {
        if (!id) return;
        await axios.delete(API_URL.answer + "/" + id);
        notif("Suppression réussie");
      } catch (error) {
        notif(null, error);
        throw error;
      }
    },
  };
}
