import React from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import {
  DialogContent,
  DialogTitle,
  Dialog,
  TextField as MuiTextField,
  Button,
  DialogActions,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CircularProgress from "@mui/material/CircularProgress";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import { spacing } from "@mui/system";
import useChallenge from "hooks/useChallenge";
// ----------------------------------------------------------------------
const TextField = styled(MuiTextField)(spacing);
// ----------------------------------------------------------------------
export default function ChallengeNoteForm({ editData, onComplete }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleComplete = async () => {
    await onComplete();
    handleClose();
    console.log("cool");
  };
  // ----------------------------------------------------------------------
  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        <StickyNote2Icon />
        Editer les notes du défi
      </Button>
      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogTitle> Ajouter un Stéréo Défi</DialogTitle>
        <DialogContent>
          <FormEdit editData={editData} onComplete={handleComplete} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
// ----------------------------------------------------------------------
const FormEdit = ({ editData, onComplete }) => {
  const { saveChallenge } = useChallenge();
  const dataSchema = Yup.object().shape({
    predefinedNote: Yup.string()
      .max(20)
      .required("Veuillez renseigner la note prédéfinie"),
    inspireNote: Yup.string()
      .max(100)
      .required(`Veuillez renseigner la note inspirée`),
  });
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      predefinedNote:
        editData && editData.predefinedNote ? editData.predefinedNote : "",
      inspireNote: editData && editData.inspireNote ? editData.inspireNote : "",
    },
    validationSchema: dataSchema,
    onSubmit: async () => {
      try {
        const newData = { id: editData.id, ...values }; //sauve only code, title, enable
        await saveChallenge(newData);
        onComplete();
      } catch (error) {
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
  } = formik;
  if (!editData) return;
  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <TextField
            fullWidth
            type="text"
            multiline
            rows={4}
            name="predefinedNote"
            label={`“Prédéfinie” : Note`}
            {...getFieldProps("predefinedNote")}
            error={Boolean(touched.predefinedNote && errors.predefinedNote)}
            helperText={touched.predefinedNote && errors.predefinedNote}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            multiline
            rows={8}
            name="inspireNote"
            label={`“Inspire moi” : Note`}
            {...getFieldProps("inspireNote")}
            error={Boolean(touched.inspireNote && errors.inspireNote)}
            helperText={touched.inspireNote && errors.inspireNote}
            my={2}
          />

          <Button
            sx={{ my: 2 }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            endIcon={
              isSubmitting ? (
                <CircularProgress size={20} />
              ) : (
                <DoneIcon size={20} />
              )
            }
          >
            Valider
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
};
