import React, { useEffect } from "react";
import {
  Button,
  TextField as MuiTextField,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import DoneIcon from "@mui/icons-material/Done";
import CircularProgress from "@mui/material/CircularProgress";
import { spacing } from "@mui/system";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import useReferentiel from "hooks/useReferentiel";
import useQuiz from "hooks/useQuiz";
import { QUIZ_TYPE_STEREO } from "config";
// ----------------------------------------------------------------------
const TextField = styled(MuiTextField)(spacing);
// ----------------------------------------------------------------------
export default function QuestionEditBtn({
  quiz,
  editQuestion,
  addLabel,
  handleComplete,
}) {
  // ----------------------------------------------------------------------
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // ----------------------------------------------------------------------
  const onComplete = () => {
    handleComplete();
    handleClose();
  };
  return (
    <div>
      <Tooltip title={addLabel}>
        <Button
          variant="outlined"
          color="warning"
          aria-label="details"
          size="small"
          onClick={handleClickOpen}
        >
          <QuestionAnswerIcon /> {editQuestion ? "Modifier" : addLabel}
        </Button>
      </Tooltip>
      <Dialog maxWidth="md" open={open} fullWidth>
        <DialogTitle> {addLabel}</DialogTitle>
        <DialogContent>
          <FormEdit
            quiz={quiz}
            editData={editQuestion}
            onComplete={onComplete}
          />
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

// ----------------------------------------------------------------------
const FormEdit = ({ quiz, editData, onComplete }) => {
  const { refs } = useReferentiel();
  const { saveQuestion } = useQuiz();
  const { refStereoType } = refs;
  // ----------------------------------------------------------------------

  const getValidationParams = () => {
    try {
      if (!refStereoType) return {};
      if (String(quiz.refQuizType) === QUIZ_TYPE_STEREO) return {};
      const obj = {};
      refStereoType.forEach((it) => {
        obj[`point_${it.code}`] = Yup.string()
          .max(200)
          .required("Veuillez renseigner le point gagné pour " + it.label);
      });
      return obj;
    } catch (error) {
      return {};
    }
  };
  const getInitValues = () => {
    try {
      if (String(quiz.refQuizType) === QUIZ_TYPE_STEREO) return {};
      const obj = {};
      refStereoType.forEach((it) => {
        const pointField = `point_${it.code}`;
        obj[pointField] = "";
      });
      return obj;
    } catch (error) {
      return {};
    }
  };

  const getFieldParams = () => {
    try {
      if (!refStereoType) return;
      if (String(quiz.refQuizType) === QUIZ_TYPE_STEREO) return <></>;
      return (
        <>
          {refStereoType.map((it, i) => {
            const pointField = `point_${it.code}`;
            return (
              <TextField
                key={i}
                fullWidth
                type="number"
                label={`Point gagné pour le ${it.label}`}
                {...getFieldProps(pointField)}
                error={Boolean(touched[pointField] && errors[pointField])}
                helperText={touched[pointField] && errors[pointField]}
                my={2}
              />
            );
          })}
        </>
      );
    } catch (error) {
      return <></>;
    }
  };

  const dataSchema = Yup.object().shape({
    title: Yup.string().max(150).required(`Veuillez renseigner le titre`),
    orderNum: Yup.number().max(100).required(`Veuillez renseigner l'ordre`),
    ...getValidationParams(),
  });
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      title: editData && editData.title ? editData.title : "",
      orderNum: editData && editData.orderNum ? editData.orderNum : 100,
      ...getInitValues(),
    },
    validationSchema: dataSchema,
    onSubmit: async () => {
      try {
        const pointGained = [];
        if (String(quiz.refQuizType) !== QUIZ_TYPE_STEREO) {
          refStereoType.forEach((it) => {
            const keyField = `point_${it.code}`;
            pointGained.push({
              refStereoType: { ...it },
              point: values[keyField],
            });
          });
        }

        const newData =
          editData && editData.id
            ? {
                ...editData,
                title: values.title,
                orderNum: values.orderNum,
                pointGained,
              }
            : {
                title: values.title,
                orderNum: values.orderNum,
                pointGained,
                quiz: quiz.id,
              };
        await saveQuestion(newData);
        onComplete();
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
    handleChange,
    setFieldValue,
  } = formik;
  // ----------------------------------------------------------------------

  useEffect(() => {
    if (!refStereoType) return;
    if (String(quiz.refQuizType) === QUIZ_TYPE_STEREO) return;
    if (!editData) return;
    if (!editData.pointGained) return;
    if (!setFieldValue) return;

    refStereoType.forEach((it) => {
      const pointField = `point_${it.code}`;
      const pointGainedFound = editData.pointGained.find(
        (p) => p.refStereoType.code === it.code
      );

      if (pointGainedFound) {
        setFieldValue(pointField, pointGainedFound.point);
      }
    });
  }, [refStereoType, editData, setFieldValue]);
  // ----------------------------------------------------------------------

  if (!refs) return;
  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <TextField
            fullWidth
            type="text"
            name="title"
            label="Titre"
            {...getFieldProps("title")}
            error={Boolean(touched.title && errors.title)}
            helperText={touched.title && errors.title}
            my={2}
          />
          <TextField
            fullWidth
            type="number"
            label="Ordre d'affichage"
            {...getFieldProps("orderNum")}
            error={Boolean(touched.orderNum && errors.orderNum)}
            helperText={touched.orderNum && errors.orderNum}
            my={2}
          />
          {getFieldParams()}
          <Button
            sx={{ my: 2 }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            endIcon={
              isSubmitting ? (
                <CircularProgress size={20} />
              ) : (
                <DoneIcon size={20} />
              )
            }
          >
            Valider
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
};
