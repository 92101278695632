import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Button,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card,
  CardActions,
  CardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Popover,
  Box,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import CircularProgress from "@mui/material/CircularProgress";
// ----------------------------------------------------------------------
const columns = [
  { field: "id", headerName: "ID", width: 150 },
  {
    field: "firstname",
    headerName: "Prénom",
    width: 200,
    editable: false,
  },
  {
    field: "lastname",
    headerName: "Nom",
    width: 200,
    editable: false,
  },
  {
    field: "email",
    headerName: "Email",
    width: 200,
    editable: false,
  },
  {
    field: "stringStatus",
    headerName: "Statut",
    width: 150,
    editable: false,
    valueGetter: (params) => {
      try {
        const st = params.row.status;
        return Number(st) === 0 ? "Valide" : Number(st) === 1 ? "erreur" : "";
      } catch (error) {
        console.error(error);
        return "";
      }
    },
  },
  {
    field: "logs",
    headerName: "Message d'erreur",
    width: 250,
  },
];
export default function GridImportUser({
  users,
  handleImport,
  onCancel,
  loading,
}) {
  const [rows, setrows] = useState(null);
  useEffect(() => {
    if (!users) return;
    setrows(users);
  }, [users]);
  const onImport = () => {
    handleImport(rows);
  };
  if (!Array.isArray(rows)) return <></>;
  return (
    <Box sx={{ height: 600, p: 10 }}>
      <DataGrid
        rowsPerPageOptions={[20, 50, 100]}
        rows={rows}
        columns={columns}
        pageSize={20}
      />
      <Box display="flex" justifyContent="space-between" sx={{ my: 3 }}>
        <CancelImportBtn onCancel={onCancel} />
        {loading ? (
          <CircularProgress size={20} />
        ) : (
          <Button variant="contained" color="primary" onClick={onImport}>
            <CheckIcon />
            Importer
          </Button>
        )}
      </Box>
    </Box>
  );
}
const CancelImportBtn = ({ onCancel }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirm = async () => {
    try {
      onCancel();
      setAnchorEl(null);
    } catch (error) {
      console.error(error);
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Button variant="outlined" color="error" onClick={handleClick}>
        <ClearIcon />
        Annuler l'import
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card
          sx={{
            minWidth: 275,
            
          }}
        >
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Confirmez-vous l'annulation d'import
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Voulez-vous annuler l'import en cours ?
            </Typography>
          </CardContent>
          <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={handleConfirm}>Valider</Button>
            <Button onClick={handleClose}>Annuler</Button>
          </CardActions>
        </Card>
      </Popover>
    </div>
  );
};
