import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import {
  MenuItem,
  TextField as MuiTextField,
  ListItemText,
  Button,
  Checkbox,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CircularProgress from "@mui/material/CircularProgress";
import { spacing } from "@mui/system";
import useNotification from "hooks/useNotification";
import useReferentiel from "hooks/useReferentiel";
import useProgram from "hooks/useProgram";
import { RULE_OPERATORS } from "config";
// ----------------------------------------------------------------------
const TextField = styled(MuiTextField)(spacing);
// ----------------------------------------------------------------------
export default function RuleDataForm({
  editData,
  handleSave,
  refData,
  field,
  fieldLabel,
  typeRule,
}) {
  // ----------------------------------------------------------------------
  const [dataSelected, setDataSelected] = useState([]);
  // ----------------------------------------------------------------------
  const isSingleChoice = typeRule === "arrayType" ? false : true;
  // ----------------------------------------------------------------------
  const objSchema = {
    operator: Yup.string().max(20).required("Veuillez renseigner l'opérateur"),
  };
  objSchema.data = isSingleChoice
    ? Yup.string().max(100).required(`Veuillez renseigner ${fieldLabel}`)
    : null;
  const dataSchema = Yup.object().shape({
    ...objSchema,
  });

  const objInitValue = {
    operator: editData && editData.operator ? editData.operator : "",
  };
  objInitValue.data = isSingleChoice
    ? editData && editData.data
      ? editData.data
      : ""
    : null;
   const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...objInitValue,
    },
    validationSchema: dataSchema,
    onSubmit: async () => {
      try {
        setSubmitting(true);
        const newData =
          editData && editData.id ? { ...editData, ...values } : { ...values };
        newData.data = isSingleChoice ? values.data : [...dataSelected];
        await handleSave(newData, field);
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
    handleChange,
    setFieldValue,
  } = formik;
  // ----------------------------------------------------------------------
  useEffect(() => {
    if (!editData) return;
    if (isSingleChoice) return;
    if (editData.data) setDataSelected(editData.data);
  }, [editData, isSingleChoice]);
  // ----------------------------------------------------------------------

  const handleChangeSnotes = (event) => {
    if (isSingleChoice) return;
    const {
      target: { value },
    } = event;
    setDataSelected(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  // ----------------------------------------------------------------------
  if (!refData) return <></>;
  if (!typeRule) return <></>;
  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <TextField
            fullWidth
            select
            label="Opérateur"
            value={values.operator}
            onChange={handleChange("operator")}
            {...getFieldProps("operator")}
            error={Boolean(touched.operator && errors.operator)}
            helperText={touched.operator && errors.operator}
            my={2}
          >
            {RULE_OPERATORS[typeRule].map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {/* data array multi */}
          {isSingleChoice ? (
            <TextField
              fullWidth
              select
              label={fieldLabel}
              value={values.data}
              onChange={handleChange("data")}
              {...getFieldProps("data")}
              error={Boolean(touched.data && errors.data)}
              helperText={touched.data && errors.data}
              my={2}
            >
              {refData.map((option, i) => (
                <MenuItem key={i} value={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          ) : (
            <TextField
              fullWidth
              select
              multiple={true}
              label={fieldLabel}
              {...getFieldProps("data")}
              SelectProps={{
                multiple: true,
                value: dataSelected,
                renderValue: (selected) => {
                  if (!refData) return;
                  const arr = refData.filter((it) => selected.includes(it.id));
                  const arrTitle = arr.map((it) => it.label);
                  return arrTitle.join(", ");
                },
                onChange: handleChangeSnotes,
              }}
              my={2}
            >
              {refData.map((c, i) => {
                return (
                  <MenuItem key={i} value={c.id}>
                    <Checkbox checked={dataSelected.indexOf(c.id) > -1} />
                    <ListItemText primary={c.label} />
                  </MenuItem>
                );
              })}
            </TextField>
          )}

          <Button
            sx={{ my: 2 }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            endIcon={
              isSubmitting ? (
                <CircularProgress size={20} />
              ) : (
                <DoneIcon size={20} />
              )
            }
          >
            Valider
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
}
// ----------------------------------------------------------------------
const FormEdit = ({ editData }) => {
  // ----------------------------------------------------------------------

  const refName = "programmotor";
  const { programs, getPublishPrograms } = useProgram();
  const { refs, saveRef, initRefs } = useReferentiel();
  const { notif } = useNotification();
  // ----------------------------------------------------------------------

  useEffect(() => {
    getPublishPrograms();
  }, []);
  const dataSchema = Yup.object().shape({
    code: Yup.string().max(20).required("Veuillez renseigner le code"),
    label: Yup.string().max(50).required(`Veuillez renseigner le libellé`),
    programs: Yup.string()
      .max(50)
      .required(`Veuillez renseigner le programme publié`),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: editData && editData.code ? editData.code : "",
      label: editData && editData.label ? editData.label : "",
      programs: editData && editData.programs ? editData.programs : "",
    },
    validationSchema: dataSchema,
    onSubmit: async () => {
      try {
        const newData =
          editData && editData.id ? { ...editData, ...values } : { ...values };
        await saveRef(
          {
            ...newData,
          },
          refName
        );
        await initRefs();
        notif("Sauvegarde réussie");
      } catch (error) {
        console.error(error);
        notif(null, error);
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleChange,
    handleSubmit,
    getFieldProps,
    setSubmitting,
  } = formik;
  if (!programs) return <>Aucun programme publié n'est trouvé</>;
  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <TextField
            fullWidth
            type="text"
            label="Code"
            {...getFieldProps("code")}
            error={Boolean(touched.code && errors.code)}
            helperText={touched.code && errors.code}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            name="label"
            label="Libellé"
            {...getFieldProps("label")}
            error={Boolean(touched.label && errors.label)}
            helperText={touched.label && errors.label}
            my={2}
          />
          <TextField
            fullWidth
            select
            label="Programme publié"
            value={values.programs}
            onChange={handleChange("programs")}
            {...getFieldProps("programs")}
            error={Boolean(touched.programs && errors.programs)}
            helperText={touched.programs && errors.programs}
            my={2}
          >
            {programs.map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {option.title}
              </MenuItem>
            ))}
          </TextField>
          <Button
            sx={{ my: 2 }}
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Valider
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
};
