import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import {
  Box,
  MenuItem,
  TextField as MuiTextField,
  Button,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Typography,
  Grid,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CircularProgress from "@mui/material/CircularProgress";
import { spacing } from "@mui/system";
import useReferentiel from "hooks/useReferentiel";
import useChallenge from "hooks/useChallenge";
import RadioBtnStyled from "components/custom/RadioBtnStyled";
import { isBooleanTrue } from "utils/objectUtil";
// ----------------------------------------------------------------------
const TextField = styled(MuiTextField)(spacing);
// ----------------------------------------------------------------------

export default function ChallengeForm({ editData, onComplete }) {
  const { refs } = useReferentiel();
  const { getChallenges, saveChallenge } = useChallenge();
  // ----------------------------------------------------------------------

  const getValidationParams = () => {
    try {
      if (!refStereoType) return;
      const obj = {};
      refStereoType.forEach((it) => {
        obj[`point_${it.code}`] = Yup.string()
          .max(200)
          .required("Veuillez renseigner le point gagné pour " + it.label);
        obj[`awardMessage_${it.code}`] = Yup.string()
          .max(200)
          .required(
            "Veuillez renseigner le message de récompense pour " + it.label
          );
      });
      return obj;
    } catch (error) {
      return {};
    }
  };
  const getInitValues = () => {
    try {
      const obj = {};
      refStereoType.forEach((it) => {
        const pointField = `point_${it.code}`;
        obj[pointField] = "";
        const awardMessageField = `awardMessage_${it.code}`;
        obj[awardMessageField] = "";
      });
      return obj;
    } catch (error) {
      return {};
    }
  };

  const getFieldParams = () => {
    try {
      if (!refStereoType) return;
      return (
        <Grid container spacing={4}>
          {refStereoType.map((it, i) => {
            const pointField = `point_${it.code}`;
            const awardMessageField = `awardMessage_${it.code}`;
            return (
              <Grid key={i} item xs={4}>
                <TextField
                  fullWidth
                  type="number"
                  label={`Point gagné pour le ${it.label}`}
                  {...getFieldProps(pointField)}
                  error={Boolean(touched[pointField] && errors[pointField])}
                  helperText={touched[pointField] && errors[pointField]}
                  my={2}
                />
                <TextField
                  fullWidth
                  type="text"
                  label={`Message de récompense le ${it.label}`}
                  {...getFieldProps(awardMessageField)}
                  error={Boolean(
                    touched[awardMessageField] && errors[awardMessageField]
                  )}
                  helperText={
                    touched[awardMessageField] && errors[awardMessageField]
                  }
                  my={2}
                />
              </Grid>
            );
          })}
        </Grid>
      );
    } catch (error) {
      return <></>;
    }
  };
  // ----------------------------------------------------------------------

  const dataSchema = Yup.object().shape({
    code: Yup.string().max(20).required("Veuillez renseigner le code"),
    title: Yup.string().max(100).required(`Veuillez renseigner le titre`),
    description: Yup.string().max(250).required(`Veuillez renseigner le titre`),
    refSubject: Yup.string().max(255).required("Veuillez renseigner le sujet"),
    refChallengeType: Yup.string()
      .max(255)
      .required("Veuillez renseigner le type du défi"),
    refStereoType: Yup.string()
      .max(255)
      .required("Veuillez renseigner le stéréotype du défi"),
    ...getValidationParams(),
  });
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      code: editData && editData.code ? editData.code : "",
      title: editData && editData.title ? editData.title : "",
      description: editData && editData.description ? editData.description : "",
      enable: editData && isBooleanTrue(editData.enable) ? true : true, //by default is true
      refSubject: editData && editData.refSubject ? editData.refSubject.id : "",
      refChallengeType:
        editData && editData.refChallengeType
          ? editData.refChallengeType.id
          : "",
      refStereoType:
        editData && editData.refStereoType ? editData.refStereoType.id : "",
      ...getInitValues(),
    },
    validationSchema: dataSchema,
    onSubmit: async () => {
      try {
        const pointGained = [];
        refStereoType.forEach((it) => {
          const keyField = `point_${it.code}`;
          const awardMessageField = `awardMessage_${it.code}`;
          pointGained.push({
            id: "temp_" + new Date().getTime(),
            refStereoType: { ...it },
            point: values[keyField],
            awardMessage: values[awardMessageField],
          });
        });
        if (!pointGained || pointGained.length === 0) return;
        const newData =
          editData && editData.id
            ? { id: editData.id, ...values, pointGained }
            : { ...values, pointGained }; //sauve only code, title, enable
        await saveChallenge(newData);
        await getChallenges();
        onComplete();
      } catch (error) {
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
    handleChange,
    setFieldValue,
  } = formik;
  const { refSubject, refChallengeType, refStereoType } = refs;
  // ----------------------------------------------------------------------

  useEffect(() => {
    if (!refStereoType) return;
    if (!editData) return;
    if (!editData.pointGained) return;
    if (!setFieldValue) return;

    refStereoType.forEach((it) => {
      const pointField = `point_${it.code}`;
      const awardMessageField = `awardMessage_${it.code}`;
      const pointGainedFound = editData.pointGained.find(
        (p) => p.refStereoType.code === it.code
      );

      if (pointGainedFound) {
        setFieldValue(pointField, pointGainedFound.point);
        setFieldValue(awardMessageField, pointGainedFound.awardMessage);
      }
    });
  }, [refStereoType, editData, setFieldValue]);
  if (!refs) return <></>;
  if (!refSubject) return <></>;
  if (!refStereoType) return <></>;
  if (!refChallengeType) return <></>;
  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <TextField
            fullWidth
            type="text"
            label="Code"
            {...getFieldProps("code")}
            error={Boolean(touched.code && errors.code)}
            helperText={touched.code && errors.code}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            name="title"
            label="Titre"
            {...getFieldProps("title")}
            error={Boolean(touched.title && errors.title)}
            helperText={touched.title && errors.title}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            name="description"
            label="Description"
            {...getFieldProps("description")}
            error={Boolean(touched.description && errors.description)}
            helperText={touched.description && errors.description}
            my={2}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={2}
          >
            <FormLabel component="legend">Activé ?</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={values.enable}
              onChange={handleChange("enable")}
            >
              <FormControlLabel
                value={Boolean(true)}
                control={<RadioBtnStyled />}
                label={<Typography>Oui</Typography>}
              />
              <FormControlLabel
                value={Boolean(false)}
                control={<RadioBtnStyled />}
                label={<Typography>Non</Typography>}
              />
            </RadioGroup>
          </Box>

          <TextField
            fullWidth
            select
            label="Sujet"
            value={values.refSubject}
            onChange={handleChange("refSubject")}
            {...getFieldProps("refSubject")}
            error={Boolean(touched.refSubject && errors.refSubject)}
            helperText={touched.refSubject && errors.refSubject}
            my={2}
          >
            {refSubject.map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            disabled={editData && editData.refChallengeType ? true : false}
            fullWidth
            select
            label="Type de défi"
            value={values.refChallengeType}
            onChange={handleChange("refChallengeType")}
            {...getFieldProps("refChallengeType")}
            error={Boolean(touched.refChallengeType && errors.refChallengeType)}
            helperText={
              editData && editData.refChallengeType
                ? "Le type de défi ne peut pas être changé"
                : touched.refChallengeType && errors.refChallengeType
            }
            my={2}
          >
            {refChallengeType.map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            select
            label="Stéréotype du défi"
            value={values.refStereoType}
            onChange={handleChange("refStereoType")}
            {...getFieldProps("refStereoType")}
            error={Boolean(touched.refStatus && errors.refStereoType)}
            helperText={touched.refStereoType && errors.refStereoType}
            my={2}
          >
            {refStereoType.map((option, i) => (
              <MenuItem key={i} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {getFieldParams()}
          <Button
            sx={{ my: 2 }}
            type="submit"
            fullWidth={editData && editData.id ? false : true}
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            endIcon={
              isSubmitting ? (
                <CircularProgress size={20} />
              ) : (
                <DoneIcon size={20} />
              )
            }
          >
            Valider
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
}
