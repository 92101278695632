import React, { useEffect, useState } from "react";
import {
  TextField as MuiTextField,
  Box,
  Stack,
  Typography,
  Paper,
  Divider as MuiDivider,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import useQuiz from "hooks/useQuiz";
import { grey } from "@mui/material/colors";
import QuestionEditBtn from "./QuestionEditBtn";
import QuestionItem from "./QuestionItem";
// ----------------------------------------------------------------------
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));
// ----------------------------------------------------------------------
const Divider = styled(MuiDivider)(spacing);
// ----------------------------------------------------------------------

export default function QuestionPanel({ quiz }) {
  const [questions, setquestions] = useState(null);
  const [updatedAt, setupdatedAt] = useState(null);
  // ----------------------------------------------------------------------
  const { getQuestionsByQuiz } = useQuiz();
  // ----------------------------------------------------------------------
  useEffect(() => {
    if (!quiz) return;
    init();
  }, [quiz]);
  // ----------------------------------------------------------------------
  useEffect(() => {
    if (!quiz) return;
    if (!updatedAt) return;
    init();
  }, [quiz, updatedAt]);

  const init = async () => {
    try {
      const res = await getQuestionsByQuiz(quiz.id);
      if (res) {
        setquestions(res);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // ----------------------------------------------------------------------
  const handleComplete = () => {
    setupdatedAt(new Date());
  };
  if (!quiz) return <></>;
  // ----------------------------------------------------------------------
  return (
    <>
      <Box sx={{ my: 2, flexGrow: 1, overflow: "hidden", px: 3 }}>
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          display="flex"
          justifyContent="flex-end"
          sx={{ width: "100%" }}
        >
          <QuestionEditBtn
            quiz={quiz}
            addLabel="Nouvelle question"
            handleComplete={handleComplete}
          />
        </Stack>
      </Box>
      {Array.isArray(questions) && questions.length > 0 ? (
        <>
          {questions.map((it, i) => {
            const bgColor = i % 2 === 0 ? grey.A200 : grey.A400;
            return (
              <React.Fragment key={i}>
                <QuestionItem
                  item={it}
                  handleComplete={handleComplete}
                  quiz={quiz}
                  bgColor={bgColor}
                />
                <Divider my={2} />
              </React.Fragment>
            );
          })}
        </>
      ) : (
        <Box sx={{ flexGrow: 1, overflow: "hidden", px: 3 }}>
          <Item
            sx={{
              my: 1,
              mx: "auto",
              p: 2,
            }}
          >
            <>
              <Stack
                spacing={2}
                direction="row"
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                sx={{ width: "100%" }}
              >
                <Typography noWrap>Questions non disponibles</Typography>
              </Stack>
            </>
          </Item>
        </Box>
      )}
    </>
  );
}
// ----------------------------------------------------------------------
