import axios from "axios";
import { API_URL } from "config";

import useNotification from "hooks/useNotification";
// ----------------------------------------------------------------------

export default function useTchat() {
  const { notif } = useNotification();
  return {
    save: async (o) => {
      try {
        if (!o) return;
        if (o.id) {
          await axios.patch(API_URL.tchat + "/" + o.id, o);
        } else {
          await axios.post(API_URL.tchat, o);
        }
        notif("Sauvegarde réussie");
      } catch (error) {
        notif(null, error);
        throw error;
      }
    },
    delTchat: async (id) => {
      try {
        if (!id) return;
        await axios.delete(API_URL.tchat + "/" + id);
        notif("Suppression réussie");
      } catch (error) {
        notif(null, error);
        throw error;
      }
    },
    cloneNodeToParent: async (id, idParent) => {
      try {
        if (!id || !idParent) return;
        await axios.get(API_URL.tchat + "/clone-node/" + id + "/" + idParent);
        notif("Clonage réussie");
      } catch (error) {
        notif(null, error);
        throw error;
      }
    },

    findNodeByTchat: async (id) => {
      try {
        if (!id) return;
        const res = await axios.get(API_URL.tchat + "/tchat-node/" + id);
        if (res && res.data) {
          return res.data;
        }
        return null;
      } catch (error) {
        console.error(error);
        //notif(null, error);
        throw error;
      }
    },
    saveNode: async (o) => {
      try {
        if (!o) return;
        if (o.id) {
          await axios.patch(API_URL.tchat + "/node/" + o.id, o);
        } else {
          await axios.post(API_URL.tchat + "/node", o);
        }
        notif("Sauvegarde réussie");
      } catch (error) {
        notif(null, error);
        throw error;
      }
    },
    delNode: async (id) => {
      try {
        if (!id) return;
        await axios.delete(API_URL.tchat + "/node/" + id);
        notif("Suppression réussie");
      } catch (error) {
        notif(null, error);
        throw error;
      }
    },
  };
}
