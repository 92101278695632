import React, { useEffect, useState } from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import SvgIcon from "@mui/material/SvgIcon";
import Collapse from "@mui/material/Collapse";
import { TreeView } from "@mui/x-tree-view/TreeView";
import {
  TreeItem,
  useTreeItem,
  treeItemClasses,
} from "@mui/x-tree-view/TreeItem";
import clsx from "clsx";
import useTchat from "hooks/useTchat";
import ChatNodeForm from "./ChatNodeForm";
import ChatNodeDel from "./ChatNodeDel";
import ChatNodeClone from "./ChatNodeClone";
import { TCHAT_NODE_CONTACT_SINGLECHOICE } from "../../config";
// ----------------------------------------------------------------------
function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

// ----------------------------------------------------------------------

const CustomContent = React.forwardRef(function CustomContent(props, ref) {
  const {
    editData,
    onComplete,
    onSelectCloneNode,
    cloned,
    classes,
    className,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
  } = props;
  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event) => {
    preventSelection(event);
  };

  const handleExpansionClick = (event) => {
    handleExpansion(event);
  };

  const handleSelectionClick = (event) => {
    handleSelection(event);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      onMouseDown={handleMouseDown}
      ref={ref}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </div>
      <Box display="flex" flexDirection="column">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "400px",
            minHeight: "50px",
            p: 2,
          }}
        >
          <Typography
            onClick={handleSelectionClick}
            component="div"
            className={classes.label}
          >
            {label}
          </Typography>

          {editData && (
            <>
              <Box sx={{ mx: 1 }} />
              <ChatNodeForm editData={editData} onComplete={onComplete} />
              <ChatNodeDel editData={editData} onComplete={onComplete} />
              {!editData.isLast && (
                <>
                  <Box sx={{ mx: 1 }} />
                  <ChatNodeForm
                    editData={{
                      tChat: editData.tChat,
                      nodeParent: editData.id,
                      isRoot: false,
                    }}
                    onComplete={onComplete}
                    parentTitle={label}
                  />
                </>
              )}
              {!editData.isLast && !editData.isRoot && (
                <ChatNodeClone
                  disabled={cloned.includes(String(editData.id))}
                  editData={editData}
                  onSelectCloneNode={onSelectCloneNode}
                />
              )}
            </>
          )}
        </Box>
        {editData.isLast && editData.options && (
          <ul>
            {editData.options.map((opt, i) => {
              const label =
                editData.refTChatNodeType &&
                editData.refTChatNodeType === TCHAT_NODE_CONTACT_SINGLECHOICE
                  ? opt.name
                  : opt.title;
              return (
                <li key={i}>
                  <Typography>{label}</Typography>
                </li>
              );
            })}
          </ul>
        )}
      </Box>
    </div>
  );
});
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const CustomTreeItem = React.forwardRef((props, ref) => {
  const { editData, onComplete, onSelectCloneNode, cloned } = props;
  return (
    <StyledTreeItem
      ContentComponent={CustomContent}
      {...props}
      ref={ref}
      ContentProps={{
        editData,
        onComplete,
        onSelectCloneNode,
        cloned,
      }}
    />
  );
});

const StyledTreeItem = styled(TreeItem)(({ theme }) => {
  return {
    [`& .${treeItemClasses.iconContainer}`]: {
      "& .close": {
        opacity: 0.3,
      },
    },
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 15,
      paddingLeft: 18,
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
  };
});
// ----------------------------------------------------------------------
const data = {
  id: "root",
  name: "Parent",
  children: [
    {
      id: "1",
      name: "Child - 1",
    },
    {
      id: "3",
      name: "Child - 3",
      children: [
        {
          id: "4",
          name: "Child - 4",
        },
      ],
    },
  ],
};

// ----------------------------------------------------------------------
export default function ChatFlowTree({ editData }) {
  const { findNodeByTchat, cloneNodeToParent } = useTchat();
  // ----------------------------------------------------------------------
  const [editTChatNodes, seteditTChatNodes] = useState(null);
  const [expandedNodes, setexpandedNodes] = useState(null);
  const [updatedAt, setupdatedAt] = useState(null);
  const [cloned, setCloned] = useState([]);
  // ----------------------------------------------------------------------
  useEffect(() => {
    if (!editData) return;
    init();
  }, [editData]);
  useEffect(() => {
    if (!updatedAt) return;
    init();
  }, [updatedAt]);
  const init = async () => {
    try {
      const res = await findNodeByTchat(editData.id);
      if (res) {
        seteditTChatNodes(res);
      }
    } catch (error) {
      console.error(error);
      seteditTChatNodes(null);
    }
  };
  // ----------------------------------------------------------------------
  useEffect(() => {
    if (!editTChatNodes) return;
    const arr = [];
    setExpander(editTChatNodes, arr);
    setexpandedNodes(arr);
  }, [editTChatNodes]);
  // ----------------------------------------------------------------------
  /**  */
  // ----------------------------------------------------------------------
  useEffect(() => {
    if (!cloned) return;
    if (cloned.length < 2) return;
    const init = async () => {
      await cloneNodeToParent(cloned[0], cloned[1]);
      setCloned([]);
      await setupdatedAt(new Date());
    };
    init();
  }, [cloned]);
  const onSelectCloneNode = async (idClone) => {
    let arr = [...cloned];
    if (arr.includes(String(idClone))) {
      arr = arr.filter((it) => String(it) !== String(idClone));
      setCloned(arr);
      return;
    }
    if (arr.length < 2) {
      arr.push(String(idClone));
      setCloned(arr);
    }
  };
  // ----------------------------------------------------------------------

  const setExpander = (node, arr) => {
    if (!node) return;
    arr.push(node.id);
    if (node.children && node.children.length > 0) {
      node.children.forEach((it) => {
        setExpander(it, arr);
      });
    }
  };
  // ----------------------------------------------------------------------
  const renderTree = (nodes) => {
    return (
      <CustomTreeItem
        key={nodes.id}
        nodeId={nodes.id}
        label={nodes.title}
        editData={nodes}
        onComplete={() => setupdatedAt(new Date())}
        onSelectCloneNode={onSelectCloneNode}
        cloned={cloned}
      >
        {Array.isArray(nodes.children)
          ? nodes.children.map((node) => renderTree(node))
          : null}
      </CustomTreeItem>
    );
  };
  // ----------------------------------------------------------------------
  if (!editData) return;
  return (
    <>
      {editTChatNodes && expandedNodes ? (
        <>
          <TreeView
            aria-label="customized"
            defaultExpanded={expandedNodes}
            defaultCollapseIcon={<MinusSquare />}
            defaultExpandIcon={<PlusSquare />}
            defaultEndIcon={<CloseSquare />}
            sx={{ overflowX: "hidden" }}
          >
            {renderTree(editTChatNodes)}
          </TreeView>
        </>
      ) : (
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle1" color="textPrimary" sx={{ mr: 2 }}>
            Créer le noeud racine pour commencer
          </Typography>
          <ChatNodeForm
            editData={{ tChat: editData.id, isRoot: true }}
            onComplete={() => setupdatedAt(new Date())}
          />
        </Box>
      )}
    </>
  );
}
// ----------------------------------------------------------------------
