import React from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import {
  DialogContent,
  DialogTitle,
  Dialog,
  TextField as MuiTextField,
  Button,
  DialogActions,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import YouTubeIcon from "@mui/icons-material/YouTube";
import CircularProgress from "@mui/material/CircularProgress";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import { spacing } from "@mui/system";
import useChallenge from "hooks/useChallenge";
// ----------------------------------------------------------------------
const TextField = styled(MuiTextField)(spacing);
// ----------------------------------------------------------------------
export default function ChallengeVideoUrl({ editData, onComplete }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleComplete = async () => {
    await onComplete();
    handleClose();
    console.log("cool");
  };
  // ----------------------------------------------------------------------
  const color =
    editData && editData.youtubeUrl && editData.youtubeUrl.length > 10
      ? "primary"
      : "error";
  return (
    <div>
      <Button variant="contained" color={color} onClick={handleClickOpen}>
        <YouTubeIcon />
        Editer l'url de la vidéo Youtube
      </Button>
      <Dialog open={open} fullWidth maxWidth="xl">
        <DialogTitle> Ajouter un url Youtube</DialogTitle>
        <DialogContent>
          <FormEdit editData={editData} onComplete={handleComplete} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
// ----------------------------------------------------------------------
const FormEdit = ({ editData, onComplete }) => {
  const { saveChallenge } = useChallenge();
  const dataSchema = Yup.object().shape({
    youtubeUrl: Yup.string()
      .max(200)
      .required("Veuillez renseigner le url Youtube"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      youtubeUrl: editData && editData.youtubeUrl ? editData.youtubeUrl : "",
    },
    validationSchema: dataSchema,
    onSubmit: async () => {
      try {
        const newData = { id: editData.id, ...values }; //sauve only code, title, enable
        await saveChallenge(newData);
        onComplete();
      } catch (error) {
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
  } = formik;
  if (!editData) return;
  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <TextField
            fullWidth
            type="text"
            name="youtubeUrl"
            label={`Url Youtube`}
            {...getFieldProps("youtubeUrl")}
            error={Boolean(touched.youtubeUrl && errors.youtubeUrl)}
            helperText={touched.youtubeUrl && errors.youtubeUrl}
            my={2}
          />

          <Button
            sx={{ my: 2 }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            endIcon={
              isSubmitting ? (
                <CircularProgress size={20} />
              ) : (
                <DoneIcon size={20} />
              )
            }
          >
            Valider
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
};
