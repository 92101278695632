import { useDispatch, useSelector } from "react-redux";
import { maxsubjectsbyday, maxchallengesbysubject } from "config";
import axios from "axios";
import { API_URL } from "config";
import {
  startLoading,
  stopLoading,
  initPrograms,
  initAppProgram,
  initSubjsNumberByDay,
  initChallengesNumberBySubj,
  initStereoChallenges,
} from "redux/slices/programSlice";
import useNotification from "./useNotification";
// ----------------------------------------------------------------------

export default function useProgram() {
  const dispatch = useDispatch();
  const {
    isLoading,
    isLoaded,
    programs,
    boosters,
    stereotypechallenges,
    challenges,
    appProgram,
    maxSubjsByDay,
    challengesNumberBySubj,
  } = useSelector((state) => state.programs);
  const { appconfigs } = useSelector((state) => state.ref);
  const { notif } = useNotification();
  return {
    isLoaded,
    isLoading,
    programs,
    boosters,
    stereotypechallenges,
    challenges,
    appProgram,
    maxSubjsByDay,
    challengesNumberBySubj,
    getStereotypeChallenges: async () => {
      try {
        dispatch(startLoading());
        const res = await axios.get(API_URL.stereotypechallenge);
        if (res && res.data) {
          dispatch(initStereoChallenges(res.data));
        } else {
          throw new Error("Stereotype programme ne sont pas disponibles");
        }
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw new Error("Stereotype programme ne sont pas disponibles");
      }
    },
    initConfigProgram: () => {
      try {
        if (!appconfigs) return false;
        const subjsNumberByDay = appconfigs.find(
          (it) => it.code === maxsubjectsbyday
        );
        if (subjsNumberByDay) {
          dispatch(initSubjsNumberByDay(Number(subjsNumberByDay.value)));
        }

        const challengesNumberBySubj = appconfigs.find(
          (it) => it.code === maxchallengesbysubject
        );
        if (challengesNumberBySubj) {
          dispatch(
            initChallengesNumberBySubj(Number(challengesNumberBySubj.value))
          );
        }
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
      }
    },
    getPrograms: async () => {
      try {
        dispatch(startLoading());
        const res = await axios.get(API_URL.program, {
          params: {
            filters: { isPublished: false },
          },
        });
        if (res && res.data) {
          dispatch(initPrograms(res.data));
        } else {
          dispatch(initPrograms(null));
          throw new Error("Challenges ne sont pas disponibles");
        }
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw new Error("Challenges ne sont pas disponibles");
      }
    },
    getPublishPrograms: async () => {
      try {
        dispatch(startLoading());
        const res = await axios.get(API_URL.program, {
          params: {
            filters: { isPublished: true },
          },
        });
        if (res && res.data) {
          dispatch(initPrograms(res.data));
        } else {
          dispatch(initPrograms(null));
          throw new Error("Challenges ne sont pas disponibles");
        }
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw new Error("Challenges ne sont pas disponibles");
      }
    },
    initProgramById: async (id) => {
      try {
        dispatch(startLoading());
        const res = await axios.get(`${API_URL.program}/${id}`);
        if (res && res.data) {
          dispatch(initAppProgram(res.data));
        } else {
          throw new Error("Challenges ne sont pas disponibles");
        }
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw new Error("Challenges ne sont pas disponibles");
      }
    },
    delProgram: async (o) => {
      try {
        if (!o) return;
        dispatch(startLoading());
        await axios.delete(`${API_URL.program}/${o.id}`);
        dispatch(stopLoading());
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    cloneProgram: async (o) => {
      try {
        if (!o) return;
        dispatch(startLoading());
        await axios.get(`${API_URL.program}/${o.id}/clone`);
        dispatch(stopLoading());
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    publishProgram: async (o) => {
      try {
        if (!o) return;
        dispatch(startLoading());
        await axios.get(`${API_URL.program}/${o.id}/publish`);
        dispatch(stopLoading());
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    unPublishProgram: async (o) => {
      try {
        if (!o) return;
        dispatch(startLoading());
        await axios.get(`${API_URL.program}/${o.id}/unpublish`);
        dispatch(stopLoading());
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },

    saveProgram: async (o) => {
      try {
        dispatch(startLoading());

        delete o.modifiedby;
        delete o.createdby;
        if (o && o.id) {
          delete o.numberoffdays;
          await axios.patch(API_URL.program + "/" + o.id, o);
          notif("Sauvegarde réussie");
        } else {
          const res = await axios.post(API_URL.program, o);
          notif("Sauvegarde réussie");
          return res.data;
        }
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    saveImageProgram: async (o, field, image) => {
      try {
        if (!o || !o.id) return;
        if (!field) return;
        dispatch(startLoading());
        const formData = new FormData();
        formData.append("image", image);
        formData.append("field", field);
        formData.append("id", o.id);
        await axios.post(API_URL.program + "/image", formData);
        notif("Sauvegarde réussie");
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    getAllStereo: async () => {
      try {
        const res = await axios.get(API_URL.stereotypechallenge);
        if (res && res.data) {
          return res.data;
        }
        return null;
      } catch (error) {
        notif(null, error);
      }
    },
    selectStereoForProg: async (idProg, idStereo) => {
      try {
        await axios.get(
          `${API_URL.program}/selectStereo/${idProg}/${idStereo}`
        );
      } catch (error) {
        notif(null, error);
        throw error;
      }
    },
    getSubjectsByProg: async (idTheme) => {
      try {
        if (!appProgram) return;

        const res = await axios.get(
          `${API_URL.program}/${appProgram.id}/subjects`,
          {
            params: {
              filters: { refTheme: idTheme },
            },
          }
        );
        if (res && res.data) {
          return res.data;
        }
        return null;
      } catch (error) {
        notif(null, error);
        throw error;
      }
    },
    saveSubjects: async (arr, idTheme) => {
      try {
        if (!appProgram) return;
        dispatch(startLoading());
        const res = await axios.post(
          `${API_URL.program}/${appProgram.id}/subjects`,
          { refSubjects: arr, refTheme: idTheme }
        );
        return res.data;
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
  };
}
