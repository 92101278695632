import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";
import {
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardActions,
  CardContent as MuiCardContent,
  CardMedia as MuiCardMedia,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography,
  AvatarGroup as MuiAvatarGroup,
} from "@mui/material";
import axios from "axios";
import { spacing } from "@mui/system";
import useReferentiel from "hooks/useReferentiel";
import ChatFlowPanel from "pages/tchat/ChatFlowPanel";

import RefsEditBtn from "./RefsEditBtn";
import RefsDelBtn from "./RefsDelBtn";
import RefsEditMediaBtn from "./RefsEditMediaBtn";
import RefsEditVoiceBtn from "./RefsEditVoiceBtn";
import RefsEditCloneBtn from "./RefsEditCloneBtn";
import { QUIZ_TYPE_DEMAND } from "../../config";
// ----------------------------------------------------------------------
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
`;

const CardMedia = styled(MuiCardMedia)`
  height: 220px;
`;
const Typography = styled(MuiTypography)(spacing);
const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: ${(props) => props.theme.spacing(4)};
`;

const AvatarGroup = styled(MuiAvatarGroup)`
  margin-left: ${(props) => props.theme.spacing(2)};
`;

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
export default function CardItem({
  row,
  refName,
  image,
  title,
  description,
  chip,
  createdBy,
  modifiedBy,
  displayImg,
}) {
  const [imageObj, setimageObj] = useState(null);
  // ----------------------------------------------------------------------
  const { saveRef, initRefs, saveImageByRefName, saveVoiceRefsubject } =
    useReferentiel();
  // ----------------------------------------------------------------------
  useEffect(() => {
    if (!image) return;
    if (!displayImg) return;
    const init = async () => {
      try {
        const response = await axios.get(`${image}`, {
          responseType: "arraybuffer",
        });
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        const imageDataUrl = URL.createObjectURL(blob);
        setimageObj(imageDataUrl);
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [image, displayImg]);
  // ----------------------------------------------------------------------
  const handleComplete = async (editData, field, editObj) => {
    try {
      await saveImageByRefName(editData, field, editObj, refName);
      await initRefs();
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  // ----------------------------------------------------------------------
  const handleCompleteVoice = async (editData, field, editObj) => {
    try {
      await saveVoiceRefsubject(editData, field, editObj);
      await initRefs();
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  // ----------------------------------------------------------------------
  const handleCompleteTranscript = async () => {
    await initRefs();
  };
  // ----------------------------------------------------------------------
  const handleCompleteDelQuiz = async (field) => {
    if (!field) return;
    if (!row || !row.id) return;
    row[field] = null;
    await saveRef(row, refName);
    await initRefs();
  };
  // ----------------------------------------------------------------------
  return (
    <Card>
      {imageObj ? (
        <CardMedia image={imageObj} title="Contemplative Reptile" />
      ) : (
        <></>
      )}
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {title}
        </Typography>

        {chip}

        <Typography mb={4} color="textSecondary" component="p">
          {row.shortDescription ? row.shortDescription : description}
        </Typography>

        <AvatarGroup max={3}>
          {createdBy && (
            <Avatar alt="Avatar" src="/static/img/avatars/avatar-1.jpg" />
          )}
          {modifiedBy && (
            <Avatar alt="Avatar" src="/static/img/avatars/avatar-2.jpg" />
          )}
        </AvatarGroup>
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent:
            refName === "refsubject" ? "space-between" : "flex-end",
        }}
      >
        {refName === "refdemand" && row.id && (
          <>
            <ChatFlowPanel
              preDefinedType={QUIZ_TYPE_DEMAND}
              editData={row}
              field="tChat"
              addLabel="Conversation chat"
              handleComplete={() => initRefs()}
              handleCompleteDelQuiz={() => handleCompleteDelQuiz("tChat")}
              emptyMsg="Tchat vide"
            />
          </>
        )}
        {(refName === "reftheme" ||
          refName === "contact" ||
          refName === "refdemand" ||
          refName === "reftheme" ||
          refName === "refavatar" ||
          refName === "refinterest" ||
          refName === "subtheme" ||
          refName === "article") &&
          row.id && (
            <>
              <RefsEditMediaBtn
                field="image"
                editData={row}
                refName={refName}
                addLabel={`Image ${row.title ? row.title : row.label}`}
                handleComplete={handleComplete}
              />
            </>
          )}

        {refName === "refsubject" && row.id && (
          <>
            <RefsEditCloneBtn
              editData={row}
              refName={refName}
              addLabel="Dupliquer"
            />
            <RefsEditMediaBtn
              field="image"
              editData={row}
              refName={refName}
              addLabel="Image"
              handleComplete={handleComplete}
            />
            <RefsEditMediaBtn
              field="bgimage"
              editData={row}
              refName={refName}
              addLabel="Image de fond"
              handleComplete={handleComplete}
            />
            <RefsEditVoiceBtn
              field="voiceTranscript"
              editData={row}
              refName={refName}
              addLabel="Voie et transcripts"
              handleCompleteVoice={handleCompleteVoice}
              handleCompleteTranscript={handleCompleteTranscript}
            />
          </>
        )}

        <RefsEditBtn editData={row} refName={refName} />
        <RefsDelBtn editData={row} refName={refName} />
      </CardActions>
    </Card>
  );
}
