import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import styled from "@emotion/styled";
import {
  Box,
  TextField as MuiTextField,
  Button,
  Paper,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import useNotification from "hooks/useNotification";
import useReferentiel from "hooks/useReferentiel";
import RadioBtnStyled from "components/custom/RadioBtnStyled";
// ----------------------------------------------------------------------
const TextField = styled(MuiTextField)(spacing);
const Item = styled(Paper)(({ theme }) => ({
  //backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
export default function RefActionPointForm({ editData, onComplete }) {
  const { saveRefActionPoint, refs, initRefs } = useReferentiel();
  const { refStereoType } = refs;
  const { notif } = useNotification();
  // ----------------------------------------------------------------------

  const getValidationParams = () => {
    try {
      if (!refStereoType) return;
      const obj = {};
      refStereoType.forEach((it) => {
        obj[`point_${it.code}`] = Yup.string()
          .max(200)
          .required("Veuillez renseigner le point gagné pour " + it.label);
      });
      return obj;
    } catch (error) {
      return {};
    }
  };
  const getInitValues = () => {
    try {
      const obj = {};
      refStereoType.forEach((it) => {
        const pointField = `point_${it.code}`;
        obj[pointField] = "";
      });
      return obj;
    } catch (error) {
      return {};
    }
  };

  const getFieldParams = () => {
    try {
      if (!refStereoType) return;
      return (
        <>
          {refStereoType.map((it, i) => {
            const pointField = `point_${it.code}`;
            return (
              <TextField
                key={i}
                fullWidth
                type="number"
                label={`Point gagné pour le ${it.label}`}
                {...getFieldProps(pointField)}
                error={Boolean(touched[pointField] && errors[pointField])}
                helperText={touched[pointField] && errors[pointField]}
                my={2}
              />
            );
          })}
        </>
      );
    } catch (error) {
      return <></>;
    }
  };

  const dataSchema = Yup.object().shape({
    code: Yup.string().max(20).required("Veuillez renseigner le code"),
    label: Yup.string().max(100).required(`Veuillez renseigner le libellé`),
    ...getValidationParams(),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: editData && editData.code ? editData.code : "",
      label: editData && editData.label ? editData.label : "",
      accumulated: editData && editData.accumulated ? true : false,
      ...getInitValues(),
    },
    validationSchema: dataSchema,
    onSubmit: async () => {
      try {
        const pointGained = [];
        refStereoType.forEach((it) => {
          const keyField = `point_${it.code}`;
          pointGained.push({
            refStereoType: { ...it },
            point: values[keyField],
          });
        });
        const newData =
          editData && editData.id
            ? {
                ...editData,
                code: values.code,
                label: values.label,
                pointGained,
              }
            : {
                code: values.code,
                label: values.label,
                pointGained,
              };
        await saveRefActionPoint(newData);
        await initRefs();
        notif("Sauvegarde réussie");
        onComplete();
      } catch (error) {
        console.error(error);
        notif(null, error);
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
    handleChange,
    setFieldValue,
  } = formik;
  // ----------------------------------------------------------------------

  useEffect(() => {
    if (!refStereoType) return;
    if (!editData) return;
    if (!editData.pointGained) return;
    if (!setFieldValue) return;

    refStereoType.forEach((it) => {
      const pointField = `point_${it.code}`;
      const pointGainedFound = editData.pointGained.find(
        (p) => p.refStereoType.code === it.code
      );

      if (pointGainedFound) {
        setFieldValue(pointField, pointGainedFound.point);
      }
    });
  }, [refStereoType, editData, setFieldValue]);
  // ----------------------------------------------------------------------

  if (!refStereoType) return <></>;
  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <TextField
            fullWidth
            type="text"
            label="Code"
            {...getFieldProps("code")}
            error={Boolean(touched.code && errors.code)}
            helperText={touched.code && errors.code}
            my={2}
          />
          <TextField
            fullWidth
            type="text"
            name="label"
            label="Libellé"
            {...getFieldProps("label")}
            error={Boolean(touched.label && errors.label)}
            helperText={touched.label && errors.label}
            my={2}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={2}
          >
            <FormLabel component="legend">Poin accumulé ?</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={values.accumulated}
              onChange={handleChange("accumulated")}
            >
              <FormControlLabel
                value={Boolean(true)}
                control={<RadioBtnStyled />}
                label={<Typography>Oui</Typography>}
              />
              <FormControlLabel
                value={Boolean(false)}
                control={<RadioBtnStyled />}
                label={<Typography>Non</Typography>}
              />
            </RadioGroup>
          </Box>
          {getFieldParams()}
          <Button
            sx={{ my: 2 }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Valider
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
}
